import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { ButtonLink } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./_components/table"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { AttributeCategoryFilter } from "@/features/posts/attributeCategories/resources/views/list/_components/filters"
import { Query } from "@/utils/query"
import { SearchFilterInterface } from "@/features/posts/attributeCategories/resources/views/list/_components/filters/types"

const ns = "posts/attribute_categories"

export const PostAttributeCategoryListScreen = (): React.ReactNode => {
  const { t } = useTranslation([ns])
  const { hasPermission } = usePermissions()
  const [filters, setFilters] = useState<SearchFilterInterface>()
  const query = useMemo(() => {
    const query = new Query()

    if (filters?.attribute_id) query.where("attribute_id", filters.attribute_id)
    if (filters?.category_id) query.where("category_id", filters.category_id)

    return query
  }, [filters])

  return (
    <div className={"flex flex-col p-8 gap-y-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.ATTRIBUTE_CATEGORY_SAVE) && (
          <ButtonLink
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            to={"/posts/attribute-categories/create"}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t(`${ns}:list.create`)}</span>
          </ButtonLink>
        )}
      </div>
      <AttributeCategoryFilter onSubmit={setFilters} />
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t(`${ns}:list.title`)}</span>
          </div>
          <Table query={query} />
        </div>
      </Card>
    </div>
  )
}
