import { TFunction } from "i18next"
import * as yup from "yup"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import _ from "lodash"
import {
  SaveAttributeInterface,
  SaveAttributeValueInterface,
} from "@/features/posts/attributeCategories/redux/types"
import { v4 as uuid } from "uuid"

export default class SaveAttributeCategoryValidation
  implements ValidationInterface<SaveAttributeInterface>
{
  rules(t: TFunction): yup.ObjectSchema<SaveAttributeInterface, yup.AnyObject> {
    return yup.object().shape({
      _id: yup.number().nullable(),
      attribute_id: yup.number().required(),
      category_id: yup.number(),
      affects_price: yup.boolean().required(),
      uuid: yup.string().required(),
      image: yup
        .mixed<File>()
        .nullable()
        .test(
          "file-extension",
          t("validation:file_extension", {
            allowedExtensions: "jpg, png, jpeg, svg",
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = ["jpg", "png", "jpeg", "svg"]
            const fileExtension = value.name.split(".").pop()

            return allowedExtensions.includes(fileExtension || "")
          },
        ),
      values: yup.array().of(this.valueSchema(t)).required(),
    })
  }

  defaultValues(): SaveAttributeInterface {
    return {
      attribute_id: 0,
      category_id: 0,
      uuid: uuid(),
      affects_price: false,
      image: null,
      values: [],
    }
  }

  private valueSchema(
    t: TFunction,
  ): yup.ObjectSchema<SaveAttributeValueInterface, yup.AnyObject> {
    return yup.object().shape({
      _id: yup.number().nullable(),
      value: yup.string().required(),
      children: yup
        .array()
        .of(yup.lazy(() => this.rules(t)))
        .nullable(),
      uuid: yup.string().required(t("validation:required")),
    })
  }
}
