import React from "react"
import { usePermissions } from "@/utils/hooks/usePermission"
import { useTranslation } from "react-i18next"
import { Table } from "./components/table"
import Card from "@/features/components/cards"
import { ButtonLink } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { useParams } from "react-router-dom"

export const ArticleMediaGalleryScreen = (): React.ReactNode => {
  const { t } = useTranslation(["article_media_gallery"])
  const { hasPermission } = usePermissions()
  const { id } = useParams() as { id: string }

  return (
    <div className={"flex flex-col gap-y-4 p-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.ARTICLE_GALLERY_SAVE) && (
          <ButtonLink
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            to={"create"}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t("article_media_gallery:list.create")}</span>
          </ButtonLink>
        )}
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("article_media_gallery:list.title")}</span>
          </div>
          <Table articleGalleryId={id} />
        </div>
      </Card>
    </div>
  )
}
