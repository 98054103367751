import {
  TagInterface,
  UpdateNotificationTemplateRequestInterface,
} from "@/features/notificationTemplates/redux/types"
import { NotificationTemplateTypeEnum } from "@/features/notificationTemplates/redux/enums/types"
import React, { Fragment, useState } from "react"
import { Controller, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Label } from "@/features/components/inputs/label"
import { Editor } from "@/features/components/inputs/editor"
import { GalleryEnum } from "@/app/enums/galleryEnum"
import { SelectTag } from "@/features/notificationTemplates/resources/views/edit/_components/selectTag"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Button } from "@/features/components/buttons/button"
import _ from "lodash"
import styled from "styled-components"

type Props = {
  currentIndex: number
  form: UseFormReturn<UpdateNotificationTemplateRequestInterface>
  type: NotificationTemplateTypeEnum
}

export const DataForm: React.FC<Props> = ({
  form,
  type,
  currentIndex,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "validation"])
  const { control, watch, setValue } = form
  const watchDataType = watch(`data.${currentIndex}.${type}`)
  const watchTags = watch(`data.${currentIndex}.${type}.tags`)
  const watchContent = watch(`data.${currentIndex}.${type}.content`)
  const [{ styleKey, styleValue }, setStyle] = useState<{
    styleKey: string
    styleValue: string | number
  }>({
    styleKey: "",
    styleValue: "",
  })

  if (!watchDataType || !watchDataType.content) return <Fragment></Fragment>

  const watchActions = watch(`data.${currentIndex}.${type}.actions`)

  const handleSelectTag = (tag: TagInterface): void => {
    setValue(
      `data.${currentIndex}.${type}.content`,
      `${watchContent || ""} [${tag.name}]`,
    )
  }

  const handleSaveStyle = (index: number) => {
    if (!styleKey || !styleValue) return

    setValue(`data.${currentIndex}.${type}.actions.${index}.style`, {
      ...watchActions[index].style,
      [styleKey]: styleValue,
    })

    setStyle({
      styleKey: "",
      styleValue: "",
    })
  }

  const handleRemoveStyle = (index: number, key: string) => {
    setValue(
      `data.${currentIndex}.${type}.actions.${index}.style`,
      _.omit(watchActions[index].style, key),
    )

    setStyle({
      styleKey: "",
      styleValue: "",
    })
  }

  const handleEditStyle = (index: number, key: string) => {
    setStyle({
      styleKey: key,
      styleValue: watchActions[index].style[key],
    })
  }

  return (
    <div className={"flex flex-col gap-y-4"}>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <FormControl {...field} error={!!error}>
            <Input label={t("form:labels.title")} />
            <FormHelperText message={error?.message} />
          </FormControl>
        )}
        name={`data.${currentIndex}.${type}.title`}
        control={control}
      />
      <div className={"flex gap-x-4 w-full"}>
        {watchTags && watchTags.length > 0 && (
          <SelectTag onSelectTag={handleSelectTag} tags={watchTags} />
        )}
        <Controller
          render={({ field, fieldState: { error } }) => (
            <div className={"flex flex-col w-full"}>
              <Label label={t("form:labels.content")} />
              <Editor
                onChange={field.onChange}
                data={field.value}
                galleryType={GalleryEnum.NOTIFICATION_TEMPLATE}
              />
              <FormHelperText message={error?.message} error={!!error} />
            </div>
          )}
          name={`data.${currentIndex}.${type}.content`}
          control={control}
        />
      </div>
      <div className={"py-4"}>
        <span className={"font-semibold"}>
          {t("notification_templates:edit.actions")}
        </span>
      </div>
      {watchActions.map((field, fieldIndex) => (
        <div key={fieldIndex} className={"flex flex-col gap-y-4"}>
          <div className={"grid grid-cols-4 gap-4 w-full"}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input label={t("form:labels.system_name")} readOnly />
                </FormControl>
              )}
              name={`data.${currentIndex}.${type}.actions.${fieldIndex}.system_name`}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input label={t("form:labels.name")} readOnly />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={`data.${currentIndex}.${type}.actions.${fieldIndex}.name`}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input label={t("form:labels.text")} />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={`data.${currentIndex}.${type}.actions.${fieldIndex}.text`}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input label={t("form:labels.url")} />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={`data.${currentIndex}.${type}.actions.${fieldIndex}.url`}
              control={control}
            />
          </div>
          <div className={"py-2"}>
            <span className={"font-semibold"}>
              {t("notification_templates:edit.styles")}
            </span>
          </div>
          <div
            className={"grid grid-cols-3 2xl:grid-cols-5 gap-4 items-center"}
          >
            <div>
              <div className={"flex flex-col gap-y-4 max-w-[400px]"}>
                <Input
                  label={t("form:labels.style_property")}
                  value={styleKey}
                  onChange={(e) =>
                    setStyle((prev) => ({
                      ...prev,
                      styleKey: e.target.value,
                    }))
                  }
                />
                <Input
                  label={t("form:labels.value")}
                  value={styleValue}
                  onChange={(e) =>
                    setStyle((prev) => ({
                      ...prev,
                      styleValue: e.target.value,
                    }))
                  }
                />
                <Button
                  variant={"contained"}
                  onClick={() => handleSaveStyle(fieldIndex)}
                  type={"button"}
                >
                  {t("form:buttons.save")}
                </Button>
              </div>
            </div>
            <div className={"flex"}>
              <LinkComponent current-index={fieldIndex} fields={watchActions}>
                {watchActions[fieldIndex].text}
              </LinkComponent>
            </div>
            <div className={"flex flex-col gap-y-1"}>
              {Object.keys(field.style).map((key, index) => (
                <div
                  key={index}
                  className={"flex justify-between items-center"}
                >
                  <div className={"flex gap-2"}>
                    <span>{key}</span>
                    <span>{field.style[key]}</span>
                  </div>
                  <div className={"flex gap-2 items-center"}>
                    <button
                      onClick={() => handleEditStyle(fieldIndex, key)}
                      type={"button"}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        className={"text-orange-500"}
                      />
                    </button>
                    <button
                      onClick={() => handleRemoveStyle(fieldIndex, key)}
                      type={"button"}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={"text-red-600"}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

interface ComponentInterface {
  "current-index": number
  fields: any
}

const LinkComponent = styled.a.attrs<ComponentInterface>((props) => ({
  "current-index": props["current-index"],
  fields: props.fields,
}))`
  ${(props) => props.fields[props["current-index"]].style}
`
