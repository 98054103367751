import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Query } from "@/utils/query"
import { Table } from "@/features/issues/resources/_components/table"
import Card from "@/features/components/cards"

export const IssueListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["issues"])
  const query = useRef<Query>(new Query())

  return (
    <div className={"flex flex-col p-8 gap-y-4"}>
      <div className={"flex gap-x-2"}></div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("issues:list.title")}</span>
          </div>
          <Table query={query.current} />
        </div>
      </Card>
    </div>
  )
}
