import { InvoiceInterface } from "@/features/invoices/redux/types"
import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"

type Props = {
  data: InvoiceInterface
}

export const InvoiceBuyerDetails: React.FC<Props> = ({
  data,
}): React.ReactNode => {
  const { t } = useTranslation(["invoices", "form", "utils"])

  return (
    <Card>
      <div className={"flex flex-col gap-y-6"}>
        <span className={"text-lg font-medium"}>
          {t("invoices:details.buyer_information")}
        </span>
        <div className={"flex flex-col gap-y-2"}>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>
              {t("form:labels.company_name")}
            </span>
            <span>{data.buyer.company_name}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>{t("form:labels.nip")}</span>
            <span>{data.buyer.nip}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>{t("form:labels.city")}</span>
            <span>{data.buyer.city}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>
              {t("form:labels.postal_code")}
            </span>
            <span>{data.buyer.postal_code}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>{t("form:labels.street")}</span>
            <span>{data.buyer.street}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>
              {t("form:labels.property_number")}
            </span>
            <span>{data.buyer.property_number}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>
              {t("form:labels.apartment_number")}
            </span>
            <span>{data.buyer.apartment_number}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}
