import React, { useMemo } from "react"
import { SidebarMenuItemInterface } from "@/utils/routes/privateRoutes/types"
import { useLocation, useMatch, useNavigate } from "react-router-dom"
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = Omit<SidebarMenuItemInterface, "children"> & {
  onClick: (index: string) => void
}

export const SidebarToggle: React.FC<Props> = ({
  url,
  icon,
  index,
  onClick,
  redirect,
  matchUrls,
}): React.ReactNode => {
  const location = useLocation()
  const match = useMatch(location.pathname)
  const isActive = useMemo(
    () => matchUrls.includes(match?.pathname ?? ""),
    [match, matchUrls],
  )
  const navigate = useNavigate()

  const handleClick = () => {
    onClick(index)

    if (redirect && url) {
      navigate(url)
    }
  }

  const classes = clsx("items-center justify-center flex w-12 h-12", {
    "bg-[#F3F9FE] flex rounded-lg": isActive,
    "text-gray-50": !isActive,
    "text-primary-600": isActive,
  })

  return (
    <button onClick={handleClick} className={classes}>
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}
