import React, { useEffect, useState } from "react"
import { FormInterface } from "@/app/types"
import {
  BundleCategoryInterface,
  SaveBundleCategoryRequestInterface,
} from "@/features/categoryBundles/redux/types"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import { SaveCategoryBundleValidation } from "./rules/SaveCategoryBundleValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Input } from "@/features/components/inputs/input"
import { Checkbox } from "@/features/components/inputs/checkbox"
import { Tree } from "./partials"
import { Button } from "@/features/components/buttons/button"

const Form: React.FC<
  FormInterface<SaveBundleCategoryRequestInterface, BundleCategoryInterface>
> = ({ onSubmit, data }): React.ReactNode => {
  const { t } = useTranslation(["form", "validation", "utils"])
  const { schema, defaultValues } = useValidation(
    new SaveCategoryBundleValidation(),
    t,
  )
  const { control, handleSubmit, setValue, watch } =
    useForm<SaveBundleCategoryRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })
  const [defaultSelectedCategories, setDefaultSelectedCategories] = useState<
    number[]
  >([])

  useEffect(() => {
    if (data) {
      setValue("name", data.name)
      setValue("is_active", data.is_active)

      if (data.category_ids) {
        setValue("category_ids", data.category_ids)
        setDefaultSelectedCategories(data.category_ids)
      }
    }
  }, [data])

  return (
    <form className={"flex flex-col gap-y-4"} onSubmit={handleSubmit(onSubmit)}>
      <div className={"flex flex-col gap-y-4"}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl className={"max-w-[400px]"} {...field} error={!!error}>
              <Input label={t("form:labels.name")} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={"name"}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                label={t("form:labels.is_active")}
              />
            </FormControl>
          )}
          name={"is_active"}
          control={control}
        />
      </div>
      <div className={"flex flex-col gap-y-2 pt-4"}>
        <span className={"text-lg font-medium"}>
          {t("form:labels.categories")}
        </span>
        <Tree
          onChange={(ids) => setValue("category_ids", ids)}
          selectedIds={defaultSelectedCategories}
        />
      </div>
      <div className={"flex pt-4"}>
        <Button variant={"contained"} type={"submit"}>
          {t("form:buttons.save")}
        </Button>
      </div>
    </form>
  )
}

export { Form }
