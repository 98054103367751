import { GroupBase } from "react-select"
import BaseCreateableSelect, { CreatableProps } from "react-select/creatable"

function CreateableSelect<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: CreatableProps<Option, IsMulti, Group>) {
  return (
    <BaseCreateableSelect
      classNames={{
        control: () =>
          "!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[42px]",
      }}
      {...props}
    />
  )
}

export default CreateableSelect
