import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetPostsQuery } from "@/features/posts/posts/redux/postAPI"
import { PostElasticSearchInterface } from "@/features/posts/posts/redux/types"
import { ElasticHitInterface } from "@/app/types"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import BaseTable from "@/features/components/table"
import { useNavigate } from "react-router-dom"
import Carbon from "@/utils/carbon"
import { TypeBadge } from "./_components/typeBadge"
import { BoolBadge } from "./_components/boolBadge"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { SimplePagination } from "@/features/components/table/simplePagination"
import _ from "lodash"

type Props = {
  query: { [key: string]: any }
  onRowSelectionChange: (selectedIds: number[]) => void
}

export const Table: React.FC<Props> = ({
  query: baseQuery,
  onRowSelectionChange,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils"])
  const navigate = useNavigate()
  const [{ size, from }, setPagination] = useState({ size: 15, from: 0 })
  const [data, setData] = useState<
    ElasticHitInterface<PostElasticSearchInterface>[]
  >([])
  const query = useMemo(() => {
    const query = { size, from }

    if (!_.isEmpty(baseQuery)) {
      return Object.assign(query, { query: baseQuery })
    }

    return query
  }, [baseQuery, from, size])
  const { data: apiData } = useGetPostsQuery(query)
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({})

  useEffect(() => {
    if (apiData) {
      setData(apiData.hits)
    }
  }, [apiData])

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection).map(
      (key) => data[Number(key)]._source.id,
    )

    onRowSelectionChange(selectedIds)
  }, [data, onRowSelectionChange, rowSelection])

  const table = useReactTable({
    columns: columns(t, (id) => navigate(`/posts/${id}/edit`)),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  })

  const handlePrevClick = () => {
    setPagination({ size, from: from - size })
    setRowSelection({})
  }

  const handleNextClick = () => {
    setPagination({ size, from: from + size })
    setRowSelection({})
  }

  return (
    <div className={"flex flex-col gap-y-2"}>
      <BaseTable table={table} />
      {apiData && (
        <div className={"flex-1 flex justify-center gap-x-2 p-4 items-center"}>
          <SimplePagination
            total={apiData.total}
            onPrevClick={handlePrevClick}
            onNextClick={handleNextClick}
            from={from}
            size={size}
          />
        </div>
      )}
    </div>
  )
}

const columnBuilder =
  createColumnHelper<ElasticHitInterface<PostElasticSearchInterface>>()

const columns = (t: Function, onEdit: (id: number) => void) => [
  columnBuilder.display({
    id: "select",
    header: () => <span>#</span>,
    enableSorting: false,
    meta: {
      cellClassName: "text-center",
      columnClassName: "text-center",
    },
    maxSize: 50,
    cell: ({ row }) => (
      <input
        type={"checkbox"}
        className={"w-[16px] h-[16px] accent-primary"}
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
        disabled={!row.getCanSelect()}
      />
    ),
  }),
  columnBuilder.display({
    id: "id_date",
    header: () => {
      return (
        <div className={"flex flex-col"}>
          <span>#ID</span>
          <span>{t("form:labels.created_at")}</span>
        </div>
      )
    },
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span>#{row.original._source.id}</span>
        {row.original._source.published_at && (
          <span>
            {new Carbon()
              .parse(row.original._source.published_at)
              .format("dd.MM.yyyy HH:mm")}
          </span>
        )}
      </div>
    ),
  }),
  columnBuilder.accessor("_source.type", {
    id: "type",
    header: t("form:labels.type"),
    cell: ({ row }) => <TypeBadge type={row.original._source.type} />,
  }),
  columnBuilder.accessor("_source.category.name", {
    id: "category",
    header: t("form:labels.category"),
  }),
  columnBuilder.accessor("_source.location", {
    id: "location",
    header: t("form:labels.location"),
  }),
  columnBuilder.accessor("_source.content", {
    id: "content",
    header: t("form:labels.content"),
    cell: ({ row }) => (
      <span className={"truncate"}>
        {row.original._source.content.substring(0, 50)}
        {row.original._source.content.length > 50 && "..."}
      </span>
    ),
  }),
  columnBuilder.accessor("_source.price", {
    id: "price",
    header: t("form:labels.price"),
    cell: ({ row }) => (
      <span>
        {row.original._source.price
          ? Intl.NumberFormat("pl", {
              style: "currency",
              currency: "PLN",
            }).format(row.original._source.price)
          : "-"}
      </span>
    ),
  }),
  columnBuilder.accessor("_source.is_price_negotiable", {
    id: "is_negotiable",
    header: t("form:labels.is_negotiable"),
    cell: ({ row }) => (
      <BoolBadge value={row.original._source.is_price_negotiable} />
    ),
  }),
  columnBuilder.display({
    id: "unit_with_quantity",
    header: t("form:labels.unit_with_quantity"),
    cell: ({ row }) => (
      <span>
        {row.original._source.quantity}{" "}
        {row.original._source.quantity_unit.symbol}
      </span>
    ),
  }),
  columnBuilder.accessor("_source.user_registered", {
    id: "user_registered",
    header: t("form:labels.user_registered"),
    cell: ({ row }) => (
      <BoolBadge value={row.original._source.user_registered} />
    ),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.POST_DESTROY}
        editPermission={PermissionEnum.POST_SAVE}
        onEditClick={() => onEdit(row.original._source.id)}
      />
    ),
  }),
]
