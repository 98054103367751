import React, { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ButtonStack } from "./_components/buttonStack"
import { BasicForm } from "@/features/posts/posts/resources/views/edit/_components/form/base"
import { useGetPostQuery } from "@/features/posts/posts/redux/postAPI"
import { PostStatusEnum } from "@/features/posts/posts/redux/enums/status"
import { Images } from "./_components/images"
import { Services } from "./_components/services"
import { PostUser } from "@/features/posts/posts/resources/views/edit/_components/users"
import { PostAuditList } from "./_components/audits"
import { PostIssueList } from "./_components/issues"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"

export const PostsEditScreen = (): React.ReactNode => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const { t } = useTranslation(["posts/posts"])
  const { data } = useGetPostQuery(id)
  const blocked = useMemo(() => data?.status === PostStatusEnum.BLOCKED, [data])
  const { hasPermission } = usePermissions()

  if (!data) {
    return <span></span>
  }

  return (
    <div className={"flex flex-col p-4 gap-y-6"}>
      <span className={"text-2xl font-medium"}>
        {t("posts/posts:edit.title")}
      </span>
      <ButtonStack
        policeReportUrl={data.police_report_url}
        id={id}
        blocked={blocked}
      />
      <BasicForm data={data} />
      <Images data={data} />
      <Services id={id} />
      <PostUser postId={id} data={data.user} />
      <PostAuditList id={id} />
      {hasPermission(PermissionEnum.ISSUE_SHOW) && <PostIssueList id={id} />}
    </div>
  )
}
