import igritAPI from "@/utils/api/igrit"
import {
  GetNotificationTemplateResponseInterface,
  GetNotificationTemplatesResponseInterface,
  NotificationTemplateInterface,
  UpdateNotificationTemplateRequestInterface,
} from "@/features/notificationTemplates/redux/types"
import { setPaginatedCache, setSingleObjectCache } from "@/utils/api/rtkHelper"

export const {
  useGetNotificationTemplatesQuery,
  useGetNotificationTemplateQuery,
  useUpdateNotificationTemplateMutation,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationTemplates: builder.query<
      GetNotificationTemplatesResponseInterface["data"],
      string
    >({
      query: (query) => `/admin/notification-templates?${query}`,
      transformResponse: (
        response: GetNotificationTemplatesResponseInterface,
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache("NotificationTemplate", result),
    }),
    getNotificationTemplate: builder.query<
      NotificationTemplateInterface,
      string | number
    >({
      query: (id) => `/admin/notification-templates/${id}`,
      transformResponse: (response: GetNotificationTemplateResponseInterface) =>
        response.data,
      providesTags: (result) =>
        setSingleObjectCache("NotificationTemplate", result),
    }),
    updateNotificationTemplate: builder.mutation<
      void,
      { id: string | number; body: UpdateNotificationTemplateRequestInterface }
    >({
      query: ({ body, id }) => ({
        url: `/admin/notification-templates/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: "NotificationTemplate", id },
        { type: "NotificationTemplate", id: "LIST" },
      ],
    }),
  }),
})
