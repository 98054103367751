import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveAdsSlotRequestInterface } from "@/features/ads/slots/redux/types"
import { AdsSlotTypeEnum } from "@/features/ads/slots/redux/enums/AdsSlotTypeEnum"

class SaveAdsSlotValidation
  implements ValidationInterface<SaveAdsSlotRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveAdsSlotRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validation:required")),
      type: yup.mixed<AdsSlotTypeEnum>().required(t("validation:required")),
      view_ids: yup
        .array()
        .of(yup.number().required())
        .required(t("validation:required"))
        .default([]),
    })
  }

  defaultValues(): SaveAdsSlotRequestInterface {
    return {
      name: "",
      type: AdsSlotTypeEnum.TOP_BANNER_1,
      view_ids: [],
    }
  }
}

export { SaveAdsSlotValidation }
