import igritAPI from "@/utils/api/igrit"
import {
  GetBundleCategoriesResponseInterface,
  GetBundleCategoryResponseInterface,
  SaveBundleCategoryRequestInterface,
} from "@/features/categoryBundles/redux/types"
import { setPaginatedCache, setSingleObjectCache } from "@/utils/api/rtkHelper"

export const {
  useGetBundleCategoriesQuery,
  useGetBundleCategoryQuery,
  useStoreBundleCategoryMutation,
  useUpdateBundleCategoryMutation,
  useDeleteBundleCategoryMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getBundleCategories: build.query<
      GetBundleCategoriesResponseInterface["data"],
      string
    >({
      query: (query) => `/admin/category-bundles?${query}`,
      transformResponse: (response: GetBundleCategoriesResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache("BundleCategory", result),
    }),
    getBundleCategory: build.query<
      GetBundleCategoryResponseInterface["data"],
      string | number
    >({
      query: (id) => `/admin/category-bundles/${id}`,
      transformResponse: (response: GetBundleCategoryResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache("BundleCategory", result),
    }),
    storeBundleCategory: build.mutation<
      void,
      SaveBundleCategoryRequestInterface
    >({
      query: (data) => ({
        url: "/admin/category-bundles",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["BundleCategory"],
    }),
    updateBundleCategory: build.mutation<
      void,
      { data: SaveBundleCategoryRequestInterface; id: number | string }
    >({
      query: ({ data, id }) => ({
        url: `/admin/category-bundles/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BundleCategory"],
    }),
    deleteBundleCategory: build.mutation<void, number | string>({
      query: (id) => ({
        url: `/admin/category-bundles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BundleCategory"],
    }),
  }),
})
