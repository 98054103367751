import React from "react"
import { useTranslation } from "react-i18next"
import { PostUserInterface } from "@/features/posts/posts/redux/types"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import BaseTable from "@/features/components/table"
import Card from "@/features/components/cards"

type Agreements = PostUserInterface["agreements"]
type Agreement = Agreements[0]

type Props = {
  agreements: Agreements
}

export const AgreementList: React.FC<Props> = ({
  agreements: data,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils"])

  const table = useReactTable({
    columns: columns(t),
    getCoreRowModel: getCoreRowModel(),
    data,
  })

  return (
    <Card className={"flex flex-col gap-y-4"}>
      <span className={"font-medium"}>
        {t("posts/posts:edit.users.agreements.title")}
      </span>
      <BaseTable table={table} />
    </Card>
  )
}

const columnBuilder = createColumnHelper<Agreement>()

const columns = (t: Function) => [
  columnBuilder.accessor("agreement.name", {
    id: "name",
    header: t("form:labels.name"),
    cell: ({ row }) => (
      <a
        href={row.original.agreement.file_url ?? "#"}
        className={"text-blue-500"}
        target="_blank"
      >
        {row.original.agreement.name}
      </a>
    ),
  }),
  columnBuilder.accessor("accepted", {
    id: "accepted",
    header: t("form:labels.accepted"),
    cell: ({ row }) =>
      row.original.accepted ? t("form:labels.yes") : t("form:labels.no"),
  }),
]
