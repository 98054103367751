import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { useNavigate } from "react-router-dom"
import usePagination from "@/utils/hooks/usePagination"
import { Query } from "@/utils/query"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import { isCustomError } from "@/utils/api/rtkHelper"
import { ResponseCodeEnum } from "@/app/types"
import { AttributeCategoryListInterface } from "@/features/posts/attributeCategories/redux/types"
import {
  useDeleteAttributeCategoryMutation,
  useGetAttributeCategoryListQuery,
} from "@/features/posts/attributeCategories/redux/attributeCategoryAPI"

const ns = "posts/attribute_categories"

type Props = {
  query: Query
}

export const Table: React.FC<Props> = ({
  query: baseQuery,
}): React.ReactNode => {
  const { t } = useTranslation(["form", ns, "utils"])
  const navigate = useNavigate()
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () =>
      baseQuery.includes("category", "attribute").page(pagination.pageIndex),
    [pagination, baseQuery],
  )
  const { data: apiData } = useGetAttributeCategoryListQuery(query.url())
  const [data, setData] = useState<AttributeCategoryListInterface[]>([])
  const [deleteAttribute] = useDeleteAttributeCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteAttribute({ id, delete_value: false })
      .unwrap()
      .then(() => toast.success(t(`${ns}:list.deleted`)))
      .catch((error) => {
        if (
          isCustomError(error, ResponseCodeEnum.ATTRIBUTE_CAN_NOT_BE_DELETED)
        ) {
          toast.error(t(`${ns}:list.can_not_be_deleted`))
        }
      })
  }

  const table = useReactTable({
    columns: columns(t, onDelete, (id) =>
      navigate(`/posts/attribute-categories/${id}/edit`),
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<AttributeCategoryListInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("attribute.name", {
    id: "name",
    header: t("form:labels.attribute_name"),
  }),
  columnBuilder.accessor("category.name", {
    id: "type",
    header: t("form:labels.category_name"),
  }),
  columnBuilder.accessor("affects_price", {
    id: "affects_price",
    header: t("form:labels.affects_price"),
    cell: ({ row }) =>
      row.original.affects_price ? t("form:labels.yes") : t("form:labels.no"),
  }),
  columnBuilder.accessor("has_parent", {
    id: "has_parent",
    header: t("form:labels.has_parent"),
    cell: ({ row }) =>
      row.original.has_parent ? t("form:labels.yes") : t("form:labels.no"),
  }),
  columnBuilder.accessor("has_children", {
    id: "has_children",
    header: t("form:labels.has_children"),
    cell: ({ row }) =>
      row.original.has_children ? t("form:labels.yes") : t("form:labels.no"),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.ATTRIBUTE_CATEGORY_DESTROY}
        editPermission={PermissionEnum.ATTRIBUTE_CATEGORY_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
