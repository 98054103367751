import { TFunction } from "i18next"
import * as yup from "yup"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { StoreImageRequestInterface } from "@/features/posts/posts/redux/types"
import _ from "lodash"

export default class StoreImageValidation
  implements ValidationInterface<StoreImageRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<StoreImageRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      image: yup
        .mixed<File>()
        .required(t("validation:required"))
        .test(
          "file-extension",
          t("validation:file_extension", {
            allowedExtensions: "jpg, png, jpeg",
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = ["jpg", "png", "jpeg"]
            const fileExtension = value.name.split(".").pop()

            return allowedExtensions.includes(fileExtension || "")
          },
        ),
      order: yup.number().required(t("validation:required")),
    })
  }

  defaultValues(): StoreImageRequestInterface {
    return {
      order: 0,
      image: null,
    }
  }
}
