import {
  SaveSurveyAnswerRequestInterface,
  SaveSurveyRequestInterface,
  SurveyAnswerInterface,
  SurveyInterface,
} from "@/features/surveys/redux/types"
import { create, InstanceProps } from "react-modal-promise"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveSurveyValidation from "@/features/surveys/resources/surveys/_components/form/validations/saveSurveyValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import Modal from "@/features/components/modals/modal"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Button } from "@/features/components/buttons/button"
import Carbon from "@/utils/carbon"
import { SurveyAnswersList } from "@/features/surveys/resources/answers/views/list"
import { Form as AnswerForm } from "@/features/surveys/resources/answers/_components/form"
import {
  useStoreSurveyAnswerMutation,
  useUpdateSurveyAnswerMutation,
} from "@/features/surveys/redux/surveyAPI"
import { toast } from "react-toastify"

type Props = {
  data?: SurveyInterface
  title: string
} & InstanceProps<SaveSurveyRequestInterface, unknown>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  onResolve,
  onReject,
  title,
}): React.ReactNode => {
  const { t } = useTranslation(["utils", "form", "validation"])
  const { schema, defaultValues } = useValidation(new SaveSurveyValidation(), t)
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SaveSurveyRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const [answerState, setAnswerState] = useState<
    "hide" | "list" | "edit" | "store"
  >("hide")
  const [currentAnswer, setCurrentAnswer] = useState<SurveyAnswerInterface>()
  const [storeAnswer] = useStoreSurveyAnswerMutation()
  const [updateAnswer] = useUpdateSurveyAnswerMutation()

  useEffect(() => {
    if (data) {
      setValue(
        "ended_at",
        new Carbon().parse(data.ended_at).toISOString().slice(0, 16),
      )
      setValue("question", data.question)
      setValue(
        "started_at",
        new Carbon().parse(data.started_at).toISOString().slice(0, 16),
      )
      setAnswerState("list")
    }
  }, [data])

  const handleEditAnswer = (answer: SurveyAnswerInterface) => {
    if (!answer) return

    setCurrentAnswer(answer)
    setAnswerState("edit")
  }

  const handleSaveAnswer = (answerData: SaveSurveyAnswerRequestInterface) => {
    if (!data) return

    switch (answerState) {
      case "store":
        storeAnswer({ body: answerData, surveyId: data.id })
          .unwrap()
          .then(() => {
            toast.success(t("surveys:answers.create.success"))
            setAnswerState("list")
          })
        break
      case "edit":
        if (!currentAnswer) return

        updateAnswer({
          body: answerData,
          surveyId: data.id,
          id: currentAnswer.id,
        })
          .unwrap()
          .then(() => {
            toast.success(t("surveys:answers.edit.success"))
            setAnswerState("list")
          })
    }
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content size={"w-[800px]"}>
        <div className={"flex flex-col gap-y-8"}>
          <div className={"flex w-full items-center justify-between"}>
            <span className={"text-lg font-semibold"}>{title}</span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={"cursor-pointer"}
            />
          </div>
          <form
            className={"flex flex-col gap-y-2"}
            onSubmit={handleSubmit(onResolve)}
          >
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  <Input label={t("form:labels.question")} />
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"question"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  <Input
                    type={"datetime-local"}
                    label={t("form:labels.started_at")}
                  />
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"started_at"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  <Input
                    type={"datetime-local"}
                    label={t("form:labels.ended_at")}
                  />
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"ended_at"}
              control={control}
            />
            <div className={"pt-4"}>
              <Button variant={"contained"} type={"submit"}>
                {t("form:buttons.save")}
              </Button>
            </div>
          </form>
          {answerState === "list" && data?.answers && (
            <div className={"flex flex-col gap-y-4"}>
              <div className={"flex justify-between items-center"}>
                <div className={"text-l font-medium"}>
                  <span>{t("surveys:answers.list.title")}</span>
                </div>
                <Button
                  size={"xsmall"}
                  variant={"contained"}
                  onClick={() => setAnswerState("store")}
                >
                  <FontAwesomeIcon icon={faPlus} className={"text-white"} />
                </Button>
              </div>
              <SurveyAnswersList
                totalVotes={data.total_votes_count}
                surveyId={data.id}
                onEdit={handleEditAnswer}
              />
            </div>
          )}
          {(answerState === "edit" || answerState === "store") && (
            <AnswerForm data={currentAnswer} onSubmit={handleSaveAnswer} />
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const surveyFormModal = create(Form)
