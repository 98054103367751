import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { SaveAttributePropsInterface } from "@/features/posts/attributes/redux/types"
import { useStoreAttributeMutation } from "@/features/posts/attributes/redux/attributeAPI"
import { Form } from "@/features/posts/attributes/resources/_components/form"

export const PostAttributesCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(["posts/attributes"])
  const [storeAttribute] = useStoreAttributeMutation()
  const navigate = useNavigate()

  const handleSubmit = (data: SaveAttributePropsInterface) => {
    storeAttribute(data)
      .unwrap()
      .then(() => {
        toast.success(t("posts/attributes:create.success"))
        navigate("/posts/attributes")
      })
  }

  return (
    <div className={"flex flex-col p-4 gap-y-8"}>
      <span className={"text-2xl font-medium"}>
        {t("posts/attributes:create.title")}
      </span>
      <Card>
        <Form onSubmit={handleSubmit} />
      </Card>
    </div>
  )
}
