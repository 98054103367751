import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Button } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./components/table"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import { useStoreAdsSlotMutation } from "@/features/ads/slots/redux/slotAPI"
import { adsSlotFormModal } from "@/features/ads/slots/resources/components"
import _ from "lodash"
import { ValidationErrorInterface } from "@/app/types"
import { isFetchBaseQueryError } from "@/app/hooks"

export const AdsSlotsScreen = (): React.ReactNode => {
  const { t } = useTranslation(["ads_slots"])
  const { hasPermission } = usePermissions()
  const [storeAdsSlot] = useStoreAdsSlotMutation()

  const handleAddAdsSlot = () => {
    adsSlotFormModal({
      title: t("ads_slots:create.title"),
    }).then((data) =>
      storeAdsSlot(data)
        .unwrap()
        .then(() => toast.success(t("ads_slots:create.success")))
        .catch((error) => {
          if (isFetchBaseQueryError(error) && error.status === 422) {
            const errors = error.data as ValidationErrorInterface

            toast.error(() => (
              <ul className={"flex flex-col gap-y-1"}>
                {_.map(errors.errors, (error, key) => (
                  <li key={key}>{error.join(", ")}</li>
                ))}
              </ul>
            ))
          }
        }),
    )
  }

  return (
    <div className={"flex flex-col gap-y-4 p-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.SLOT_SAVE) && (
          <Button
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            onClick={handleAddAdsSlot}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t("ads_slots:list.create")}</span>
          </Button>
        )}
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("ads_slots:list.title")}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}
