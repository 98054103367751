import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { Button } from "@/features/components/buttons/button"
import Card from "@/features/components/cards"
import useValidation from "@/utils/hooks/useValidation"
import FilterValidation from "@/features/posts/_components/posts/filters/validations/filterValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useLazySearchCategoriesQuery } from "@/features/posts/categories/redux/categoryAPI"
import { Select2OptionInterface } from "@/app/types"
import { Query } from "@/utils/query"
import _ from "lodash"
import { SearchFilterInterface } from "@/features/posts/_components/posts/filters/types"
import { Label } from "@/features/components/inputs/label"
import AsyncSelect from "@/features/components/inputs/asyncSelect/asyncSelect"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/features/components/inputs/select"
import { PostTypeEnum } from "@/features/posts/posts/redux/enums/type"
import { Input } from "@/features/components/inputs/input"
import { Checkbox } from "@/features/components/inputs/checkbox"

type Props = {
  onSubmit: (data: SearchFilterInterface) => void
}

export const PostFilter: React.FC<Props> = ({ onSubmit }): React.ReactNode => {
  const { t } = useTranslation(["form"])
  const [show, setShow] = useState<boolean>(false)
  const { schema, defaultValues } = useValidation(new FilterValidation(), t)
  const [searchCategories] = useLazySearchCategoriesQuery()

  const { control, handleSubmit, reset } = useForm<SearchFilterInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const _handleSearchCategories = (
    value: string,
    callback: (options: Select2OptionInterface[]) => void,
  ) => {
    const query = new Query().where("name", value).url()

    searchCategories(query)
      .unwrap()
      .then((response) => {
        callback(
          response.map((category) => ({
            label: category.name,
            value: category.id.toString(),
          })),
        )
      })
  }

  const handleSearchCategories = _.debounce(_handleSearchCategories, 500)

  return (
    <div>
      <Card
        className={clsx(
          "!mb-0 relative flex duration-300 ease-in-out transition-all",
          show ? "h-[450px] lg:h-[360px] mb-2" : "h-[120px]",
        )}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"flex flex-col gap-y-4 w-full overflow-hidden flex-grow-0"}
        >
          <div className={"grid grid-cols-3 lg:grid-cols-4 gap-4 p-1"}>
            <Controller
              control={control}
              name={"category_id"}
              render={({ field }) => (
                <div className={"flex flex-col"}>
                  <Label label={t("form:labels.category")} />
                  <AsyncSelect
                    cacheOptions
                    onChange={(value) => field.onChange(value?.value)}
                    loadOptions={handleSearchCategories}
                  />
                </div>
              )}
            />
            <Controller
              render={({ field }) => (
                <FormControl>
                  <Select
                    buttonClassNames={"!min-w-full"}
                    label={t("form:labels.type")}
                    placeholder={t("form:labels.select_type")}
                    onChange={(_, value) => field.onChange(value)}
                    value={field.value}
                  >
                    {_.map(PostTypeEnum, (value, key) => (
                      <Option value={value} key={key}>
                        {t(`utils:post_types.${value}`)}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={"type"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"number"} label={t("form:labels.price_from")} />
                </FormControl>
              )}
              name={"price_from"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"number"} label={t("form:labels.price_to")} />
                </FormControl>
              )}
              name={"price_to"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input
                    type={"date"}
                    label={t("form:labels.published_from")}
                  />
                </FormControl>
              )}
              name={"published_at_from"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"date"} label={t("form:labels.published_to")} />
                </FormControl>
              )}
              name={"published_at_to"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"number"} label={t("form:labels.post_id")} />
                </FormControl>
              )}
              name={"id"}
              control={control}
            />
          </div>
          <div className={"grid grid-cols-5 gap-4 p-1"}>
            <Controller
              render={({ field }) => (
                <FormControl>
                  <Checkbox
                    onChange={field.onChange}
                    checked={!!field.value}
                    label={t("form:labels.user_registered")}
                  />
                </FormControl>
              )}
              name={"registered_user"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl>
                  <Checkbox
                    onChange={field.onChange}
                    checked={!!field.value}
                    label={t("form:labels.email_visible")}
                  />
                </FormControl>
              )}
              name={"email_visible"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl>
                  <Checkbox
                    onChange={field.onChange}
                    checked={!!field.value}
                    label={t("form:labels.phone_visible")}
                  />
                </FormControl>
              )}
              name={"phone_visible"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl>
                  <Checkbox
                    onChange={field.onChange}
                    checked={!!field.value}
                    label={t("form:labels.is_price_negotiable")}
                  />
                </FormControl>
              )}
              name={"is_price_negotiable"}
              control={control}
            />
          </div>
          <div className={"flex pt-4 gap-x-2 items-center"}>
            <Button size={"small"} type={"submit"} variant={"contained"}>
              {t("form:buttons.search")}
            </Button>
            <Button
              size={"small"}
              className={"bg-gray-500"}
              variant={"contained"}
              type={"button"}
              onClick={() => reset(defaultValues)}
            >
              {t("form:buttons.clear")}
            </Button>
          </div>
        </form>
        <button
          onClick={() => setShow(!show)}
          className={
            "absolute z-[999999999] -bottom-2 -right-3 flex items-center justify-center rounded-full w-8 h-8 bg-blue-500"
          }
        >
          <FontAwesomeIcon
            icon={show ? faChevronUp : faChevronDown}
            className={"text-white"}
          />
        </button>
      </Card>
    </div>
  )
}
