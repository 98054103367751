import { useMemo, useState } from "react"
import { PaginationState } from "./types"

const usePagination = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 15,
  })

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  return [pagination, setPagination] as const
}

export default usePagination
