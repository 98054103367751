import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { Button } from "@/features/components/buttons/button"
import { StoreArticleMediaGalleryInterface } from "@/features/articleMediaGalleries/redux/types"
import StoreArticleMediaGalleryValidation from "../validations/StoreArticleMediaGalleryValidation"
import { FormInterface } from "@/app/types"
import Card from "@/features/components/cards"
import { FormControl } from "@mui/base"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Checkbox } from "@/features/components/inputs/checkbox"
import { Input } from "@/features/components/inputs/input"
import { useDropzone } from "react-dropzone"

const Form: React.FC<FormInterface<StoreArticleMediaGalleryInterface>> = ({
  onSubmit,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "validation"])
  const { schema, defaultValues } = useValidation(
    new StoreArticleMediaGalleryValidation(),
    t,
  )
  const { control, handleSubmit, setValue, watch } =
    useForm<StoreArticleMediaGalleryInterface>({
      resolver: yupResolver(schema),
      defaultValues: defaultValues,
    })

  const watchFiles = watch("files")

  const handleFiles = (files: File[]) => {
    if (files)
      setValue(
        "files",
        Array.from(files).map((file) => ({
          file,
          watermark: false,
          order: 0,
          title: "",
          description: "",
        })),
      )
  }

  const dropzone = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024,
    onDropAccepted: handleFiles,
  })

  return (
    <div className={"p-8"}>
      <Card>
        <div className={"w-full"}>
          <div {...dropzone.getRootProps({ className: "dropzone" })}>
            <input {...dropzone.getInputProps()} />
            <p>{t("utils:dropzone.select_files")}</p>
          </div>
        </div>
      </Card>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"flex flex-col gap-4"}
        >
          <div className={"grid grid-cols-4 gap-4 pt-4"}>
            {watchFiles.map((file, index) => (
              <div className={"flex flex-col gap-4"} key={index}>
                <img
                  src={URL.createObjectURL(file.file)}
                  className={"max-h-[200px]"}
                  alt={""}
                />
                <Controller
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl {...field} error={!!error}>
                      <Input label={t("form:labels.description")} />
                      <FormHelperText message={error?.message} />
                    </FormControl>
                  )}
                  name={`files.${index}.description`}
                />
                <Controller
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl {...field} error={!!error}>
                      <Input label={t("form:labels.title")} />
                      <FormHelperText message={error?.message} />
                    </FormControl>
                  )}
                  name={`files.${index}.title`}
                />
                <Controller
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl {...field} error={!!error}>
                      <Input
                        min={0}
                        type={"number"}
                        label={t("form:labels.order")}
                      />
                      <FormHelperText message={error?.message} />
                    </FormControl>
                  )}
                  name={`files.${index}.order`}
                />
                <Controller
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error}>
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        label={t("form:labels.watermark")}
                      />
                      <FormHelperText message={error?.message} />
                    </FormControl>
                  )}
                  name={`files.${index}.watermark`}
                />
              </div>
            ))}
          </div>
          <div className={"flex pt-4"}>
            <Button variant={"contained"} type={"submit"}>
              {t("form:buttons.save")}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export { Form }
