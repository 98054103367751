import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useStoreServiceMutation } from "@/features/services/redux/serviceAPI"
import { SaveServiceRequestInterface } from "@/features/services/redux/types"
import { Form } from "@/features/services/resources/_components/form"

export const ServiceCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(["services"])
  const [storeService] = useStoreServiceMutation()
  const navigate = useNavigate()

  const handleSubmit = (data: SaveServiceRequestInterface) => {
    storeService(data)
      .unwrap()
      .then(() => {
        toast.success(t("services:create.success"))
        navigate("/services")
      })
  }

  return (
    <div className={"flex flex-col p-4 gap-y-8"}>
      <span className={"text-2xl font-medium"}>
        {t("services:create.title")}
      </span>
      <Card>
        <Form onSubmit={handleSubmit} />
      </Card>
    </div>
  )
}
