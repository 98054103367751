import React from "react"
import clsx from "clsx"

type Props = {
  label: string
  noPadding?: boolean
}

export const Label = ({ label, noPadding }: Props) => {
  return (
    <div
      className={clsx("font-s text-gray-700", {
        "pb-[6px]": !noPadding,
      })}
    >
      <label className="my-2">{label}</label>
    </div>
  )
}
