import { TFunction } from "i18next"
import * as yup from "yup"
import { UpdateNotificationTemplateRequestInterface } from "@/features/notificationTemplates/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { isEmpty } from "lodash"
import { NotificationTemplateTypeEnum } from "@/features/notificationTemplates/redux/enums/types"
import { AvailableLocales } from "@/app/types"

export default class UpdateNotificationTemplateValidation
  implements ValidationInterface<UpdateNotificationTemplateRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<
    UpdateNotificationTemplateRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      type: yup
        .mixed<NotificationTemplateTypeEnum>()
        .oneOf(Object.values(NotificationTemplateTypeEnum))
        .required(t("validation:required")),
      data: yup
        .array(
          yup.object().shape({
            locale: yup.string().oneOf(AvailableLocales).required(),
            email: this.getSchema(
              NotificationTemplateTypeEnum.EMAIL,
            ).nullable(),
            broadcast: this.getSchema(
              NotificationTemplateTypeEnum.BROADCAST,
            ).nullable(),
            sms: this.getSchema(NotificationTemplateTypeEnum.SMS).nullable(),
          }),
        )
        .required(),
    })
  }

  defaultValues(): UpdateNotificationTemplateRequestInterface {
    return {
      type: NotificationTemplateTypeEnum.EMAIL,
      data: [],
    }
  }

  private getSchema(type: NotificationTemplateTypeEnum) {
    return yup.object().shape({
      title: yup
        .string()
        .nullable()
        .when(type, {
          is: (type: NotificationTemplateTypeEnum) =>
            type === NotificationTemplateTypeEnum.EMAIL ||
            type === NotificationTemplateTypeEnum.BROADCAST,
          then: (yup) => yup.required(),
        }),
      content: yup.string().required(),
      tags: yup
        .array(
          yup
            .object({
              name: yup.string().required(),
              system_name: yup.string().required(),
            })
            .required(),
        )
        .required(),
      actions: yup
        .array(
          yup.object().shape({
            system_name: yup.string().required(),
            name: yup.string().required(),
            text: yup.string().required(),
            url: yup.string().nullable(),
            style: yup.lazy((obj) => {
              if (!isEmpty(obj)) {
                const entries = Object.keys(obj).reduce(
                  (acc, key) => ({
                    ...acc,
                    [key]: yup.string().required(),
                  }),
                  {},
                )

                return yup.object().shape(entries)
              }

              return yup.object().shape({})
            }),
          }),
        )
        .required(),
    })
  }
}
