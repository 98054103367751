import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { usePermissions } from "@/utils/hooks/usePermission"

type Props = {
  onEditClick?: () => void
  onDeleteClick?: () => void
  containerClassName?: string
  deletePermission?: PermissionEnum
  editPermission?: PermissionEnum
  onDetailsClick?: () => void
}

export const ActionStack: React.FC<Props> = ({
  onEditClick,
  onDeleteClick,
  containerClassName,
  deletePermission,
  editPermission,
  onDetailsClick,
}): React.ReactNode => {
  const { hasPermission } = usePermissions()

  return (
    <div className={clsx("flex items-center gap-x-2", containerClassName)}>
      {onDetailsClick && (
        <button
          onClick={onDetailsClick}
          className={
            "w-6 h-6 rounded flex items-center justify-center bg-blue-500"
          }
        >
          <FontAwesomeIcon icon={faEye} className={"text-white"} />
        </button>
      )}
      {onEditClick && (!editPermission || hasPermission(editPermission)) && (
        <button
          onClick={onEditClick}
          className={
            "w-6 h-6 rounded flex items-center justify-center bg-amber-500"
          }
        >
          <FontAwesomeIcon icon={faPencil} className={"text-white"} />
        </button>
      )}
      {onDeleteClick &&
        (!deletePermission || hasPermission(deletePermission)) && (
          <button
            onClick={onDeleteClick}
            className={"w-6 h-6 rounded items-center justify-center bg-red-500"}
          >
            <FontAwesomeIcon icon={faTrash} className={"text-white"} />
          </button>
        )}
    </div>
  )
}
