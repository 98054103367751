import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Button } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./_components/table"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import { useStoreSurveyMutation } from "@/features/surveys/redux/surveyAPI"
import { surveyFormModal } from "@/features/surveys/resources/surveys/_components/form"

export const SurveyListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["surveys"])
  const { hasPermission } = usePermissions()
  const [storeSurvey] = useStoreSurveyMutation()

  const handleAddQuestion = () => {
    surveyFormModal({
      title: t("surveys:create.title"),
    }).then((data) =>
      storeSurvey(data)
        .unwrap()
        .then(() => toast.success(t("surveys:create.success"))),
    )
  }

  return (
    <div className={"flex flex-col gap-y-4 p-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.SURVEY_SAVE) && (
          <Button
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            onClick={handleAddQuestion}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t("surveys:list.create")}</span>
          </Button>
        )}
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("surveys:list.title")}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}
