import { addDays, format, subDays, subMonths, subYears } from "date-fns"

class Carbon {
  private date: Date = new Date()

  constructor(date?: Date | string) {
    if (date) {
      this.parse(date)
    }
  }

  now(): Date {
    return this.date
  }

  addDays(days: number): this {
    this.date = addDays(this.date, days)

    return this
  }

  subDays(days: number): this {
    this.date = subDays(this.date, days)

    return this
  }

  subMonths(months: number): this {
    this.date = subMonths(this.date, months)

    return this
  }

  subYears(years: number): this {
    this.date = subYears(this.date, years)

    return this
  }

  format(formatDate: string): string {
    return format(this.date, formatDate)
  }

  getDate(): Date {
    return this.date
  }

  parse(date: string | Date): this {
    if (date instanceof Date) {
      this.date = date
    } else {
      try {
        this.date = new Date(date)
      } catch (error) {
        throw new Error("Invalid date")
      }
    }

    return this
  }

  toISOString(): string {
    return this.date.toISOString()
  }

  toLocaleString(): string {
    return this.date.toLocaleString("pl-PL", { timeZone: "Europe/Warsaw" })
  }

  toDateTimeLocal(): string {
    return this.format("dd.MM.yyyy HH:mm")
  }

  gt(date: Date): boolean {
    return this.date > date
  }

  gte(date: Date): boolean {
    return this.date >= date
  }
}

export default Carbon
