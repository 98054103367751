import { create, InstanceProps } from "react-modal-promise"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetIssueQuery } from "@/features/issues/redux/issueAPI"
import Modal from "@/features/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import Carbon from "@/utils/carbon"

type Props = {
  issueId: number | string
} & InstanceProps<void>

export const IssueDetails: React.FC<Props> = ({
  issueId,
  isOpen,
  onReject,
}): React.ReactNode => {
  const { t } = useTranslation(["issues", "form"])
  const { data } = useGetIssueQuery(issueId)

  return (
    <Modal open={isOpen} onClose={() => onReject()}>
      <Modal.Content size={"lg:w-[800px]"}>
        <div className={"flex w-full flex-row items-center justify-between"}>
          <span className={"text-lg font-semibold"}>
            {t("issues:details.title")}
          </span>
          <FontAwesomeIcon
            onClick={() => onReject()}
            icon={faTimes}
            className={"cursor-pointer"}
          />
        </div>
        {data && (
          <div className={"flex flex-col gap-y-2 pt-6"}>
            <div className={"grid grid-cols-2 gap-4"}>
              <span className={"font-medium"}>{t("form:labels.id")}</span>
              <span>{data.id}</span>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <span className={"font-medium"}>
                {t("form:labels.issue_signature")}
              </span>
              <span>{data.signature}</span>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <span className={"font-medium"}>{t("form:labels.model")}</span>
              <span>{`${t(`utils:models.${data.issueable_type}`)}#${
                data.issueable_id
              }`}</span>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <span className={"font-medium"}>{t("form:labels.content")}</span>
              <span>{data.content}</span>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <span className={"font-medium"}>{t("form:labels.status")}</span>
              <span>{t(`issues:statuses.${data.status}`)}</span>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <span className={"font-medium"}>
                {t("form:labels.attributes")}
              </span>
              <span></span>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <span className={"font-medium"}>
                {t("form:labels.created_at")}
              </span>
              <span>{new Carbon(data.created_at).toLocaleString()}</span>
            </div>
          </div>
        )}
      </Modal.Content>
    </Modal>
  )
}

export const issueDetailsModal = create(IssueDetails)
