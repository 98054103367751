import React, { useEffect } from "react"
import { create, InstanceProps } from "react-modal-promise"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import UpdateInvoiceValidation from "@/features/invoices/resources/views/edit/validations/updateInvoiceValidation"
import { UpdateInvoiceRequestInterface } from "@/features/invoices/redux/types"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
} from "@/features/invoices/redux/invoiceAPI"
import Modal from "@/features/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import _ from "lodash"
import { Button } from "@/features/components/buttons/button"
import Carbon from "@/utils/carbon"
import { toast } from "react-toastify"

type Props = {
  id: string
} & InstanceProps<unknown>

const InvoiceEditForm: React.FC<Props> = ({
  id,
  isOpen,
  onResolve,
  onReject,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "invoices", "validation"])
  const [updateInvoice] = useUpdateInvoiceMutation()
  const { data } = useGetInvoiceQuery(id)
  const { schema, defaultValues } = useValidation(
    new UpdateInvoiceValidation(),
    t,
  )
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateInvoiceRequestInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (data) {
      setValue("issue_date", new Carbon(data.issue_date).format("yyyy-MM-dd"))
      setValue("sale_date", new Carbon(data.sale_date).format("yyyy-MM-dd"))
      setValue("buyer", data.buyer)
    }
  }, [data])

  const onSubmit = (data: UpdateInvoiceRequestInterface) => {
    updateInvoice({
      id,
      body: data,
    })
      .unwrap()
      .then(() => {
        toast.success(t("invoices:edit.success"))
        onResolve()
      })
  }

  return (
    <Modal onClose={() => onReject()} open={isOpen}>
      <Modal.Content size={"lg:w-[800px]"}>
        <div className={"flex flex-col gap-y-4"}>
          <div className={"flex w-full items-center justify-between"}>
            <span className={"text-lg font-semibold"}>
              {t("invoices:edit.title")}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={"cursor-pointer"}
            />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={"grid grid-cols-3 gap-4"}
          >
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  <Input type={"date"} label={t("form:labels.issue_date")} />
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"issue_date"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  <Input type={"date"} label={t("form:labels.sale_date")} />
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"sale_date"}
              control={control}
            />
            <div className={"col-span-3"}>
              <span className={"font-medium"}>
                {t("invoices:edit.buyer_information")}
              </span>
            </div>
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!_.get(errors, field.name)}>
                  <Input label={t("form:labels.company_name")} />
                  <FormHelperText
                    message={_.get(errors, field.name)?.message}
                  />
                </FormControl>
              )}
              name={"buyer.company_name"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!_.get(errors, field.name)}>
                  <Input label={t("form:labels.nip")} />
                  <FormHelperText
                    message={_.get(errors, field.name)?.message}
                  />
                </FormControl>
              )}
              name={"buyer.nip"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!_.get(errors, field.name)}>
                  <Input label={t("form:labels.street")} />
                  <FormHelperText
                    message={_.get(errors, field.name)?.message}
                  />
                </FormControl>
              )}
              name={"buyer.street"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!_.get(errors, field.name)}>
                  <Input label={t("form:labels.postal_code")} />
                  <FormHelperText
                    message={_.get(errors, field.name)?.message}
                  />
                </FormControl>
              )}
              name={"buyer.postal_code"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!_.get(errors, field.name)}>
                  <Input label={t("form:labels.city")} />
                  <FormHelperText
                    message={_.get(errors, field.name)?.message}
                  />
                </FormControl>
              )}
              name={"buyer.city"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!_.get(errors, field.name)}>
                  <Input label={t("form:labels.property_number")} />
                  <FormHelperText
                    message={_.get(errors, field.name)?.message}
                  />
                </FormControl>
              )}
              name={"buyer.property_number"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!_.get(errors, field.name)}>
                  <Input label={t("form:labels.apartment_number")} />
                  <FormHelperText
                    message={_.get(errors, field.name)?.message}
                  />
                </FormControl>
              )}
              name={"buyer.apartment_number"}
              control={control}
            />
            <div className={"col-span-3 flex gap-x-4 pt-4"}>
              <Button
                variant={"contained"}
                type={"submit"}
                className={"w-full"}
              >
                {t("form:buttons.save")}
              </Button>
              <Button
                variant={"outlined"}
                onClick={() => onReject()}
                className={"w-full"}
              >
                {t("form:buttons.cancel")}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const invoiceEditFormModal = create(InvoiceEditForm)
