import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { ButtonLink } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./_components/table"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"

export const FAQCategoriesListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["faq/categories"])
  const { hasPermission } = usePermissions()

  return (
    <div className={"flex flex-col p-8 gap-y-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.FAQ_SAVE) && (
          <ButtonLink
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            to={"/faq/categories/create"}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t("faq/categories:list.create")}</span>
          </ButtonLink>
        )}
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("faq/categories:list.title")}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}
