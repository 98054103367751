import { TFunction } from "i18next"
import * as yup from "yup"
import { SearchFilterInterface } from "../types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import _ from "lodash"

export default class FilterValidation
  implements ValidationInterface<SearchFilterInterface>
{
  rules(t: TFunction): yup.ObjectSchema<SearchFilterInterface, yup.AnyObject> {
    return yup.object().shape({
      date_from: yup.string().nullable(),
      date_to: yup.string().nullable(),
      amount_from: yup
        .number()
        .nullable()
        .transform((value: number | string | undefined) =>
          _.isNaN(value) ? null : value,
        ),
      amount_to: yup
        .number()
        .nullable()
        .transform((value: number | string | undefined) =>
          _.isNaN(value) ? null : value,
        ),
      user_id: yup.number().nullable(),
      type: yup
        .mixed<"collective" | "instant">()
        .oneOf(["collective", "instant"])
        .nullable(),
    })
  }

  defaultValues(): SearchFilterInterface {
    return {
      date_from: null,
      date_to: null,
      amount_from: null,
      amount_to: null,
      user_id: null,
      type: null,
    }
  }
}
