import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { type RootState } from "@/app/store"

export let url = "http://localhost:5173"

if (import.meta.env) {
  url = import.meta.env.VITE_IGRIT_API_URL
}

export const tagTypes = [
  "User",
  "Category",
  "PostAudit",
  "PostService",
  "Post",
  "Invoice",
  "InvoiceStatistic",
  "NotificationTemplate",
  "Issue",
  "Advertiser",
  "Page",
  "User",
  "ServiceConfig",
  "Service",
  "Survey",
  "Role",
  "Attribute",
  "AttributeCategory",
  "FAQ_CATEGORY_QUESTION",
  "FAQ_CATEGORY",
  "Censorship",
  "ServiceCategory",
  "Package",
  "PackageCategory",
  "ArticleCategory",
  "BlackList",
  "ArticleGallery",
  "ArticleMediaGallery",
  "AdsView",
  "AdsSlots",
  "BundleCategory",
  "CategoryTree",
] as const
export type TagTypes = (typeof tagTypes)[number]

const igritAPI = createApi({
  tagTypes,
  reducerPath: "igritAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const currentLanguage = window.localStorage.getItem("i18nextLng")
      const token = (getState() as RootState).auth.token

      headers.set("Accept", "application/json")
      headers.set("Content-Language", currentLanguage ?? "pl")

      if (headers.get("Content-Type") === "multipart/form-data") {
        headers.delete("Content-Type")
      } else {
        headers.set("Content-Type", "application/json")
      }

      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({}),
})

export default igritAPI
