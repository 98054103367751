import {
  MediaElasticSearchInterface,
  PostInterface,
} from "@/features/posts/posts/redux/types"
import React, { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  useChangePostMediaOrderMutation,
  useDeletePostImageMutation,
} from "@/features/posts/posts/redux/postAPI"
import { toast } from "react-toastify"
import { Input } from "@/features/components/inputs/input"
import _ from "lodash"

type Props = {
  data: PostInterface
}

export const Gallery: React.FC<Props> = ({ data }): React.ReactNode => {
  const { t } = useTranslation(["posts/posts"])
  const [deleteImage] = useDeletePostImageMutation()
  const [changeOrder] = useChangePostMediaOrderMutation()

  const handleDeleteImage = (id: string) => {
    deleteImage({
      id: id,
      postId: data.id,
    })
      .unwrap()
      .then(() => toast.success(t("posts/posts:edit.images.delete_success")))
  }

  const getThumbnailUrl = (media: MediaElasticSearchInterface[]): string => {
    return media.filter((image) => image.collection_name === "thumbnail")[0].url
  }

  const _onOrderChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    mediaId: string,
  ) => {
    const order = Number(e.target.value)

    changeOrder({
      mediaId: mediaId,
      postId: data.id,
      order: order,
    })
      .unwrap()
      .then(() => toast.success(t("posts/posts:edit.images.order_success")))
  }

  const onOrderChange = _.debounce(_onOrderChange, 1000)

  return (
    <Card>
      <div className={"flex flex-col gap-y-4"}>
        <span className={"font-medium"}>
          {t("posts/posts:edit.images.title")}
        </span>
      </div>
      <div className={"flex gap-4 flex-wrap pt-8"}>
        {data.media_groups.map((image, index) => (
          <div key={index} className={"flex flex-col gap-y-4"}>
            <div className={"relative max-h-32"}>
              <img src={getThumbnailUrl(image.media)} alt={"-"} />
              <button
                className={
                  "w-6 h-6 absolute -top-2 -right-2 size-5 rounded-full bg-red-600 flex justify-center items-center"
                }
                onClick={() => handleDeleteImage(image.id)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className={"text-white hover:rotate-90 transition-all"}
                />
              </button>
            </div>
            <div className={"flex justify-center"}>
              <Input
                containerClassName={"max-w-[100px]"}
                defaultValue={image.order}
                onChange={(e) => onOrderChange(e, image.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}
