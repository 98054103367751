import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Button } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./_components/table"
import { serviceConfigForm } from "../_components/form"
import { toast } from "react-toastify"
import { useStoreServiceConfigMutation } from "@/features/services/redux/serviceAPI"
import { ServiceNameEnum } from "@/features/posts/posts/redux/enums/service"

type Props = {
  serviceId: number | string
  serviceName: ServiceNameEnum
}

export const ServiceConfigList: React.FC<Props> = ({
  serviceId,
  serviceName,
}): React.ReactNode => {
  const { t } = useTranslation("services")
  const [storeServiceConfig] = useStoreServiceConfigMutation()

  const handleStoreServiceCategory = () => {
    serviceConfigForm({
      title: t("services:config.create.title"),
      serviceName,
    }).then((data) => {
      storeServiceConfig({ serviceId, body: data })
        .unwrap()
        .then(() => toast.success(t("services:config.create.success")))
    })
  }

  return (
    <div className={"flex flex-col gap-y-4"}>
      <div className={"flex flex-row-reverse"}>
        <Button
          variant={"contained"}
          className={"flex items-center gap-x-2"}
          onClick={handleStoreServiceCategory}
        >
          <FontAwesomeIcon icon={faPlus} className={"text-white"} />
          <span>{t("services:config.list.create")}</span>
        </Button>
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("services:config.list.title")}</span>
          </div>
          <Table serviceName={serviceName} serviceId={serviceId} />
        </div>
      </Card>
    </div>
  )
}
