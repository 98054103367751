import React, { Fragment, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { create, InstanceProps } from "react-modal-promise"
import useValidation from "@/utils/hooks/useValidation"
import SavePostServiceValidation from "@/features/posts/posts/resources/views/edit/_components/services/_components/form/validations/savePostServiceValidation"
import { Controller, useForm } from "react-hook-form"
import {
  PostServiceInterface,
  SavePostServiceRequestInterface,
} from "@/features/posts/posts/redux/types"
import { yupResolver } from "@hookform/resolvers/yup"
import Modal from "@/features/components/modals/modal"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import Carbon from "@/utils/carbon"
import { Button } from "@/features/components/buttons/button"
import Select, { Option } from "@/features/components/inputs/select"
import { postServiceNameEnum } from "@/features/posts/posts/redux/enums/service"
import _ from "lodash"
import { useLazyGetServiceConfigsQuery } from "@/features/services/redux/serviceAPI"

type Props = {
  data?: PostServiceInterface
} & InstanceProps<SavePostServiceRequestInterface>

const PostServiceForm: React.FC<Props> = ({
  isOpen,
  onReject,
  data,
  onResolve,
}): React.ReactNode => {
  const { t } = useTranslation(["posts/posts", "validation", "form", "utils"])
  const [getConfig, { data: configs = [] }] = useLazyGetServiceConfigsQuery()
  const { schema, defaultValues } = useValidation(
    new SavePostServiceValidation(configs),
    t,
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<SavePostServiceRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const watchSystemName = watch("system_name")

  useEffect(() => {
    if (data) {
      setValue("system_name", data.system_name)
      setValue("quantity", data.quantity)
      if (data.end_at) {
        setValue("end_at", new Carbon(data.end_at).format("yyyy-MM-dd hh:mm"))
      }

      setValue("value", data.value)
    }
  }, [data])

  useEffect(() => {
    getConfig(watchSystemName)
  }, [getConfig, watchSystemName])

  return (
    <Modal open={isOpen} onClose={() => {}}>
      <Modal.Content size={"lg:w-[400px]"}>
        <Fragment>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold"}>
              {watchSystemName
                ? t(`utils:services.${watchSystemName}`)
                : t("posts/posts:edit.services.add")}
            </span>
            <FontAwesomeIcon
              onClick={() => onReject()}
              icon={faTimes}
              className={"cursor-pointer"}
            />
          </div>
          <form
            onSubmit={handleSubmit(onResolve)}
            className={"flex flex-col gap-y-4 pt-4"}
          >
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <Select
                    disabled={!!data}
                    value={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    label={t("form:labels.system_name")}
                  >
                    {_.map(postServiceNameEnum, (service) => (
                      <Option value={service} key={service}>
                        {t(`utils:services.${service}`)}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={"system_name"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  <Input
                    type={"number"}
                    min={0}
                    label={t("form:labels.quantity")}
                  />
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"quantity"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  {configs.length ? (
                    <Select
                      value={field.value}
                      placeholder={t("form:placeholders.select_value")}
                      onChange={(_, value) => field.onChange(value)}
                      label={t("form:labels.value")}
                    >
                      {configs.map((config) => (
                        <Option value={config.value} key={config.value}>
                          {config.value}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input label={t("form:labels.value")} />
                  )}
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"value"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors[field.name]}>
                  <Input
                    type={"datetime-local"}
                    label={t("form:labels.end_at")}
                  />
                  <FormHelperText message={errors[field.name]?.message} />
                </FormControl>
              )}
              name={"end_at"}
              control={control}
            />
            <div className={"flex gap-x-2 pt-4"}>
              <Button
                variant={"contained"}
                className={"w-full"}
                type={"submit"}
              >
                {t("form:buttons.save")}
              </Button>
              <Button
                className={"w-full"}
                variant={"outlined"}
                onClick={() => onReject()}
              >
                {t("form:buttons.cancel")}
              </Button>
            </div>
          </form>
        </Fragment>
      </Modal.Content>
    </Modal>
  )
}

export const postServiceForm = create(PostServiceForm)
