import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { Query } from "@/utils/query"
import usePagination from "@/utils/hooks/usePagination"
import { IssueInterface } from "@/features/issues/redux/types"
import {
  useDeleteIssueMutation,
  useGetIssuesQuery,
} from "@/features/issues/redux/issueAPI"
import { issueDetailsModal } from "@/features/issues/resources/views/details"
import { issueEditModal } from "@/features/issues/resources/views/edit"
import { toast } from "react-toastify"

type Props = {
  query: Query
}

export const Table: React.FC<Props> = ({
  query: baseQuery,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "issues"])
  const [{ pageIndex, pageSize }, setPagination] = usePagination()
  const [data, setData] = useState<IssueInterface[]>([])
  const [deleteIssue] = useDeleteIssueMutation()
  const query = useMemo(() => baseQuery.page(pageIndex), [pageIndex, baseQuery])
  const { data: apiData } = useGetIssuesQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = (id: number) => {
    deleteIssue(id)
      .unwrap()
      .then(() => toast.success(t("issues:delete.success")))
  }

  const handleEdit = (id: number) => {
    void issueEditModal({
      issueId: id,
    })
  }

  const handleDetails = (id: number) => {
    void issueDetailsModal({
      issueId: id,
    })
  }

  const table = useReactTable({
    columns: columns(t, handleDetails, handleEdit, handleDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<IssueInterface>()

const columns = (
  t: Function,
  onDetails: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
) => [
  columnBuilder.accessor("id", {
    id: "id",
    header: t("form:labels.id"),
  }),
  columnBuilder.accessor("signature", {
    id: "signature",
    header: t("form:labels.issue_signature"),
  }),
  columnBuilder.accessor("issueable_type", {
    id: "issueable_type",
    header: t("form:labels.model"),
    cell: ({ row }) => (
      <span>
        {t(`utils:models.${row.original.issueable_type}`)}#
        {row.original.issueable_id}
      </span>
    ),
  }),
  columnBuilder.accessor("type", {
    id: "type",
    header: t("form:labels.type"),
    cell: ({ row }) => <span>{t(`issues:types.${row.original.type}`)}</span>,
  }),
  columnBuilder.accessor("status", {
    id: "status",
    header: t("form:labels.status"),
    cell: ({ row }) => t(`issues:statuses.${row.original.status}`),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.ISSUE_DESTROY}
        editPermission={PermissionEnum.ISSUE_SAVE}
        onEditClick={() => onEdit(row.original.id)}
        onDetailsClick={() => onDetails(row.original.id)}
        onDeleteClick={() => onDelete(row.original.id)}
      />
    ),
  }),
]
