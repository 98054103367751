import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  GetAuthUserResponseInterface,
  LogInPropsInterface,
  LogInResponseInterface,
} from "@/features/auth/redux/types"
import axios, { AxiosError } from "axios"
import igritAPI, { url } from "@/utils/api/igrit"
import { ErrorResponseInterface, SuccessResponseInterface } from "@/app/types"
import { RootState } from "@/app/store"

export const loginMutation = createAsyncThunk<
  LogInResponseInterface,
  LogInPropsInterface
>("auth/login", async (data, thunkAPI) => {
  const response = await axios.post(`${url}/v1/auth/login`, data)

  if (response.data && response.data.code === 200) {
    return response.data
  }

  return thunkAPI.rejectWithValue({
    error: null,
    code: response.data.code,
  })
})

export const logoutRequestMutation = createAsyncThunk<
  SuccessResponseInterface,
  void,
  { rejectValue: ErrorResponseInterface; state: RootState }
>("auth/logout", async (_, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/v1/auth/logout`, [], {
      headers: {
        Authorization: `Bearer ${thunkAPI.getState().auth.token}`,
      },
    })

    return response.data
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return thunkAPI.rejectWithValue({
        data: error.response.data,
        status: error.response.status,
      })
    }
  }
})

export const { useGetMeQuery } = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<GetAuthUserResponseInterface["data"], void>({
      query: () => `/v1/auth/me`,
      transformResponse: (response: GetAuthUserResponseInterface) =>
        response.data,
      providesTags: [{ type: "User", id: "Me" }],
    }),
  }),
})
