import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { useNavigate } from "react-router-dom"
import { CategoryInterface } from "@/features/posts/categories/redux/types"
import usePagination from "@/utils/hooks/usePagination"
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from "@/features/posts/categories/redux/categoryAPI"
import { Query } from "@/utils/query"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import { isCustomError } from "@/utils/api/rtkHelper"
import { ResponseCodeEnum } from "@/app/types"

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(["form", "posts/categories"])
  const navigate = useNavigate()
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () => new Query().includes("parentCategory").page(pagination.pageIndex),
    [pagination],
  )
  const { data: apiData } = useGetCategoriesQuery(query.url())
  const [data, setData] = useState<CategoryInterface[]>([])
  const [deleteCategory] = useDeleteCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteCategory(id)
      .unwrap()
      .then(() => toast.success(t("posts/categories:list.deleted")))
      .catch((error) => {
        if (
          isCustomError(error, ResponseCodeEnum.CATEGORY_CAN_NOT_BE_DELETED)
        ) {
          toast.error(t("posts/categories:list.can_not_be_deleted"))
        }
      })
  }

  const table = useReactTable({
    columns: columns(t, onDelete, (id) =>
      navigate(`/posts/categories/${id}/edit`),
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<CategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("name", {
    id: "type",
    header: t("form:labels.name"),
  }),
  columnBuilder.accessor("seo_keywords", {
    id: "seo_keywords",
    header: t("form:labels.seo_keywords"),
  }),
  columnBuilder.accessor("seo_description", {
    id: "seo_description",
    header: t("form:labels.seo_description"),
  }),
  columnBuilder.accessor("parent_category.name", {
    id: "parent_category",
    header: t("form:labels.parent_category"),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.CATEGORY_DESTROY}
        editPermission={PermissionEnum.CATEGORY_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
