import React, { useState } from "react"
import { FormInterface, Select2OptionInterface } from "@/app/types"
import {
  ArticleInterface,
  SaveArticleRequestInterface,
} from "@/features/articles/redux/types"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveArticleValidation from "./SaveArticleValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import Card from "@/features/components/cards"
import { Checkbox } from "@/features/components/inputs/checkbox"
import { Editor } from "@/features/components/inputs/editor"
import { GalleryEnum } from "@/app/enums/galleryEnum"
import { Label } from "@/features/components/inputs/label"
import { SingleValue } from "react-select"
import { SearchCategory } from "@/features/components/inputs/asyncSelect/searchCategory"

const ArticleForm: React.FC<
  FormInterface<SaveArticleRequestInterface, ArticleInterface>
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(["form", "validation"])
  const { schema, defaultValues } = useValidation(
    new SaveArticleValidation(),
    t,
  )
  const { control, setValue, setError, handleSubmit } =
    useForm<SaveArticleRequestInterface>({
      resolver: yupResolver(schema),
      defaultValues,
    })
  const [selectedPostCategory, setSelectedPostCategory] =
    useState<Select2OptionInterface>()

  const handleChangePostCategory = (
    value: SingleValue<Select2OptionInterface>,
  ) => {}

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
      <div className={"grid grid-cols-6 gap-4"}>
        <div className={"col-span-4"}>
          <Card>
            <div className={"grid gap-4"}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl {...field} error={!!error}>
                    <Input label={t("form:labels.title")} />
                    <FormHelperText message={error?.message} />
                  </FormControl>
                )}
                name={"title"}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl {...field} error={!!error}>
                    <Input multiline rows={5} label={t("form:labels.lead")} />
                    <FormHelperText message={error?.message} />
                  </FormControl>
                )}
                name={"lead"}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Label label={t("form:labels.content")} />
                    <Editor
                      onChange={field.onChange}
                      data={field.value}
                      galleryType={GalleryEnum.ARTICLE}
                    />
                    <FormHelperText message={error?.message} />
                  </FormControl>
                )}
                name={"content"}
                control={control}
              />
            </div>
          </Card>
        </div>
        <div className={"flex flex-col col-span-2 gap-4"}>
          <Card>
            <span className={"font-semibold text-lg"}>
              {t("form:labels.additional_information")}
            </span>
            <div className={"flex flex-col gap-4 pt-8"}>
              <Controller
                render={({ field }) => (
                  <Checkbox
                    label={t("form:labels.promoted")}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
                name={"promoted"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Checkbox
                    label={t("form:labels.important")}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
                name={"important"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Checkbox
                    label={t("form:labels.article_of_day")}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
                name={"article_of_day"}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Label label={t("form:labels.post_category")} />
                    <SearchCategory
                      onChange={handleChangePostCategory}
                      isMulti={false}
                      defaultValue={selectedPostCategory}
                    />
                  </FormControl>
                )}
                name={"category_id"}
                control={control}
              />
            </div>
          </Card>
          <Card>
            <span className={"font-semibold text-lg"}>
              {t("form:labels.seo")}
            </span>
            <div className={"flex flex-col gap-4 pt-8"}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl {...field} error={!!error}>
                    <Input label={t("form:labels.seo_keywords")} />
                    <FormHelperText message={error?.message} />
                  </FormControl>
                )}
                name={"seo_keywords"}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl {...field} error={!!error}>
                    <Input label={t("form:labels.seo_canonical_url")} />
                    <FormHelperText message={error?.message} />
                  </FormControl>
                )}
                name={"seo_canonical_url"}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl {...field} error={!!error}>
                    <Input label={t("form:labels.seo_description")} />
                    <FormHelperText message={error?.message} />
                  </FormControl>
                )}
                name={"seo_description"}
                control={control}
              />
            </div>
          </Card>
        </div>
      </div>
    </form>
  )
}

export default ArticleForm
