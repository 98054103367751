import React, { useEffect, useMemo, useState } from "react"
import { useGetPostAuditsQuery } from "@/features/posts/posts/redux/postAPI"
import usePagination from "@/utils/hooks/usePagination"
import { Query } from "@/utils/query"
import { AuditInterface } from "@/app/types"
import { AuditTable } from "@/features/components/table/audits"

type Props = {
  id: string | number
}

export const PostAuditList: React.FC<Props> = ({ id }): React.ReactNode => {
  const [{ pageIndex }, setPagination] = usePagination()
  const query = useMemo(
    () => new Query().page(pageIndex).limit(5).url(),
    [pageIndex],
  )
  const [data, setData] = useState<AuditInterface[]>([])
  const { data: apiData } = useGetPostAuditsQuery({ id, query })

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  return (
    <AuditTable
      data={data}
      pagination={apiData?.pagination}
      onPageChange={(page) =>
        setPagination((prev) => ({ ...prev, pageIndex: page }))
      }
    />
  )
}
