import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Button } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./components/table"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import { useStoreAdsViewMutation } from "@/features/ads/views/redux/viewAPI"
import { adsViewFormModal } from "@/features/ads/views/resources/components"

export const AdsViewsScreen = (): React.ReactNode => {
  const { t } = useTranslation(["ads_views"])
  const { hasPermission } = usePermissions()
  const [storeAdsView] = useStoreAdsViewMutation()

  const handleAddAdsView = () => {
    adsViewFormModal({
      title: t("ads_views:create.title"),
    }).then((data) =>
      storeAdsView(data)
        .unwrap()
        .then(() => toast.success(t("ads_views:create.success"))),
    )
  }

  return (
    <div className={"flex flex-col gap-y-4 p-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.VIEW_SAVE) && (
          <Button
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            onClick={handleAddAdsView}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t("ads_views:list.create")}</span>
          </Button>
        )}
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("ads_views:list.title")}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}
