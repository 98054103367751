import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { useNavigate } from "react-router-dom"
import usePagination from "@/utils/hooks/usePagination"
import { Query } from "@/utils/query"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import {
  useDeletePageMutation,
  useGetPagesQuery,
} from "@/features/pages/redux/pageAPI"
import { PageInterface } from "@/features/pages/redux/types"

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(["form", "pages", "utils"])
  const navigate = useNavigate()
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination.pageIndex],
  )
  const { data: apiData } = useGetPagesQuery(query.url())
  const [data, setData] = useState<PageInterface[]>([])
  const [deletePage] = useDeletePageMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deletePage(id)
      .unwrap()
      .then(() => toast.success(t("pages:list.deleted")))
  }

  const table = useReactTable({
    columns: columns(t, onDelete, (id) => navigate(`/pages/${id}/edit`)),
    getCoreRowModel: getCoreRowModel(),
    data,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<PageInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("title", {
    id: "title",
    header: t("form:labels.title"),
  }),
  columnBuilder.accessor("slug", {
    id: "slug",
    header: t("form:labels.slug"),
  }),
  columnBuilder.accessor("is_active", {
    id: "is_active",
    header: t("form:labels.is_active"),
    cell: ({ row }) =>
      row.original.is_active ? t("form:labels.yes") : t("form:labels.no"),
  }),
  columnBuilder.accessor("in_menu", {
    id: "in_menu",
    header: t("form:labels.in_menu"),
    cell: ({ row }) =>
      row.original.in_menu ? t("form:labels.yes") : t("form:labels.no"),
  }),
  columnBuilder.accessor("in_footer", {
    id: "in_footer",
    header: t("form:labels.in_footer"),
    cell: ({ row }) =>
      row.original.in_footer ? t("form:labels.yes") : t("form:labels.no"),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.PAGE_DESTROY}
        editPermission={PermissionEnum.PAGE_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
