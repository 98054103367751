import React from "react"
import { Form } from "./partials"
import { StoreArticleMediaGalleryInterface } from "@/features/articleMediaGalleries/redux/types"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useStoreArticleMediaGalleryMutation } from "@/features/articleMediaGalleries/redux/articleMediaGalleryAPI"
import { serialize } from "object-to-formdata"

export const CreateArticleMediaGalleryScreen = (): React.ReactNode => {
  const [storeArticleMediaGallery] = useStoreArticleMediaGalleryMutation()
  const { t } = useTranslation("article_media_gallery")
  const navigate = useNavigate()
  const { id } = useParams() as { id: string }

  const onSubmit = (data: StoreArticleMediaGalleryInterface) => {
    const formData = serialize(data, {
      nullsAsUndefineds: true,
      booleansAsIntegers: true,
      indices: true,
    })

    storeArticleMediaGallery({ data: formData, galleryId: id })
      .unwrap()
      .then(() => {
        toast.success(t("article_media_gallery:create.success"))
        navigate(`/article-gallery/${id}/article-media/`)
      })
  }
  return <Form onSubmit={onSubmit} />
}
