import igritAPI from "@/utils/api/igrit"
import {
  GetIssueResponseInterface,
  GetIssuesResponseInterface,
  IssueInterface,
  UpdateIssueRequestInterface,
} from "@/features/issues/redux/types"
import { setPaginatedCache, setSingleObjectCache } from "@/utils/api/rtkHelper"

export const {
  useGetIssuesQuery,
  useGetIssueQuery,
  useUpdateIssueMutation,
  useDeleteIssueMutation,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getIssues: builder.query<GetIssuesResponseInterface["data"], string>({
      query: (query) => `/admin/issues?${query}`,
      transformResponse: (response: GetIssuesResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache("Issue", result),
    }),
    getIssue: builder.query<IssueInterface, string | number>({
      query: (id) => `/admin/issues/${id}`,
      transformResponse: (response: GetIssueResponseInterface) => response.data,
      providesTags: (result) => setSingleObjectCache("Issue", result),
    }),
    updateIssue: builder.mutation<
      void,
      { id: number | string; data: UpdateIssueRequestInterface }
    >({
      query: ({ id, data }) => ({
        url: `/admin/issues/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: "Issue", id },
        { type: "Issue", id: "LIST" },
      ],
    }),
    deleteIssue: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/admin/issues/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Issue", id: "LIST" }],
    }),
  }),
})
