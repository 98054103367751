import { isArray } from "lodash"
import {
  AttributeCategoryInterface,
  SaveAttributeInterface,
  SaveAttributeRequestInterface,
  SaveAttributeValueInterface,
  SaveAttributeValueRequestInterface,
  StoreAttributeInterface,
  StoreAttributeValueInterface,
} from "@/features/posts/attributeCategories/redux/types"
import { v4 as uuid } from "uuid"
import {
  instanceOfAttribute,
  instanceOfAttributeValue,
} from "@/features/posts/attributeCategories/redux/helper"

export const useHelper = () => {
  const assignValue = (
    fields:
      | SaveAttributeValueInterface[]
      | SaveAttributeValueInterface
      | SaveAttributeInterface,
    uuid: string,
    attribute: SaveAttributeInterface,
  ): SaveAttributeValueInterface[] | SaveAttributeValueInterface => {
    if (isArray(fields)) {
      fields.map((field) => assignValue(field, uuid, attribute))
    } else {
      if (fields.uuid === uuid && instanceOfAttributeValue(fields)) {
        if (fields.children) {
          fields.children = replaceChildren(fields.children, attribute)
        } else {
          fields.children = [attribute]
        }
      } else if (instanceOfAttribute(fields)) {
        if (fields.values) {
          fields.values.map((field) => assignValue(field, uuid, attribute))
        }
      }
    }

    return fields as SaveAttributeValueInterface[]
  }

  const replaceChildren = (
    children: SaveAttributeInterface[],
    attribute: SaveAttributeInterface,
  ): SaveAttributeInterface[] => {
    const index = children.findIndex(
      (field) => field.attribute_id === attribute.attribute_id,
    )

    if (index !== -1) {
      children[index].values = [
        ...children[index].values,
        ...attribute.values.map((item) => ({
          value: item.value,
          uuid: item.uuid,
        })),
      ]
    } else {
      children.push(attribute)
    }

    return children
  }

  const deleteRecursiveArray = (
    data: (StoreAttributeInterface | StoreAttributeValueInterface)[],
    uuidToRemove: string,
  ): (StoreAttributeInterface | StoreAttributeValueInterface)[] => {
    return data.reduce(
      (
        acc: (StoreAttributeInterface | StoreAttributeValueInterface)[],
        item,
      ) => {
        if (instanceOfAttribute(item) && item.values) {
          item.values = deleteRecursiveArray(
            item.values,
            uuidToRemove,
          ) as StoreAttributeValueInterface[]
        }

        if (instanceOfAttributeValue(item) && item.children) {
          item.children = deleteRecursiveArray(
            item.children as (
              | StoreAttributeInterface
              | StoreAttributeValueInterface
            )[],
            uuidToRemove,
          ) as StoreAttributeInterface[]
        }

        if (item.uuid !== uuidToRemove) {
          acc.push(item)
        }

        return acc
      },
      [],
    )
  }

  const transformToForm = (
    data: AttributeCategoryInterface,
  ): SaveAttributeInterface => {
    const transformedData: SaveAttributeInterface = {
      uuid: uuid(),
      attribute_id: data.attribute_id,
      category_id: data.category_id,
      affects_price: data.affects_price,
      values: [],
    }

    if (data.values && data.values.length) {
      transformedData["values"] = data.values.map((value) => {
        const transformedValue: SaveAttributeValueInterface = {
          _id: value.id,
          uuid: uuid(),
          value: value.value,
        }

        if (value.children && value.children.length) {
          return {
            ...transformedValue,
            children: value.children.map((child) => transformToForm(child)),
          }
        }

        return transformedValue
      })
    }

    return transformedData
  }

  const findInArray = (
    values: (SaveAttributeValueInterface | SaveAttributeInterface)[],
    uuid: string,
  ): SaveAttributeValueInterface | SaveAttributeInterface | undefined => {
    for (let i = 0; i < values.length; i++) {
      const object = values[i]

      if (object.uuid === uuid) {
        return values[i]
      }

      if (instanceOfAttribute(object) && object.values) {
        const result = findInArray(object.values, uuid)

        if (result) {
          return result
        }
      }

      if (instanceOfAttributeValue(object) && object.children) {
        const result = findInArray(object.children, uuid)

        if (result) {
          return result
        }
      }
    }
  }

  const transformToUpdate = (
    data: SaveAttributeInterface,
  ): SaveAttributeRequestInterface => {
    const { values, _id, ...rest } = data

    const transformedData: SaveAttributeRequestInterface = {
      ...rest,
      id: _id,
      values: [],
    }

    if (values.length) {
      transformedData.values = values.map((value) => {
        const { children, _id, ...rest } = value

        const transformedValue: SaveAttributeValueRequestInterface = {
          ...rest,
          id: _id,
          children: [],
        }

        if (children && children.length) {
          transformedValue.children = children.map((child) =>
            transformToUpdate(child),
          )
        }

        return transformedValue
      })
    }

    return transformedData
  }

  return {
    deleteRecursiveArray,
    transformToForm,
    findInArray,
    transformToUpdate,
    assignValue,
  } as const
}
