import igritAPI from "@/utils/api/igrit"
import {
  GetPackageCategoriesResponseInterface,
  GetPackageCategoryResponseInterface,
  GetPackageResponseInterface,
  GetPackagesResponseInterface,
  SavePackageRequestInterface,
} from "@/features/packages/redux/types"
import { setPaginatedCache, setSingleObjectCache } from "@/utils/api/rtkHelper"

export const {
  useGetPackagesQuery,
  useGetPackageQuery,
  useStorePackageMutation,
  useUpdatePackageMutation,
  useDeletePackageMutation,
  useGetPackageCategoriesQuery,
  useGetPackageCategoryQuery,
  useStorePackageCategoryMutation,
  useUpdatePackageCategoryMutation,
  useDeletePackageCategoryMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getPackages: build.query<GetPackagesResponseInterface["data"], string>({
      query: (query) => `/admin/packages?${query}`,
      transformResponse: (response: GetPackagesResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache("Package", result),
    }),
    getPackage: build.query<
      GetPackageResponseInterface["data"],
      string | number
    >({
      query: (id) => `/admin/packages/${id}`,
      transformResponse: (response: GetPackageResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache("Package", result),
    }),
    storePackage: build.mutation<void, FormData>({
      query: (data) => ({
        url: `/admin/packages`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Package"],
    }),
    updatePackage: build.mutation<
      void,
      { id: number | string; data: FormData }
    >({
      query: ({ data, id }) => ({
        url: `/admin/packages/${id}`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Package"],
    }),
    deletePackage: build.mutation<void, string | number>({
      query: (id) => ({
        url: `/admin/packages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Package"],
    }),
    getPackageCategories: build.query<
      GetPackageCategoriesResponseInterface["data"],
      { packageId: number | string; query: string }
    >({
      query: ({ packageId, query }) =>
        `/admin/packages/${packageId}/categories?${query}`,
      transformResponse: (response: GetPackageCategoriesResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache("PackageCategory", result),
    }),
    getPackageCategory: build.query<
      GetPackageCategoryResponseInterface["data"],
      { packageId: number | string; id: string | number }
    >({
      query: ({ packageId, id }) =>
        `/admin/packages/${packageId}/categories/${id}`,
      transformResponse: (response: GetPackageCategoryResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache("PackageCategory", result),
    }),
    storePackageCategory: build.mutation<
      void,
      { packageId: number | string; data: SavePackageRequestInterface }
    >({
      query: ({ data, packageId }) => ({
        url: `/admin/packages/${packageId}/categories`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PackageCategory"],
    }),
    updatePackageCategory: build.mutation<
      void,
      {
        packageId: number | string
        id: number | string
        data: SavePackageRequestInterface
      }
    >({
      query: ({ data, id, packageId }) => ({
        url: `/admin/packages/${packageId}/categories/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["PackageCategory"],
    }),
    deletePackageCategory: build.mutation<
      void,
      { packageId: number | string; id: number | string }
    >({
      query: ({ id, packageId }) => ({
        url: `/admin/packages/${packageId}/categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PackageCategory"],
    }),
  }),
})
