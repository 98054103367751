import { Select2OptionInterface } from "@/app/types"
import { MultiValue, SingleValue } from "react-select"
import { Query } from "@/utils/query"
import React from "react"
import _ from "lodash"
import AsyncSelect from "@/features/components/inputs/asyncSelect/asyncSelect"
import { useLazySearchUsersQuery } from "@/features/users/redux/userAPI"

type Props<TMultiple = boolean> = TMultiple extends true
  ? {
      defaultValue?: Select2OptionInterface[] | null
      onChange: (value: MultiValue<Select2OptionInterface>) => void
      isMulti: TMultiple
    }
  : {
      defaultValue?: Select2OptionInterface | null
      onChange: (value: SingleValue<Select2OptionInterface>) => void
      isMulti: TMultiple
    }

type SelectProps = Props & {
  query?: Query
}

export const SearchUser: React.FC<SelectProps> = ({
  defaultValue,
  onChange,
  isMulti,
  query: _query,
}): React.ReactNode => {
  const [searchUsers] = useLazySearchUsersQuery()

  const _handleSearchUser = (
    value: string,
    callback: (options: Select2OptionInterface[]) => void,
  ) => {
    let query = new Query()

    if (!_query) {
      query.whereSearch(["name", "email"], value)
    } else {
      query = _query
    }

    searchUsers(query.url())
      .unwrap()
      .then((response) =>
        callback(
          response.map((user) => ({
            label: user.name,
            value: user.id.toString(),
          })),
        ),
      )
  }

  const handleSearchUser = _.debounce(_handleSearchUser, 500)

  return (
    <AsyncSelect
      isMulti={isMulti}
      cacheOptions
      // @ts-ignore
      onChange={onChange}
      value={defaultValue}
      loadOptions={handleSearchUser}
    />
  )
}
