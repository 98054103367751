import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Table } from "./_components/table"
import { Button } from "@/features/components/buttons/button"
import { useStorePostServiceMutation } from "@/features/posts/posts/redux/postAPI"
import { postServiceForm } from "@/features/posts/posts/resources/views/edit/_components/services/_components/form"
import { toast } from "react-toastify"

type Props = {
  id: number | string
}

export const Services: React.FC<Props> = ({ id }): React.ReactNode => {
  const { t } = useTranslation(["posts/posts"])
  const [storePostService] = useStorePostServiceMutation()

  const handleAdd = () => {
    postServiceForm().then((response) =>
      storePostService({
        data: response,
        postId: id,
      })
        .unwrap()
        .then(() => toast.success(t("posts/posts:edit.services.add_success"))),
    )
  }

  return (
    <div className={"flex flex-col"}>
      <Card noPadding>
        <div className={"flex flex-col p-4 gap-y-4"}>
          <div className={"flex justify-between items-center"}>
            <span className={"font-medium"}>
              {t("posts/posts:edit.services.title")}
            </span>
            <Button variant={"contained"} size={"small"} onClick={handleAdd}>
              {t("posts/posts:edit.services.add")}
            </Button>
          </div>
          <Table postId={id} />
        </div>
      </Card>
    </div>
  )
}
