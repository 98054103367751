import React, { useEffect, useState } from "react"
import { create, InstanceProps } from "react-modal-promise"
import {
  SaveServiceCategoryRequestInterface,
  ServiceCategoryInterface,
} from "@/features/services/redux/types"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveServiceCategoryValidation from "./validations/SaveServiceCategoryValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Modal from "@/features/components/modals/modal"
import ModalTitle from "@/features/components/modals/ModalTitle"
import { Select2OptionInterface } from "@/app/types"
import { FormControl } from "@mui/base"
import { Label } from "@/features/components/inputs/label"
import { SearchCategory } from "@/features/components/inputs/asyncSelect/searchCategory"
import { SingleValue } from "react-select"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Button } from "@/features/components/buttons/button"

type Props = {
  title: string
  data?: ServiceCategoryInterface
} & InstanceProps<SaveServiceCategoryRequestInterface>

const Form: React.FC<Props> = ({
  title,
  onResolve,
  onReject,
  isOpen,
  data,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "validation"])
  const [selectedCategory, setSelectedCategory] =
    useState<Select2OptionInterface>()
  const { schema, defaultValues } = useValidation(
    new SaveServiceCategoryValidation(),
    t,
  )
  const { control, setValue, handleSubmit } =
    useForm<SaveServiceCategoryRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue("price", data.price)
      setValue("points", data.points)
      setValue("discounted_price", data.discounted_price)
      setValue("discounted_points", data.discounted_points)

      if (data.category) {
        setSelectedCategory({
          label: data.category.name,
          value: data.category.id.toString(),
        })
        setValue("category_id", data.category.id)
      }
    }
  }, [data])

  const handleChangeCategory = (value: SingleValue<Select2OptionInterface>) => {
    if (!value) return

    setSelectedCategory(value)
    setValue("category_id", Number(value.value))
  }

  return (
    <Modal open={isOpen} onClose={() => onReject()}>
      <Modal.Content>
        <ModalTitle title={title} onClose={() => onReject()} />
        <form
          onSubmit={handleSubmit(onResolve)}
          className={"flex flex-col gap-y-4 pt-8"}
        >
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} className={"flex flex-col"}>
                <Label label={t("form:labels.category")} />
                <SearchCategory
                  onChange={handleChangeCategory}
                  isMulti={false}
                  defaultValue={selectedCategory}
                />
              </FormControl>
            )}
            name={"category_id"}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} {...field}>
                <Input
                  type={"number"}
                  min={0}
                  step={"0.01"}
                  label={t("form:labels.price")}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={"price"}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} {...field}>
                <Input
                  type={"number"}
                  min={0}
                  step={"0.01"}
                  label={t("form:labels.points")}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={"points"}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} {...field}>
                <Input
                  type={"number"}
                  min={0}
                  step={"0.01"}
                  label={t("form:labels.discounted_price")}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={"discounted_price"}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} {...field}>
                <Input
                  type={"number"}
                  min={0}
                  step={"0.01"}
                  label={t("form:labels.discounted_points")}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={"discounted_points"}
            control={control}
          />
          <div className={"flex gap-x-4 pt-4"}>
            <Button variant={"contained"} type={"submit"} className={"w-full"}>
              {t("form:buttons.save")}
            </Button>
            <Button
              variant={"outlined"}
              onClick={() => onReject()}
              className={"w-full"}
            >
              {t("form:buttons.cancel")}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const serviceCategoryForm = create(Form)
