import { ServiceNameEnum } from "@/features/posts/posts/redux/enums/service"

export enum ServiceConfigKeyEnum {
  OPTIONS = "options",
  REFRESH_TIME = "refresh_time",
}

export const getServiceConfigKeyForServiceName = (
  serviceName: ServiceNameEnum,
) => {
  switch (serviceName) {
    case ServiceNameEnum.MARK:
      return [ServiceConfigKeyEnum.OPTIONS]
    case ServiceNameEnum.POST_REFRESH:
      return [ServiceConfigKeyEnum.REFRESH_TIME]
    default:
      return []
  }
}
