import React, { forwardRef, HTMLProps } from "react"
import clsx from "clsx"

const Card = forwardRef(
  (
    props: HTMLProps<HTMLDivElement> & { noPadding?: boolean },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { className, noPadding = false, ...rest } = props

    const classes = clsx(
      className,
      "py-4.3 px-6 border border-neutral-600 shadow-sm rounded-md bg-white mb-8",
      {
        "!p-0": noPadding,
      },
    )

    return <div ref={ref} className={classes} {...rest} />
  },
)

export default Card
