import { TFunction } from "i18next"
import * as yup from "yup"
import { SavePageRequestInterface } from "@/features/pages/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import _ from "lodash"

export default class SavePageValidation
  implements ValidationInterface<SavePageRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SavePageRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      slug: yup.string().required(t("validation:required")),
      title: yup.string().required(t("validation:required")),
      content: yup.string().required(t("validation:required")),
      is_active: yup.boolean().required(t("validation:required")),
      in_menu: yup.boolean().required(t("validation:required")),
      in_footer: yup.boolean().required(t("validation:required")),
      menu_order: yup.number().required(t("validation:required")),
      footer_order: yup.number().required(t("validation:required")),
      seo_title: yup.string().nullable(),
      seo_description: yup.string().nullable(),
      seo_no_index: yup.boolean().required(),
      seo_no_follow: yup.boolean().required(),
      seo_tags: yup.string().nullable(),
      og_title: yup.string().nullable(),
      og_description: yup.string().nullable(),
      og_image: yup
        .mixed<File>()
        .nullable()
        .test(
          "file-extension",
          t("validation:file_extension", {
            allowedExtensions: "jpg, png, jpeg, svg",
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = ["jpg", "png", "jpeg", "svg"]
            const fileExtension = value.name.split(".").pop()

            return allowedExtensions.includes(fileExtension || "")
          },
        ),
    })
  }

  defaultValues(): SavePageRequestInterface {
    return {
      slug: "",
      title: "",
      content: "",
      is_active: false,
      in_menu: false,
      in_footer: false,
      menu_order: 0,
      footer_order: 0,
      seo_title: null,
      seo_description: null,
      seo_no_index: false,
      seo_no_follow: false,
      seo_tags: null,
      og_title: null,
      og_description: null,
      og_image: null,
    }
  }
}
