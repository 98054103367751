import Parser from "./parser"
import {
  defaultQueryParameters,
  OptionInterface,
  QueryParametersInterface,
} from "./query.types"
import _ from "lodash"
import { SortingState } from "@tanstack/react-table"

export default class Query {
  model: string | null
  base_url: string | null
  queryParameters: QueryParametersInterface
  append: string[]
  include: string[]
  sorts: string[]
  fields: string | Object
  filters: { [key: string]: string | number }
  pageValue: number | null
  limitValue: number | null
  paramsObj: { [key: string]: any } | null
  parser: Parser

  constructor(options: OptionInterface = {}) {
    this.model = null

    // will use base_url if passed in
    this.base_url = options.base_url || null

    // default filter names
    this.queryParameters = options.queryParameters || defaultQueryParameters

    // initialise variables to hold
    // the urls data
    this.include = []
    this.append = []
    this.sorts = []
    this.fields = {}
    this.filters = {}
    this.pageValue = null
    this.limitValue = null
    this.paramsObj = null

    this.parser = new Parser(this)
  }

  // set the model for the query
  for(model: string): this {
    this.model = model

    return this
  }

  // return the parsed url
  get(): string {
    // generate the url
    const url = this.base_url
      ? this.base_url + this.parseQuery()
      : this.parseQuery()
    // reset the url so the query object can be re-used
    this.reset()
    return url
  }

  url(): string {
    return this.get()
  }

  reset(): void {
    // reset the uri
    this.parser.uri = ""
  }

  parseQuery(): string {
    return this.parser.parse()
  }

  /**
   * Query builder
   */
  includes(...include: string[]) {
    if (!include.length) {
      throw new Error(
        `The ${this.queryParameters.includes}s() function takes at least one argument.`,
      )
    }

    this.include = include

    return this
  }

  appends(...append: string[]) {
    if (!append.length) {
      throw new Error(
        `The ${this.queryParameters.appends}s() function takes at least one argument.`,
      )
    }

    this.append = append

    return this
  }

  where(key: string, value: string | number | undefined | null): this {
    if (value) {
      this.filters[key] = value
    } else {
      delete this.filters[key]
    }

    return this
  }

  whereNull(key: string): this {
    this.filters["isNull"] = key

    return this
  }

  whereNotNull(key: string): this {
    this.filters["isNotNull"] = key

    return this
  }

  whereIn(key: string, array: Array<string | number>): this {
    this.filters[key] = array.join(",")

    return this
  }

  whereNotIn(array: Array<string | number>): this {
    this.filters["exclude_ids"] = array.join(",")

    return this
  }

  sort(...args: string[]) {
    this.sorts = args

    return this
  }

  sortBySortState(sort: SortingState) {
    this.sorts = _.map(sort, (sort) => {
      return sort.desc ? `-${sort.id}` : sort.id
    })

    return this
  }

  page(value: number) {
    this.pageValue = value

    return this
  }

  limit(value: number) {
    this.limitValue = value

    return this
  }

  params(params: Object) {
    this.paramsObj = params

    return this
  }

  whereSearch(columns: string[], value: string) {
    this.filters["search"] = columns.join(",") + ":" + value

    return this
  }

  removeParam(key: string) {
    if (this.paramsObj && this.paramsObj[key]) {
      delete this.paramsObj[key]
    }

    if (_.isEmpty(this.paramsObj)) {
      this.paramsObj = null
    }

    return this
  }
}
