import React from "react"
import {
  Input as BaseInput,
  InputProps,
  useFormControlContext,
} from "@mui/base"
import { Label } from "./label"
import clsx from "clsx"

type Props = InputProps & {
  error?: boolean
  label?: string
  fullWidth?: boolean
  size?: "xl"
  step?: number | string
  min?: number | string
  max?: number | string
  containerClassName?: string
}

export const Input = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      error,
      fullWidth,
      label,
      size,
      min,
      max,
      step,
      containerClassName,
      ...rest
    } = props
    const formControlContext = useFormControlContext()

    const rootClassNames = clsx(
      "bg-white flex items-center border border-neutral-700 rounded-md focus:ring-1 focus:ring-primary focus:border-transparent px-4 py-2",
      {
        "w-full": fullWidth,
        "border-red-500": formControlContext?.error || error,
        "h-[60px]": size === "xl",
      },
    )
    const inputClassName = clsx(
      "flex-grow lg:max-w-full pr-1 bg-white outline-none placeholder-gray-950",
    )

    return (
      <div className={clsx("w-full", containerClassName)}>
        {props.label && <Label label={props.label} />}
        <BaseInput
          ref={ref}
          {...rest}
          slotProps={{
            root: {
              className: rootClassNames,
            },
            input: {
              className: inputClassName,
              step,
              min,
              max,
            },
          }}
        />
      </div>
    )
  },
)
