import React, { useEffect } from "react"
import { useGetMeQuery } from "@/features/auth/redux/authAPI"
import { Sidebar } from "./_components/sidebar"
import { useAppDispatch } from "@/app/hooks"
import { setPermissions } from "@/features/auth/redux/authSlice"

export const AppLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data } = useGetMeQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data) {
      dispatch(setPermissions(data.permissions))
    }
  }, [data, dispatch])

  return (
    <div
      className={
        "flex h-full w-full flex-col overflow-y-auto bg-neutral-100 lg:flex-row"
      }
    >
      <Sidebar />
      <div
        className={
          "bg-neutral-100 h-full lg:ml-[80px] lg:w-[calc(100%_-_80px)]"
        }
      >
        {children}
      </div>
    </div>
  )
}
