import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveInvoiceItemRequestInterface } from "@/features/invoices/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"

export default class SaveInvoiceItemValidation
  implements ValidationInterface<SaveInvoiceItemRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveInvoiceItemRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validation:required")),
      quantity: yup
        .number()
        .typeError(t("validation:required"))
        .required(t("validation:required"))
        .min(1, t("validation:min", { min: 1 })),
      net_price: yup
        .number()
        .typeError(t("validation:required"))
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
      net_value: yup
        .number()
        .typeError(t("validation:required"))
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
      tax_rate: yup
        .number()
        .typeError(t("validation:required"))
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
      tax_value: yup
        .number()
        .typeError(t("validation:required"))
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
      gross_price: yup
        .number()
        .typeError(t("validation:required"))
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
      gross_value: yup
        .number()
        .typeError(t("validation:required"))
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
    })
  }

  defaultValues(): SaveInvoiceItemRequestInterface {
    return {
      name: "",
      quantity: "",
      net_price: "",
      net_value: "",
      tax_rate: "",
      tax_value: "",
      gross_price: "",
      gross_value: "",
    }
  }
}
