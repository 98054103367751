import { FieldArrayWithId } from "react-hook-form"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import { SaveAttributeInterface } from "@/features/posts/attributeCategories/redux/types"
import { instanceOfAttribute } from "@/features/posts/attributeCategories/redux/helper"

type Props = {
  fields: FieldArrayWithId<
    | {}
    | ((formValues: SaveAttributeInterface) => SaveAttributeInterface)
    | SaveAttributeInterface,
    "values"
  >[]
  onAddClick: (parentId: string) => void
  onDeleteClick: (uuid: string) => void
}

export const Tree: React.FC<Props> = ({
  fields,
  onAddClick,
  onDeleteClick,
}): React.ReactNode => {
  const renderTree = (children: SaveAttributeInterface[]) => {
    return children.map((child, index) => (
      <ul className={"ml-2"} key={index}>
        {instanceOfAttribute(child) &&
          child.values.map((value) => (
            <li key={value.uuid}>
              <div className={"flex justify-between"}>
                <span>{value.value}</span>
                <div className={"flex gap-x-2 items-center"}>
                  <button
                    type={"button"}
                    className={
                      "w-4 h-4 rounded flex items-center justify-center bg-red-600 cursor-pointer"
                    }
                    onClick={() => onDeleteClick(value.uuid)}
                  >
                    <FontAwesomeIcon
                      size={"sm"}
                      icon={faTimes}
                      className={"text-white"}
                    />
                  </button>
                  <button
                    type={"button"}
                    onClick={() => onAddClick(value.uuid)}
                    className={
                      "w-4 h-4 rounded flex items-center justify-center bg-green-600 cursor-pointer"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size={"sm"}
                      className={"text-white"}
                    />
                  </button>
                </div>
              </div>
              {value.children && renderTree(value.children)}
            </li>
          ))}
      </ul>
    ))
  }

  return (
    <div className={"flex flex-col max-h-[600px] overflow-auto"}>
      {fields.map((field) => (
        <ul key={field.id}>
          <li>
            <div className={"flex justify-between"}>
              <span>{field.value}</span>
              <div className={"flex gap-x-2 items-center"}>
                <button
                  type={"button"}
                  onClick={() => onDeleteClick(field.uuid)}
                  className={
                    "w-4 h-4 rounded flex items-center justify-center bg-red-600 cursor-pointer"
                  }
                >
                  <FontAwesomeIcon
                    size={"sm"}
                    icon={faTimes}
                    className={"text-white"}
                  />
                </button>
                <button
                  onClick={() => onAddClick(field.uuid)}
                  type={"button"}
                  className={
                    "w-4 h-4 rounded flex items-center justify-center bg-green-600 cursor-pointer"
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    size={"sm"}
                    className={"text-white"}
                  />
                </button>
              </div>
            </div>
            {field.children && renderTree(field.children)}
          </li>
        </ul>
      ))}
    </div>
  )
}
