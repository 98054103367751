import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { Button, ButtonLink } from "@/features/components/buttons/button"
import { usePermissions } from "@/utils/hooks/usePermission"
import { Details } from "@/features/faq/categories/resources/views/details/_components/details"
import { useGetFAQCategoryQuery } from "@/features/faq/categories/redux/faqCategoryAPI"
import { FAQCategoryQuestionsList } from "@/features/faq/questions/resources/views/list"

export const FAQCategoryDetailsScreen = (): React.ReactNode => {
  const { t } = useTranslation("faq/categories")
  const { id } = useParams<{ id: string }>() as { id: string }
  const { hasPermission } = usePermissions()
  const { data } = useGetFAQCategoryQuery(id)

  if (!data) {
    return <div>Loading...</div>
  }

  const handleDelete = (): void => {}

  return (
    <div className={"flex flex-col p-4 gap-y-6"}>
      <div className={"flex justify-between items-center"}>
        <h3 className={"text-2xl font-medium"}>
          {t("faq/categories:details.title")}
        </h3>
        <div className={"flex gap-x-2"}>
          {hasPermission(PermissionEnum.FAQ_SAVE) && (
            <ButtonLink to={`/categories/${id}/edit`} variant={"contained"}>
              {t("form:buttons.edit")}
            </ButtonLink>
          )}
          {hasPermission(PermissionEnum.FAQ_DESTROY) && (
            <Button
              variant={"contained"}
              onClick={handleDelete}
              className={"bg-red-600"}
            >
              {t("form:buttons.delete")}
            </Button>
          )}
        </div>
      </div>
      <div className={"grid lg:grid-cols-2 grid-cols-1 gap-4"}>
        <Details data={data} />
      </div>
      <FAQCategoryQuestionsList categoryName={data.name} categoryId={id} />
    </div>
  )
}
