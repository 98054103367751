import { useAppSelector } from "@/app/hooks"
import { Navigate, Outlet } from "react-router-dom"
import Layout from "@/features/components/layout/auth/layout"
import { getIsAuthenticated } from "@/features/auth/redux/authSlice"

const AuthRoutes = () => {
  const isAuthorized = useAppSelector(getIsAuthenticated)

  return isAuthorized ? (
    <Navigate to={"/"} />
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default AuthRoutes
