import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveServiceConfigRequestInterface } from "@/features/services/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { ServiceNameEnum } from "@/features/posts/posts/redux/enums/service"
import {
  getServiceConfigKeyForServiceName,
  ServiceConfigKeyEnum,
} from "@/app/enums/serviceConfigKeyEnum"

export default class SaveServiceConfigValidation
  implements ValidationInterface<SaveServiceConfigRequestInterface>
{
  constructor(private serviceName: ServiceNameEnum) {}

  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveServiceConfigRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      key: yup
        .mixed<ServiceConfigKeyEnum>()
        .required(t("validation:required"))
        .oneOf(
          getServiceConfigKeyForServiceName(this.serviceName),
          t("validation:required"),
        ),
      value: yup
        .string()
        .nullable()
        .when("key", {
          is: (key: ServiceConfigKeyEnum) =>
            key === ServiceConfigKeyEnum.OPTIONS,
          then: (schema) => schema.required(t("validation:required")),
        }),
    })
  }

  defaultValues(): SaveServiceConfigRequestInterface {
    return {
      key:
        this.serviceName === ServiceNameEnum.MARK
          ? ServiceConfigKeyEnum.OPTIONS
          : ServiceConfigKeyEnum.REFRESH_TIME,
      value: "",
    }
  }
}
