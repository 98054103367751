import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { useNavigate } from "react-router-dom"
import usePagination from "@/utils/hooks/usePagination"
import { Query } from "@/utils/query"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import {
  useDeleteFAQCategoryQuestionMutation,
  useGetFAQCategoryQuestionsQuery,
} from "@/features/faq/questions/redux/faqCategoryQuestionAPI"
import { FAQCategoryQuestionInterface } from "@/features/faq/questions/redux/types"

type Props = {
  categoryId: string
}

export const Table: React.FC<Props> = ({ categoryId }): React.ReactNode => {
  const { t } = useTranslation(["form", "faq/questions", "utils"])
  const navigate = useNavigate()
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () =>
      new Query().page(pagination.pageIndex).where("category_id", categoryId),
    [categoryId, pagination.pageIndex],
  )
  const { data: apiData } = useGetFAQCategoryQuestionsQuery(query.url())
  const [data, setData] = useState<FAQCategoryQuestionInterface[]>([])
  const [deleteFAQCategoryQuestion] = useDeleteFAQCategoryQuestionMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteFAQCategoryQuestion({ id, categoryId })
      .unwrap()
      .then(() => toast.success(t("faq/questions:list.deleted")))
  }

  const table = useReactTable({
    columns: columns(
      t,
      onDelete,
      (id) => navigate(`/faq/categories/${categoryId}/questions/${id}/edit`),
      (id) => navigate(`/faq/categories/${categoryId}/questions/${id}`),
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<FAQCategoryQuestionInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onDetails: (id: number) => void,
) => [
  columnBuilder.accessor("question", {
    id: "name",
    header: t("form:labels.question"),
    // @ts-ignore
    size: "100%",
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.FAQ_DESTROY}
        editPermission={PermissionEnum.FAQ_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
        onDetailsClick={() => onDetails(row.original.id)}
      />
    ),
  }),
]
