import knwAPI from "@/utils/api/igrit"
import { setArrayCache, setSingleObjectCache } from "@/utils/api/rtkHelper"
import {
  GetRoleResponseInterface,
  GetRolesResponseInterface,
  RoleInterface,
  SaveRoleRequestInterface,
} from "@/features/roles/redux/types"

export const {
  useGetRolesQuery,
  useStoreRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<RoleInterface[], void>({
      query: () => "/admin/roles",
      transformResponse: (response: GetRolesResponseInterface) => response.data,
      providesTags: (result) => setArrayCache("Role", result),
    }),
    getRole: builder.query<RoleInterface, number | string>({
      query: (id) => `/admin/roles/${id}`,
      transformResponse: (response: GetRoleResponseInterface) => response.data,
      providesTags: (result) => setSingleObjectCache("Role", result),
    }),
    storeRole: builder.mutation<void, SaveRoleRequestInterface>({
      query: (body) => ({
        url: "/admin/roles",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Role", id: "LIST" }],
    }),
    updateRole: builder.mutation<
      void,
      { id: number | string; body: SaveRoleRequestInterface }
    >({
      query: ({ id, body }) => ({
        url: `/admin/roles/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: "Role", id: "LIST" },
        { type: "Role", id },
      ],
    }),
    deleteRole: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/admin/roles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) => [
        { type: "Role", id: "LIST" },
        { type: "Role", id },
      ],
    }),
  }),
})
