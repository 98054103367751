import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit"
import authSlice from "@/features/auth/redux/authSlice"
import igritAPI from "@/utils/api/igrit"
import storage from "redux-persist/lib/storage"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist"
import elasticSearchAPI from "@/utils/api/elasticSearch"
import nominatimAPI from "@/utils/api/nominatim"
import { UnauthorizedMiddleware } from "./middlewares"

export const persistConfig = {
  key: "root:igrit-admin-panel",
  storage,
  version: 1,
  blacklist: [
    igritAPI.reducerPath,
    elasticSearchAPI.reducerPath,
    nominatimAPI.reducerPath,
  ],
}
const rootReducer = combineReducers({
  auth: authSlice,
  [igritAPI.reducerPath]: igritAPI.reducer,
  [elasticSearchAPI.reducerPath]: elasticSearchAPI.reducer,
  [nominatimAPI.reducerPath]: nominatimAPI.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(igritAPI.middleware)
      .concat(elasticSearchAPI.middleware)
      .concat(nominatimAPI.middleware)
      .concat(UnauthorizedMiddleware),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.store = store
}
