import { SaveFAQCategoryRequestInterface } from "@/features/faq/categories/redux/types"

export const buildForm = (data: SaveFAQCategoryRequestInterface): FormData => {
  const formData = new FormData()

  formData.append("name", data.name)

  if (data.icon) {
    formData.append("icon", data.icon, data.icon.name)
  }

  return formData
}
