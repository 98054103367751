import React from "react"
import { match } from "ts-pattern"
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome"
import {
  faCheckSquare,
  faMinusSquare,
  faSquare,
} from "@fortawesome/free-solid-svg-icons"

type Props = {
  variant: "all" | "none" | "some"
} & Omit<FontAwesomeIconProps, "icon">

const CheckboxIcon: React.FC<Props> = ({
  variant,
  ...rest
}): React.ReactNode => {
  return match(variant)
    .with("all", () => <FontAwesomeIcon icon={faCheckSquare} {...rest} />)
    .with("none", () => <FontAwesomeIcon icon={faSquare} {...rest} />)
    .with("some", () => <FontAwesomeIcon icon={faMinusSquare} {...rest} />)
    .otherwise(() => null)
}

export { CheckboxIcon }
