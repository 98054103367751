export enum ServiceNameEnum {
  POSTS_LIMIT_INCREASE = "posts_limit_increase",
  POINTS = "points",
  NO_ADS = "no_ads",
  NO_DELAY = "no_delay",
  POST_MAIN_PAGE_PROMOTION = "post_main_page_promotion",
  POST_CATEGORY_PROMOTION = "post_category_promotion",
  POST_REFRESH = "post_refresh",
  LOGO = "logo",
  MARK = "mark",
  DAY_OFFER = "day_offer",
}

export const postServiceNameEnum = [
  ServiceNameEnum.POST_REFRESH,
  ServiceNameEnum.POST_MAIN_PAGE_PROMOTION,
  ServiceNameEnum.POST_CATEGORY_PROMOTION,
  ServiceNameEnum.MARK,
  ServiceNameEnum.DAY_OFFER,
]
