import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveFAQCategoryRequestInterface } from "@/features/faq/categories/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import _ from "lodash"

export default class SaveFAQCategoryValidation
  implements ValidationInterface<SaveFAQCategoryRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveFAQCategoryRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validation:required")),
      icon: yup
        .mixed<File>()
        .nullable()
        .test(
          "file-extension",
          t("validation:file_extension", {
            allowedExtensions: "jpg, png, jpeg, svg",
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = ["jpg", "png", "jpeg", "svg"]
            const fileExtension = value.name.split(".").pop()

            return allowedExtensions.includes(fileExtension || "")
          },
        ),
    })
  }

  defaultValues(): SaveFAQCategoryRequestInterface {
    return {
      name: "",
    }
  }
}
