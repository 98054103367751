import React from "react"
import { useTranslation } from "react-i18next"
import { SaveArticleRequestInterface } from "@/features/articles/redux/types"
import Form from "@/features/articles/resources/components/Form"

export const ArticleCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(["articles"])

  const handleSubmit = (data: SaveArticleRequestInterface) => {}

  return (
    <div className={"flex flex-col p-4 gap-y-8"}>
      <span className={"text-2xl font-medium"}>
        {t("services:create.title")}
      </span>
      <Form onSubmit={handleSubmit} />
    </div>
  )
}
