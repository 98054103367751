import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveRoleRequestInterface } from "@/features/roles/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { PermissionEnum } from "@/features/permissions/redux/types"

export default class SaveRoleValidation
  implements ValidationInterface<SaveRoleRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveRoleRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validation:required")),
      permissions: yup
        .array()
        .of(yup.mixed<PermissionEnum>().required())
        .required(t("validation.required")),
    })
  }

  defaultValues(): SaveRoleRequestInterface {
    return {
      name: "",
      permissions: [],
    }
  }
}
