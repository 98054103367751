import React from "react"
import { useTranslation } from "react-i18next"
import { PostUserInterface } from "@/features/posts/posts/redux/types"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import BaseTable from "@/features/components/table"
import Card from "@/features/components/cards"

type Addresses = PostUserInterface["addresses"]
type Address = Addresses[0]

type Props = {
  addresses: Addresses
}

export const AddressList: React.FC<Props> = ({
  addresses: data,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils"])

  const table = useReactTable({
    columns: columns(t),
    getCoreRowModel: getCoreRowModel(),
    data,
  })

  return (
    <Card className={"flex flex-col gap-y-4"}>
      <span className={"font-medium"}>
        {t("posts/posts:edit.users.addresses.title")}
      </span>
      <BaseTable table={table} />
    </Card>
  )
}

const columnBuilder = createColumnHelper<Address>()

const buildAddress = (address: Address) =>
  `${address.street} ${address.city} ${address.postal_code}`

const columns = (t: Function) => [
  columnBuilder.accessor("name", {
    id: "name",
    header: t("form:labels.name"),
  }),
  columnBuilder.display({
    id: "address",
    header: t("form:labels.address"),
    cell: ({ row }) => buildAddress(row.original),
  }),
  columnBuilder.accessor("is_default", {
    id: "is_default",
    header: t("form:labels.name"),
    cell: ({ row }) =>
      row.original.is_default ? t("form:labels.yes") : t("form:labels.no"),
  }),
]
