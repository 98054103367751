import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  useGetIssueQuery,
  useUpdateIssueMutation,
} from "@/features/issues/redux/issueAPI"
import useValidation from "@/utils/hooks/useValidation"
import UpdateIssueStatusValidation from "./validations/updateIssueStatusValidation"
import { Controller, useForm } from "react-hook-form"
import { UpdateIssueRequestInterface } from "@/features/issues/redux/types"
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from "react-toastify"
import { create, InstanceProps } from "react-modal-promise"
import Modal from "@/features/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/features/components/inputs/select"
import { IssueStatusEnum } from "@/features/issues/redux/enums/status"
import { Button } from "@/features/components/buttons/button"

type Props = {
  issueId: number | string
} & InstanceProps<void>

const IssueEdit: React.FC<Props> = ({
  issueId,
  isOpen,
  onResolve,
  onReject,
}): React.ReactNode => {
  const { t } = useTranslation(["issues", "form", "validation", "utils"])
  const { data } = useGetIssueQuery(issueId)
  const [updateIssue] = useUpdateIssueMutation()
  const { schema, defaultValues } = useValidation(
    new UpdateIssueStatusValidation(),
    t,
  )
  const { handleSubmit, control, setValue } =
    useForm<UpdateIssueRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue("status", data.status)
    }
  }, [data])

  const onSubmit = (data: UpdateIssueRequestInterface) => {
    void updateIssue({
      id: issueId,
      data,
    })
      .unwrap()
      .then(() => {
        toast.success(t("issues:edit.success"))
        onResolve()
      })
  }

  return (
    <Modal open={isOpen} onClose={() => onReject()}>
      <Modal.Content size={"lg:w-[600px]"}>
        <div className={"flex w-full flex-row items-center justify-between"}>
          <span className={"text-lg font-semibold"}>
            {t("issues:edit.title")}
          </span>
          <FontAwesomeIcon
            onClick={() => onReject()}
            icon={faTimes}
            className={"cursor-pointer"}
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"flex flex-col gap-y-6 pt-6"}
        >
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <Select
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  label={t("form:labels.status")}
                >
                  {Object.values(IssueStatusEnum).map((status, index) => (
                    <Option value={status} key={index}>
                      {t(`issues:statuses.${status}`)}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            name={"status"}
            control={control}
          />
          <div className={"flex gap-x-2"}>
            <Button variant={"contained"} className={"w-full"} type={"submit"}>
              {t("form:buttons.save")}
            </Button>
            <Button
              className={"w-full"}
              variant={"outlined"}
              onClick={() => onReject()}
            >
              {t("form:buttons.cancel")}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const issueEditModal = create(IssueEdit)
