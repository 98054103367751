import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import BaseTable from "@/features/components/table"
import { useNavigate } from "react-router-dom"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { SortingState } from "@/utils/query"
import usePagination from "@/utils/hooks/usePagination"
import { InvoiceInterface } from "@/features/invoices/redux/types"
import {
  useDeleteInvoiceMutation,
  useGetInvoicesQuery,
} from "@/features/invoices/redux/invoiceAPI"
import Carbon from "@/utils/carbon"
import { SearchFilterInterface } from "../filters/types"
import { buildQuery } from "@/features/invoices/resources/views/list/_components/filters/helper"
import { invoiceEditFormModal } from "@/features/invoices/resources/views/edit"
import { toast } from "react-toastify"

type Props = {
  filters: SearchFilterInterface | undefined
}

export const Table: React.FC<Props> = ({ filters }): React.ReactNode => {
  const { t } = useTranslation(["form", "utils"])
  const navigate = useNavigate()
  const [{ pageIndex, pageSize }, setPagination] = usePagination()
  const [data, setData] = useState<InvoiceInterface[]>([])
  const [deleteInvoice] = useDeleteInvoiceMutation()
  const [sorting, setSorting] = useState<SortingState>([])
  const query = useMemo(
    () =>
      buildQuery(filters)
        .limit(pageSize)
        .page(pageIndex)
        .sortBySortState(sorting),
    [filters, pageIndex, pageSize, sorting],
  )
  const { data: apiData } = useGetInvoicesQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = (id: string) => {
    deleteInvoice(id)
      .unwrap()
      .then(() => toast.success(t("invoices:list.deleted")))
  }

  const handleEdit = (id: string) => {
    void invoiceEditFormModal({
      id,
    })
  }

  const table = useReactTable({
    columns: columns(
      t,
      (id) => navigate(`/invoices/${id}`),
      (id) => handleEdit(id),
      (id) => handleDelete(id),
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    manualSorting: true,
    enableSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<InvoiceInterface>()

const columns = (
  t: Function,
  onDetails: (id: string) => void,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void,
) => [
  columnBuilder.accessor("id", {
    id: "id",
    header: t("form:labels.id"),
    enableSorting: true,
  }),
  columnBuilder.accessor("signature", {
    id: "signature",
    header: t("form:labels.signature"),
    enableSorting: true,
  }),
  columnBuilder.accessor("issue_date", {
    id: "issue_date",
    header: t("form:labels.issue_date"),
    enableSorting: true,
    cell: ({ row }) => (
      <span>{new Carbon(row.original.issue_date).format("dd.MM.yyyy")}</span>
    ),
  }),
  columnBuilder.accessor("type", {
    id: "type",
    header: t("form:labels.type"),
    enableSorting: true,
    cell: ({ row }) => (
      <span>{t(`utils:invoice_types.${row.original.type}`)}</span>
    ),
  }),
  columnBuilder.accessor("gross_value", {
    id: "gross_value",
    header: t("form:labels.gross_value"),
    enableSorting: true,
    cell: ({ row }) => (
      <span>
        {Intl.NumberFormat("pl", {
          style: "currency",
          currency: "PLN",
        }).format(row.original.gross_value)}
      </span>
    ),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.INVOICE_DESTROY}
        editPermission={PermissionEnum.INVOICE_SAVE}
        onEditClick={() => onEdit(row.original.id)}
        onDetailsClick={() => onDetails(row.original.id)}
        onDeleteClick={() => onDelete(row.original.id)}
      />
    ),
  }),
]
