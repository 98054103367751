import React, { HTMLAttributes, LegacyRef, ReactElement } from "react"
import { Modal as MUIModal } from "@mui/base"
import clsx from "clsx"

type ModalProps = {
  open: boolean
  onClose: () => void
  children: ReactElement
}

const Modal = (props: ModalProps) => {
  const { open, onClose, children } = props

  return (
    <MUIModal
      open={open}
      onClose={onClose}
      disableAutoFocus
      slots={{
        backdrop: Backdrop,
      }}
      className={"fixed inset-0 z-[9999] flex items-center justify-center"}
    >
      {children}
    </MUIModal>
  )
}

const ModalContent = React.forwardRef(
  (
    props: HTMLAttributes<HTMLDivElement> & { size?: string },
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    const { className, children, size, ...rest } = props
    const classes = clsx(
      "bg-white border border-neutral-600 rounded shadow p-4",
      className,
    )

    return (
      <div
        className={`min-w-[350px] transition duration-300 ease-out text-gray-700 lg:min-w-[500px] ${
          size ? size : "max-w-2xl"
        }`}
        ref={ref}
        tabIndex={-1}
      >
        <div className={classes} {...rest}>
          {children}
        </div>
      </div>
    )
  },
)

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string; onClick: () => void }
>((props, ref) => {
  const { open, className, onClick } = props
  const classes = clsx(
    "backdrop-blur-sm backdrop-brightness-75 w-screen h-screen fixed inset-0 z-[-1]",
    className,
    {
      "MuiBackdrop-open": !open,
    },
  )

  return <div ref={ref} className={classes} onClick={onClick} />
})

Modal.Content = ModalContent

export default Modal
