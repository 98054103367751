import React from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import {
  InvoiceItemInterface,
  SaveInvoiceItemRequestInterface,
} from "@/features/invoices/redux/types"
import { invoiceItemFormModal } from "@/features/invoices/items/resources/_components/form"
import {
  useDeleteInvoiceItemMutation,
  useUpdateInvoiceItemMutation,
} from "@/features/invoices/redux/invoiceAPI"
import { toast } from "react-toastify"

type Props = {
  data: InvoiceItemInterface[]
  invoiceId: string
}

export const Table: React.FC<Props> = ({
  data,
  invoiceId,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "invoices"])
  const [updateInvoiceItem] = useUpdateInvoiceItemMutation()
  const [deleteInvoiceItem] = useDeleteInvoiceItemMutation()

  const handleDelete = (id: number) => {
    deleteInvoiceItem({
      id,
      invoiceId,
    })
      .unwrap()
      .then(() => toast.success(t("invoices:items.list.deleted")))
  }

  const handleEdit = (id: number) => {
    const invoiceItem = data.find((item) => item.id === id)

    if (!invoiceItem) return

    invoiceItemFormModal({
      data: invoiceItem,
      title: t("invoices:items.edit.title"),
    }).then((response: SaveInvoiceItemRequestInterface) => {
      updateInvoiceItem({
        id: invoiceItem.id,
        body: response,
        invoiceId,
      })
        .unwrap()
        .then(() => toast.success(t("invoices:items.edit.success")))
    })
  }

  const table = useReactTable({
    columns: columns(
      t,
      (id) => handleEdit(id),
      (id) => handleDelete(id),
    ),
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
    data,
  })

  return <BaseTable table={table} />
}

const columnBuilder = createColumnHelper<InvoiceItemInterface>()

const columns = (
  t: Function,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
) => [
  columnBuilder.accessor("name", {
    id: "name",
    header: t("form:labels.name"),
  }),
  columnBuilder.accessor("quantity", {
    id: "quantity",
    header: t("form:labels.quantity"),
  }),
  columnBuilder.accessor("net_price", {
    id: "net_price",
    header: t("form:labels.net_price"),
    cell: ({ row }) => (
      <span>
        {Intl.NumberFormat("pl", {
          style: "currency",
          currency: "PLN",
        }).format(row.original.net_price)}
      </span>
    ),
  }),
  columnBuilder.accessor("net_value", {
    id: "net_value",
    header: t("form:labels.net_value"),
    cell: ({ row }) => (
      <span>
        {Intl.NumberFormat("pl", {
          style: "currency",
          currency: "PLN",
        }).format(row.original.net_value)}
      </span>
    ),
  }),
  columnBuilder.accessor("tax_rate", {
    id: "tax_rate",
    header: t("form:labels.tax_rate"),
  }),
  columnBuilder.accessor("gross_price", {
    id: "gross_price",
    header: t("form:labels.gross_price"),
    cell: ({ row }) => (
      <span>
        {Intl.NumberFormat("pl", {
          style: "currency",
          currency: "PLN",
        }).format(row.original.gross_price)}
      </span>
    ),
  }),
  columnBuilder.accessor("gross_value", {
    id: "gross_value",
    header: t("form:labels.gross_value"),
    cell: ({ row }) => (
      <span>
        {Intl.NumberFormat("pl", {
          style: "currency",
          currency: "PLN",
        }).format(row.original.gross_value)}
      </span>
    ),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.INVOICE_DESTROY}
        editPermission={PermissionEnum.INVOICE_SAVE}
        onEditClick={() => onEdit(row.original.id)}
        onDeleteClick={() => onDelete(row.original.id)}
      />
    ),
  }),
]
