import igritAPI from "@/utils/api/igrit"
import {
  GetFAQCategoryQuestionResponseInterface,
  GetFAQCategoryQuestionsResponseInterface,
  SaveFAQCategoryQuestionRequestInterface,
  SearchFAQCategoryQuestionsResponseInterface,
} from "@/features/faq/questions/redux/types"
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from "@/utils/api/rtkHelper"

export const {
  useGetFAQCategoryQuestionsQuery,
  useDeleteFAQCategoryQuestionMutation,
  useStoreFAQCategoryQuestionMutation,
  useUpdateFAQCategoryQuestionMutation,
  useGetFAQCategoryQuestionQuery,
  useLazySearchFAQCategoryQuestionsQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    searchFAQCategoryQuestions: build.query<
      SearchFAQCategoryQuestionsResponseInterface["data"],
      string
    >({
      query: (query) => `/admin/faq-entries/search?${query}`,
      transformResponse: (
        response: SearchFAQCategoryQuestionsResponseInterface,
      ) => response.data,
      providesTags: (result) => setArrayCache("FAQ_CATEGORY_QUESTION", result),
    }),
    getFAQCategoryQuestions: build.query<
      GetFAQCategoryQuestionsResponseInterface["data"],
      string
    >({
      query: (query) => `/admin/faq-entries?${query}`,
      transformResponse: (response: GetFAQCategoryQuestionsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        setPaginatedCache("FAQ_CATEGORY_QUESTION", result),
    }),
    getFAQCategoryQuestion: build.query<
      GetFAQCategoryQuestionResponseInterface["data"],
      string | number
    >({
      query: (id) => `/admin/faq-entries/${id}`,
      transformResponse: (response: GetFAQCategoryQuestionResponseInterface) =>
        response.data,
      providesTags: (result) =>
        setSingleObjectCache("FAQ_CATEGORY_QUESTION", result),
    }),
    storeFAQCategoryQuestion: build.mutation<
      void,
      SaveFAQCategoryQuestionRequestInterface
    >({
      query: (body) => ({
        url: "/admin/faq-entries",
        body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "FAQ_CATEGORY_QUESTION", id: "LIST" }],
    }),
    updateFAQCategoryQuestion: build.mutation<
      void,
      { id: number | string; body: SaveFAQCategoryQuestionRequestInterface }
    >({
      query: ({ body, id }) => ({
        url: `/admin/faq-entries/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: "FAQ_CATEGORY_QUESTION", id: "LIST" },
        { type: "FAQ_CATEGORY_QUESTION", id },
      ],
    }),
    deleteFAQCategoryQuestion: build.mutation<
      void,
      { categoryId: number | string; id: number | string }
    >({
      query: ({ id }) => ({
        url: `/admin/faq-entries/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, { categoryId }) => [
        { type: "FAQ_CATEGORY_QUESTION", id: "LIST" },
        { type: "FAQ_CATEGORY", id: categoryId },
      ],
    }),
  }),
})
