import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { Query } from "@/utils/query"
import usePagination from "@/utils/hooks/usePagination"
import { toast } from "react-toastify"
import {
  useDeleteAdvertiserMutation,
  useGetAdvertisersQuery,
  useUpdateAdvertiserMutation,
} from "@/features/ads/advertisers/redux/advertiserAPI"
import { AdvertiserInterface } from "@/features/ads/advertisers/redux/types"
import { advertiserFormModal } from "@/features/ads/advertisers/resources/_components/form"

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "advertisers"])
  const [{ pageIndex, pageSize }, setPagination] = usePagination()
  const [data, setData] = useState<AdvertiserInterface[]>([])
  const [deleteAdvertiser] = useDeleteAdvertiserMutation()
  const query = useMemo(
    () => new Query().limit(pageSize).page(pageIndex),
    [pageIndex, pageSize],
  )
  const { data: apiData } = useGetAdvertisersQuery(query.url())
  const [updateAdvertiser] = useUpdateAdvertiserMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = (id: number) => {
    deleteAdvertiser(id)
      .unwrap()
      .then(() => toast.success(t("advertisers:list.deleted")))
  }

  const handleEdit = (id: number) => {
    const advertiser = data.find((advertiser) => advertiser.id === id)

    if (!advertiser) return

    void advertiserFormModal({
      data: advertiser,
      title: t("advertisers:edit.title"),
    }).then((response) => {
      updateAdvertiser({
        id,
        body: response,
      })
        .unwrap()
        .then(() => toast.success(t("advertisers:edit.success")))
    })
  }

  const table = useReactTable({
    columns: columns(
      t,
      (id) => handleEdit(id),
      (id) => handleDelete(id),
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<AdvertiserInterface>()

const columns = (
  t: Function,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
) => [
  columnBuilder.accessor("id", {
    id: "id",
    header: t("form:labels.id"),
  }),
  columnBuilder.accessor("user.name", {
    id: "user.name",
    header: t("form:labels.user"),
  }),
  columnBuilder.accessor("manager.name", {
    id: "manager.name",
    header: t("form:labels.manager"),
  }),
  columnBuilder.accessor("company_name", {
    id: "company_name",
    header: t("form:labels.company_name"),
  }),
  columnBuilder.accessor("nip", {
    id: "nip",
    header: t("form:labels.nip"),
  }),
  columnBuilder.accessor("company_address", {
    id: "company_address",
    header: t("form:labels.company_address"),
  }),
  columnBuilder.accessor("contact_person", {
    id: "contact_person",
    header: t("form:labels.contact_person"),
  }),
  columnBuilder.accessor("contact_phone", {
    id: "contact_phone",
    header: t("form:labels.contact_phone"),
  }),
  columnBuilder.accessor("contact_email", {
    id: "contact_email",
    header: t("form:labels.contact_email"),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.ADVERTISER_DESTROY}
        editPermission={PermissionEnum.ADVERTISER_SAVE}
        onEditClick={() => onEdit(row.original.id)}
        onDeleteClick={() => onDelete(row.original.id)}
      />
    ),
  }),
]
