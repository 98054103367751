import { FormInterface } from "@/app/types"
import {
  AttributeInterface,
  SaveAttributePropsInterface,
} from "@/features/posts/attributes/redux/types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveAttributeValidation from "@/features/posts/attributes/resources/_components/form/validations/saveAttributeValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import Select, { Option } from "@/features/components/inputs/select"
import { AttributeTypeEnum } from "@/features/posts/attributes/redux/enums/attributeType"
import _ from "lodash"
import { UnitEnum } from "@/app/enums/unitEnum"
import { Checkbox } from "@/features/components/inputs/checkbox"
import { Button } from "@/features/components/buttons/button"

export const Form: React.FC<
  FormInterface<SaveAttributePropsInterface, AttributeInterface>
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(["validation", "form", "utils"])
  const { schema, defaultValues } = useValidation(
    new SaveAttributeValidation(),
    t,
  )

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<SaveAttributePropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      setValue("name", data.name)
      setValue("type", data.type)
      setValue("unit", data.unit)
      setValue("is_required", data.is_required)
      setValue("settable_by_user", data.settable_by_user)
    }
  }, [data])

  return (
    <div className={"flex flex-col"}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex flex-col gap-y-6"}
      >
        <div className={"grid grid-cols-4 gap-4 place-content-center"}>
          <Controller
            render={({ field }) => (
              <FormControl {...field} error={!!errors.name}>
                <Input
                  name={field.name}
                  label={t("form:labels.name")}
                  error={!!errors.name}
                />
                <FormHelperText message={errors.name?.message} />
              </FormControl>
            )}
            name={"name"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl error={!!errors.type}>
                <Select
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  label={t("form:labels.attribute_type")}
                  name={field.name}
                >
                  {_.map(AttributeTypeEnum, (value, index) => (
                    <Option value={value} key={index}>
                      {t(`utils:attribute_types.${value}`)}
                    </Option>
                  ))}
                </Select>
                <FormHelperText message={errors.type?.message} />
              </FormControl>
            )}
            name={"type"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl error={!!errors.type}>
                <Select
                  value={field.value}
                  placeholder={t("form:labels.select_unit")}
                  onChange={(_, value) => field.onChange(value)}
                  label={t("form:labels.unit")}
                  name={field.name}
                >
                  {_.map(UnitEnum, (value, index) => (
                    <Option value={value} key={index}>
                      {t(`utils:units.${value}`)}
                    </Option>
                  ))}
                </Select>
                <FormHelperText message={errors.unit?.message} />
              </FormControl>
            )}
            name={"unit"}
            control={control}
          />
        </div>
        <Controller
          render={({ field }) => (
            <FormControl error={!!errors.is_required}>
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                label={t("form:labels.is_required")}
                name={field.name}
              />
            </FormControl>
          )}
          control={control}
          name={"is_required"}
        />
        <Controller
          render={({ field }) => (
            <FormControl error={!!errors.settable_by_user}>
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                label={t("form:labels.settable_by_user")}
                name={field.name}
              />
            </FormControl>
          )}
          control={control}
          name={"settable_by_user"}
        />
        <div className={"flex pt-6"}>
          <Button variant={"contained"} type={"submit"}>
            {t("form:buttons.save")}
          </Button>
        </div>
      </form>
    </div>
  )
}
