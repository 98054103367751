import { UserInterface } from "@/features/users/redux/types"
import {
  PaginatedResponseInterface,
  SuccessResponseInterface,
} from "@/app/types"

export type BlackListRowType = "email" | "phone_number"

export const BlackListRowTypes: BlackListRowType[] = ["email", "phone_number"]

export interface BlackListRowInterface {
  id: number
  value: string
  type: BlackListRowType
  reason: string | null
  expires_at: string | null
  created_by_id: number | null
  created_by?: UserInterface | null
}

export interface GetBlackListResponseInterface
  extends SuccessResponseInterface {
  data: PaginatedResponseInterface<BlackListRowInterface>
}

export interface GetBlackListRowResponseInterface
  extends SuccessResponseInterface {
  data: BlackListRowInterface
}

export interface SaveBlackListRowRequestInterface {
  value: string
  type: BlackListRowType
  reason?: string | null
  expires_at?: string | null
}
