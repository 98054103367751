import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveSurveyAnswerRequestInterface } from "@/features/surveys/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"

export default class SaveSurveyAnswerValidation
  implements ValidationInterface<SaveSurveyAnswerRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveSurveyAnswerRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      answer: yup.string().required(t("validation:required")),
    })
  }

  defaultValues(): SaveSurveyAnswerRequestInterface {
    return {
      answer: "",
    }
  }
}
