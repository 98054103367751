import { useTranslation } from "react-i18next"
import React from "react"
import clsx from "clsx"

export const BoolBadge: React.FC<{ value: boolean }> = ({
  value,
}): React.ReactNode => {
  const { t } = useTranslation(["form"])

  return (
    <span
      className={clsx("py-1 px-2 rounded text-xs uppercase shadow", {
        "bg-green-600 text-white": value,
        "bg-red-600 text-white": !value,
      })}
    >
      {value ? t("form:labels.yes") : t("form:labels.no")}
    </span>
  )
}
