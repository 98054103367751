import elasticSearchAPI from "@/utils/api/elasticSearch"
import {
  GetPostResponseInterface,
  GetPostsElasticSearchInterface,
  GetPostServicesResponseInterface,
  ManagePostsRequestInterface,
  PostInterface,
  SavePostServiceRequestInterface,
  UpdatePostDataRequest,
  UpdatePostUserRequestInterface,
} from "@/features/posts/posts/redux/types"
import igritAPI from "@/utils/api/igrit"
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from "@/utils/api/rtkHelper"
import { AttributeTypeEnum } from "@/features/posts/attributes/redux/enums/attributeType"
import { GetAuditsResponseInterface } from "@/app/types"

export const elasticPostAPI = elasticSearchAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query<GetPostsElasticSearchInterface["hits"], object>({
      query: (query) => ({
        url: `/posts/_search`,
        method: "POST",
        body: query,
      }),
      transformResponse: (response: GetPostsElasticSearchInterface) =>
        response.hits,
      providesTags: [{ type: "ElasticSearchPost", id: "LIST" }],
      keepUnusedDataFor: 60,
    }),
  }),
})

export const { useGetPostsQuery } = elasticPostAPI

export const {
  useManagePostsMutation,
  useGetPostQuery,
  useBlockPostMutation,
  useUpdatePostMutation,
  useUploadPostImageMutation,
  useGetPostServicesQuery,
  useUpdatePostServiceMutation,
  useUpdatePostUserMutation,
  useGetPostAuditsQuery,
  useSendPostPublishedMailMutation,
  useDeletePostImageMutation,
  useStorePostServiceMutation,
  useDeletePostServiceMutation,
  useChangePostMediaOrderMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    managePosts: build.mutation<void, ManagePostsRequestInterface>({
      query: (body) => ({
        url: "/admin/posts/manage",
        method: "POST",
        body,
      }),
      onQueryStarted: async (_, utils) => {
        await utils.queryFulfilled

        utils.dispatch(
          elasticPostAPI.util.invalidateTags(["ElasticSearchPost"]),
        )
      },
    }),
    getPost: build.query<PostInterface, string | number>({
      query: (id) => `/admin/posts/${id}`,
      transformResponse: (response: GetPostResponseInterface) => {
        const post = response.data

        return {
          ...post,
          attributes: post.attributes.map((attribute) => {
            if (attribute.type === AttributeTypeEnum.RANGE) {
              return {
                ...attribute,
                value: JSON.parse(attribute.value as unknown as string),
                original_attribute_value: {
                  ...attribute.original_attribute_value,
                  value: JSON.parse(
                    attribute.original_attribute_value
                      .value as unknown as string,
                  ),
                },
              }
            }

            return attribute
          }),
        }
      },
      providesTags: (result) => setSingleObjectCache("Post", result),
    }),
    updatePost: build.mutation<
      void,
      { id: number | string; body: UpdatePostDataRequest }
    >({
      query: ({ id, body }) => ({
        url: `/admin/posts/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Post", id: id }],
    }),
    blockPost: build.mutation<void, { id: number | string; reason?: string }>({
      query: ({ id, reason }) => ({
        url: `/admin/posts/${id}/toggle-block`,
        method: "POST",
        body: { audit_note: reason },
      }),
      invalidatesTags: (_, error, { id }) => [{ type: "Post", id }],
    }),
    uploadPostImage: build.mutation<
      void,
      { id: number | string; data: FormData }
    >({
      query: ({ id, data }) => ({
        url: `/admin/posts/${id}/media-groups`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: (_, error, { id }) => [{ type: "Post", id }],
    }),
    getPostServices: build.query<
      GetPostServicesResponseInterface["data"],
      string | number
    >({
      query: (id) => ({
        url: `/admin/posts/${id}/services`,
        method: "GET",
      }),
      transformResponse: (response: GetPostServicesResponseInterface) =>
        response.data,
      providesTags: (result) => setArrayCache("PostService", result),
    }),
    updatePostService: build.mutation<
      void,
      {
        id: number | string
        postId: number | string
        body: SavePostServiceRequestInterface
      }
    >({
      query: ({ id, postId, body }) => ({
        url: `/admin/posts/${postId}/services/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: "PostService", id },
        { type: "PostService", id: "LIST" },
      ],
    }),
    storePostService: build.mutation<
      void,
      { postId: number | string; data: SavePostServiceRequestInterface }
    >({
      query: ({ postId, data }) => ({
        url: `/admin/posts/${postId}/services`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "PostService", id: "LIST" }],
    }),
    deletePostService: build.mutation<
      void,
      { postId: number | string; id: number | string }
    >({
      query: ({ postId, id }) => ({
        url: `/admin/posts/${postId}/services/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "PostService", id: "LIST" }],
    }),
    updatePostUser: build.mutation<
      void,
      {
        body: UpdatePostUserRequestInterface
        id: number | string
        postId: number | string
      }
    >({
      query: ({ body, id, postId }) => ({
        url: `/admin/posts/${postId}/users/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { postId }) => [{ type: "Post", id: postId }],
    }),
    getPostAudits: build.query<
      GetAuditsResponseInterface["data"],
      { id: number | string; query: string }
    >({
      query: ({ id, query }) => `/admin/posts/${id}/audits?${query}`,
      transformResponse: (response: GetAuditsResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache("PostAudit", result),
    }),
    sendPostPublishedMail: build.mutation<void, number | string>({
      query: (id) => ({
        url: `/admin/posts/${id}/send-published-notification`,
        method: "POST",
      }),
    }),
    deletePostImage: build.mutation<
      void,
      { postId: number | string; id: string | number }
    >({
      query: ({ postId, id }) => ({
        url: `/admin/posts/${postId}/media-groups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, { postId }) => [{ type: "Post", id: postId }],
    }),
    changePostMediaOrder: build.mutation<
      void,
      { postId: number | string; mediaId: string; order: number }
    >({
      query: ({ postId, mediaId, order }) => ({
        url: `/admin/posts/${postId}/media-groups/${mediaId}/change-order`,
        method: "POST",
        body: { order },
      }),
      invalidatesTags: (_, error, { postId }) => [{ type: "Post", id: postId }],
    }),
  }),
})
