import React from "react"
import { useTranslation } from "react-i18next"
import { Table } from "./_components/table"
import Card from "@/features/components/cards"
import { Button } from "@/features/components/buttons/button"
import { advertiserFormModal } from "@/features/ads/advertisers/resources/_components/form"
import { useStoreAdvertiserMutation } from "@/features/ads/advertisers/redux/advertiserAPI"
import { toast } from "react-toastify"

export const AdvertiserListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["advertisers"])
  const [storeAdvertiser] = useStoreAdvertiserMutation()

  const handleAdd = () => {
    advertiserFormModal({
      title: t("advertisers:create.title"),
    }).then((response) => {
      storeAdvertiser(response)
        .unwrap()
        .then(() => toast.success(t("advertisers:create.success")))
    })
  }

  return (
    <div className={"flex flex-col p-8 gap-y-4"}>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium justify-between flex items-center"
            }
          >
            <span>{t("advertisers:list.title")}</span>
            <Button onClick={handleAdd} variant={"contained"}>
              {t("advertisers:list.create")}
            </Button>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}
