import { TFunction } from "i18next"
import * as yup from "yup"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { SavePostServiceRequestInterface } from "@/features/posts/posts/redux/types"
import Carbon from "@/utils/carbon"
import { ServiceNameEnum } from "@/features/posts/posts/redux/enums/service"
import { ServiceConfigInterface } from "@/features/services/redux/types"

export default class SavePostServiceValidation
  implements ValidationInterface<SavePostServiceRequestInterface>
{
  private configs: ServiceConfigInterface[] = []

  constructor(configs: ServiceConfigInterface[]) {
    this.configs = configs
  }

  rules(
    t: TFunction,
  ): yup.ObjectSchema<SavePostServiceRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      system_name: yup
        .mixed<ServiceNameEnum>()
        .oneOf(Object.values(ServiceNameEnum))
        .required(t("validation:required")),
      quantity: yup.number().required(t("validation:required")),
      value: yup
        .string()
        .nullable()
        .when("system_name", {
          is: (system_name: ServiceNameEnum) =>
            [ServiceNameEnum.POST_REFRESH, ServiceNameEnum.MARK].includes(
              system_name,
            ),
          then: (yup) => yup.required(t("validation:required")),
        })
        .test(
          "format",
          t("validation:invalid_format", { format: "H:m" }),
          (value, context) => {
            const type = context.parent.system_name as ServiceNameEnum

            if (value && type === ServiceNameEnum.POST_REFRESH) {
              return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
            }

            if (this.configs.length > 0 && value) {
              const configValues = this.configs.map((config) => config.value)

              return configValues.includes(value)
            }

            return true
          },
        ),
      end_at: yup
        .string()
        .nullable()
        .when("system_name", {
          is: (system_name: ServiceNameEnum) =>
            system_name !== ServiceNameEnum.MARK,
          then: (yup) => yup.required(t("validation:required")),
        })
        .test("is-date", function (value) {
          try {
            if (!value) return true

            const date = new Date(value)
            return new Carbon(date).gte(new Date())
              ? true
              : this.createError({
                  message: t("validation:field_must_be_greater_or_equal_than", {
                    min: new Carbon().toDateTimeLocal(),
                  }),
                  path: this.path,
                })
          } catch (error) {
            return this.createError({
              message: t("validation:invalid_date"),
              path: this.path,
            })
          }
        })
        .transform((value: string) =>
          value ? new Carbon(value).toDateTimeLocal() : null,
        ),
    })
  }

  defaultValues(): SavePostServiceRequestInterface {
    return {
      system_name: ServiceNameEnum.POST_REFRESH,
      quantity: 0,
      value: "",
      end_at: "",
    }
  }
}
