import {
  PostInterface,
  StoreImageRequestInterface,
} from "@/features/posts/posts/redux/types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Form } from "./_components/form"
import { useUploadPostImageMutation } from "@/features/posts/posts/redux/postAPI"
import { toast } from "react-toastify"
import { Gallery } from "./_components/gallery"

type Props = {
  data: PostInterface
}

export const Images: React.FC<Props> = ({ data }): React.ReactNode => {
  const { t } = useTranslation(["posts/posts"])
  const { id } = data
  const [uploadImage] = useUploadPostImageMutation()

  const handleUpload = async (
    data: StoreImageRequestInterface,
  ): Promise<boolean> => {
    if (!data.image) return false

    try {
      const formData = new FormData()
      formData.append("file", data.image, data.image.name)
      formData.append("order", data.order.toString())

      await uploadImage({ id, data: formData }).unwrap()
      toast.success(t("posts/posts:edit.images.create.success"))
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <div className={"grid grid-cols-2 gap-4"}>
      <Form onSubmit={handleUpload} />
      <Gallery data={data} />
    </div>
  )
}
