export enum AdsSlotTypeEnum {
  TOP_BANNER_1 = "top_banner_1",
  TOP_BANNER_2 = "top_banner_2",
  SKYSCRAPER = "skyscraper",
  SKYSCRAPER_2 = "skyscraper_2",
  MINI_BANNER_1 = "mini_banner_1",
  MINI_BANNER_2 = "mini_banner_2",
  MINI_BANNER_3 = "mini_banner_3",
  WIDGET_SKYSCRAPER = "widget_skyscraper",
  BACKGROUND = "background",
  AMONG_POSTS = "among_ads",
  POPUP = "modal",
  EMAIL_NEW_POST = "email_new_post",
  NEW_POST_4 = "new_post_4",
  SURVEY = "probe",
  PARTNER = "partner",
  FORM_NEW_POST = "form_new_post",
  INFO_POST = "info_ad",
  PARALLAX_1 = "parallax_1",
  PARALLAX_2 = "parallax_2",
}
