import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetServiceQuery,
  useUpdateServiceMutation,
} from "@/features/services/redux/serviceAPI"
import { SaveServiceRequestInterface } from "@/features/services/redux/types"
import { Form } from "@/features/services/resources/_components/form"
import { ServiceCategoryList } from "./categories/list"
import { ServiceConfigList } from "@/features/services/resources/views/edit/config/list"
import { ServiceNameEnum } from "@/features/posts/posts/redux/enums/service"

export const ServiceEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(["services"])
  const [updateService] = useUpdateServiceMutation()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data } = useGetServiceQuery(id)

  if (!data) return null

  const handleSubmit = (data: SaveServiceRequestInterface) => {
    updateService({
      id,
      body: data,
    })
      .unwrap()
      .then(() => {
        toast.success(t("services:edit.success"))
        navigate("/services")
      })
  }

  return (
    <div className={"flex flex-col p-4 gap-y-8"}>
      <span className={"text-2xl font-medium"}>{t("services:edit.title")}</span>
      <Card>
        <Form data={data} onSubmit={handleSubmit} />
      </Card>
      <ServiceCategoryList serviceId={id} />
      {[ServiceNameEnum.MARK, ServiceNameEnum.POST_REFRESH].includes(
        data.system_name,
      ) && <ServiceConfigList serviceId={id} serviceName={data.system_name} />}
    </div>
  )
}
