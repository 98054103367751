import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@/features/components/buttons/button"
import Card from "@/features/components/cards"
import useValidation from "@/utils/hooks/useValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { SearchFilterInterface } from "./types"
import { Label } from "@/features/components/inputs/label"
import { SearchCategory } from "@/features/components/inputs/asyncSelect/searchCategory"
import { SearchAttribute } from "@/features/components/inputs/asyncSelect/searchAttribute"
import FilterValidation from "./validations/filterValidation"
import { Select2OptionInterface } from "@/app/types"

type Props = {
  onSubmit: (data: SearchFilterInterface) => void
}

export const AttributeCategoryFilter: React.FC<Props> = ({
  onSubmit,
}): React.ReactNode => {
  const { t } = useTranslation(["form"])
  const { schema, defaultValues } = useValidation(new FilterValidation(), t)
  const { control, getValues, handleSubmit, reset } =
    useForm<SearchFilterInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })
  const [selectedCategory, setSelectedCategory] =
    useState<Select2OptionInterface | null>(null)
  const [selectedAttribute, setSelectedAttribute] =
    useState<Select2OptionInterface | null>(null)

  const resetForm = () => {
    setSelectedCategory(null)
    setSelectedAttribute(null)
    reset(defaultValues)
    onSubmit(getValues())
  }

  return (
    <div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"flex flex-col gap-y-4 w-full flex-grow-0"}
        >
          <div className={"grid grid-cols-4 gap-4 p-1 items-end"}>
            <Controller
              control={control}
              name={"category_id"}
              render={({ field }) => (
                <div className={"flex flex-col"}>
                  <Label label={t("form:labels.category")} />
                  <SearchCategory
                    defaultValue={selectedCategory}
                    isMulti={false}
                    onChange={(value) => {
                      field.onChange(value?.value)
                      setSelectedCategory(value)
                    }}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name={"attribute_id"}
              render={({ field }) => (
                <div className={"flex flex-col"}>
                  <Label label={t("form:labels.attribute")} />
                  <SearchAttribute
                    defaultValue={selectedAttribute}
                    isMulti={false}
                    onChange={(value) => {
                      field.onChange(value?.value)
                      setSelectedAttribute(value)
                    }}
                  />
                </div>
              )}
            />
            <div className={"flex pt-4 gap-x-2 items-center"}>
              <Button size={"small"} type={"submit"} variant={"contained"}>
                {t("form:buttons.search")}
              </Button>
              <Button
                size={"small"}
                className={"bg-gray-500"}
                variant={"contained"}
                type={"button"}
                onClick={resetForm}
              >
                {t("form:buttons.clear")}
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  )
}
