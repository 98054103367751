import igritAPI from "@/utils/api/igrit"
import {
  GetPageResponseInterface,
  GetPagesResponseInterface,
  PageInterface,
} from "@/features/pages/redux/types"
import { setPaginatedCache, setSingleObjectCache } from "@/utils/api/rtkHelper"

export const {
  useGetPagesQuery,
  useGetPageQuery,
  useStorePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getPages: build.query<GetPagesResponseInterface["data"], string>({
      query: (query) => `/admin/pages?${query}`,
      transformResponse: (response: GetPagesResponseInterface) => response.data,
      providesTags: (result) => setPaginatedCache("Page", result),
    }),
    getPage: build.query<PageInterface, string | number>({
      query: (id) => `/admin/pages/${id}`,
      transformResponse: (response: GetPageResponseInterface) => response.data,
      providesTags: (result) => setSingleObjectCache("Page", result),
    }),
    storePage: build.mutation<void, FormData>({
      query: (body) => ({
        url: "/admin/pages",
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Page"],
    }),
    updatePage: build.mutation<void, { id: number | string; body: FormData }>({
      query: ({ body, id }) => ({
        url: `/admin/pages/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Page"],
    }),
    deletePage: build.mutation<void, string | number>({
      query: (id) => ({
        url: `/admin/pages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Page"],
    }),
  }),
})
