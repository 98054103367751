import React, { useState } from "react"
import { ClickAwayListener } from "@mui/base"
import {
  ChildSidebarMenuItemInterface,
  SidebarMenuItemInterface,
} from "@/utils/routes/privateRoutes/types"
import { SidebarToggle } from "@/features/components/layout/app/_components/sidebarToggle"
import { ChildrenSidebar } from "@/features/components/layout/app/_components/childrenSidebar"
import { useNavigate } from "react-router-dom"
import {
  faDollar,
  faList,
  faPenToSquare,
  faRightFromBracket,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch } from "@/app/hooks"
import { logoutRequestMutation } from "@/features/auth/redux/authAPI"
import igritAPI from "@/utils/api/igrit"
import elasticSearchAPI from "@/utils/api/elasticSearch"
import nominatimAPI from "@/utils/api/nominatim"

export const Sidebar = (): React.ReactNode => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [currentChildren, setCurrentChildren] = useState<{
    children: ChildSidebarMenuItemInterface[]
    title: string
  }>({ children: [], title: "" })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleOpenChildren = (index: string) => {
    const item = items.find((item) => item.index === index)

    if (item && item.children.length > 0) {
      setCurrentChildren({
        children: item.children,
        title: item.title,
      })
      setOpenSidebar(true)
    } else {
      setOpenSidebar(false)
    }
  }

  const handleLogout = () => {
    dispatch(logoutRequestMutation())
      .unwrap()
      .finally(() => {
        dispatch(igritAPI.util?.resetApiState())
        dispatch(elasticSearchAPI.util?.resetApiState())
        dispatch(nominatimAPI.util?.resetApiState())
        navigate("/login")
      })
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenSidebar(false)}>
      <div>
        <div
          className={
            "fixed bottom-0 z-[9999] flex w-auto flex-col-reverse bg-white lg:top-0 lg:flex-row"
          }
        >
          <div
            className={
              "flex h-[80px] w-screen items-center border-r border-neutral-600 bg-white px-4 pb-8 pt-4 lg:h-full lg:w-[80px] lg:items-baseline"
            }
          >
            <ul className={"flex items-center gap-4 py-4 lg:flex-col h-full"}>
              {items.map((item) => (
                <SidebarToggle
                  {...item}
                  key={item.index}
                  onClick={handleOpenChildren}
                />
              ))}
              <div className={"flex-1"} />
              <SidebarToggle
                index={"profile"}
                title={"profile"}
                icon={faUser}
                matchUrls={["/profile"]}
                onClick={() => navigate("/profile")}
              />
              <SidebarToggle
                index={"logout"}
                title={"logout"}
                icon={faRightFromBracket}
                matchUrls={["/logout"]}
                onClick={handleLogout}
              />
            </ul>
          </div>
        </div>
        <ChildrenSidebar
          children={currentChildren.children}
          title={currentChildren.title}
          open={openSidebar}
          closeSidebar={() => setOpenSidebar(false)}
        />
      </div>
    </ClickAwayListener>
  )
}

const items: SidebarMenuItemInterface[] = [
  {
    title: "layout:sidebar.menu.posts",
    index: "posts",
    matchUrls: [
      "/posts",
      "/posts/categories",
      "/posts/categories/create",
      "/posts/attributes",
      "/posts/attributes/create",
      "/posts/attribute-categories",
      "/posts/attribute-categories/create",
      "/bundle-categories",
    ],
    icon: faList,
    children: [
      {
        title: "layout:sidebar.menu.categories",
        path: "/posts/categories",
      },
      {
        title: "layout:sidebar.menu.attributes",
        path: "/posts/attributes",
      },
      {
        title: "layout:sidebar.menu.posts",
        path: "/posts",
      },
      {
        title: "layout:sidebar.menu.attribute_categories",
        path: "/posts/attribute-categories",
      },
      {
        title: "layout:sidebar.menu.bundle_categories",
        path: "/bundle-categories",
      },
    ],
  },
  {
    title: "layout:sidebar.menu.content_management",
    index: "content_management",
    matchUrls: [
      "/faq/categories",
      "/faq/categories/create",
      "/censorship",
      "/surveys",
      "/pages",
      "/notification-templates/create",
      "/notification-templates",
      "/blacklist",
      "/article-gallery",
      "/article-categories",
    ],
    icon: faPenToSquare,
    children: [
      {
        title: "layout:sidebar.menu.faq_categories",
        path: "/faq/categories",
      },
      {
        title: "layout:sidebar.menu.censorship",
        path: "/censorship",
      },
      {
        title: "layout:sidebar.menu.surveys",
        path: "/surveys",
      },
      {
        title: "layout:sidebar.menu.pages",
        path: "/pages",
      },
      {
        title: "layout:sidebar.menu.notification_templates",
        path: "/notification-templates",
      },
      {
        title: "layout:sidebar.menu.blacklist",
        path: "/blacklist",
      },
      {
        title: "layout:sidebar.menu.article_categories",
        path: "/article-categories",
      },
      {
        title: "layout:sidebar.menu.article_galleries",
        path: "/article-gallery",
      },
    ],
  },
  {
    title: "layout:sidebar.menu.users",
    index: "users",
    matchUrls: [
      "/users",
      "/users/create",
      "/roles",
      "/roles/create",
      "/issues",
    ],
    icon: faUsers,
    children: [
      {
        title: "layout:sidebar.menu.users",
        path: "/users",
      },
      {
        title: "layout:sidebar.menu.roles",
        path: "/roles",
      },
      {
        title: "layout:sidebar.menu.issues",
        path: "/issues",
      },
    ],
  },
  {
    title: "layout:sidebar.menu.payments",
    index: "payments",
    matchUrls: [
      "/invoices",
      "/advertisers",
      "/services",
      "/packages",
      "/ads/views",
      "/ads/slots",
    ],
    icon: faDollar,
    children: [
      {
        title: "layout:sidebar.menu.invoices",
        path: "/invoices",
      },
      {
        title: "layout:sidebar.menu.advertisers",
        path: "/advertisers",
      },
      {
        title: "layout:sidebar.menu.services",
        path: "/services",
      },
      {
        title: "layout:sidebar.menu.packages",
        path: "/packages",
      },
      {
        title: "layout:sidebar.menu.ads_views",
        path: "/ads/views",
      },
      {
        title: "layout:sidebar.menu.ads_slots",
        path: "/ads/slots",
      },
    ],
  },
]
