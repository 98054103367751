export enum AttributeTypeEnum {
  STRING = "string",
  INTEGER = "integer",
  FLOAT = "float",
  BOOL = "boolean",
  DATE = "date",
  PIORIN_FORMAT = "piorin_format",
  YEAR_FORMAT = "year_format",
  SELECT = "select",
  MULTI_SELECT = "multi_select",
  CERTIFICATE = "certificate",
  RANGE = "range",
}
