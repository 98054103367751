import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
  useDeleteInvoiceMutation,
  useGetInvoiceQuery,
} from "@/features/invoices/redux/invoiceAPI"
import React from "react"
import { Button } from "@/features/components/buttons/button"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { InvoiceBasicDetails } from "./_components/basic"
import { InvoiceUserDetails } from "@/features/invoices/resources/views/details/_components/user"
import { InvoiceBuyerDetails } from "@/features/invoices/resources/views/details/_components/buyer"
import { InvoiceItemList } from "@/features/invoices/items/resources/views/list"
import { invoiceEditFormModal } from "@/features/invoices/resources/views/edit"
import { toast } from "react-toastify"

export const InvoiceDetailsScreen = (): React.ReactNode => {
  const { id } = useParams() as { id: string }
  const { t } = useTranslation(["invoices", "form"])
  const { data } = useGetInvoiceQuery(id)
  const { hasPermission } = usePermissions()
  const [deleteInvoice] = useDeleteInvoiceMutation()
  const navigate = useNavigate()

  const handleDelete = () => {
    deleteInvoice(id)
      .unwrap()
      .then(() => {
        toast.success(t("invoices:list.deleted"))
        navigate("/invoices")
      })
  }

  const handleEdit = () => {
    void invoiceEditFormModal({ id })
  }

  return (
    <div className={"flex flex-col gap-y-4 p-4"}>
      <div className={"flex justify-between items-center"}>
        <h3 className={"text-2xl font-medium"}>
          {t("invoices:details.title", { signature: data?.signature })}
        </h3>
        <div className={"flex gap-x-2"}>
          {hasPermission(PermissionEnum.INVOICE_SAVE) && (
            <Button variant={"contained"} onClick={handleEdit}>
              {t("form:buttons.edit")}
            </Button>
          )}
          {hasPermission(PermissionEnum.INVOICE_DESTROY) && (
            <Button
              variant={"contained"}
              onClick={handleDelete}
              className={"bg-red-600"}
            >
              {t("form:buttons.delete")}
            </Button>
          )}
        </div>
      </div>
      {data && (
        <div className={"grid grid-cols-3 gap-4"}>
          <InvoiceBasicDetails data={data} />
          <InvoiceUserDetails data={data} />
          <InvoiceBuyerDetails data={data} />
        </div>
      )}
      {data && data.items && (
        <InvoiceItemList invoiceId={id} data={data.items} />
      )}
    </div>
  )
}
