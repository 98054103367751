import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { ButtonLink } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./components/table"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"

const BundleCategoryListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["bundle_categories"])
  const { hasPermission } = usePermissions()

  return (
    <div className={"flex flex-col gap-y-4 p-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.CATEGORY_BUNDLE_SAVE) && (
          <ButtonLink
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            to={"/bundle-categories/create"}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t("bundle_categories:list.create")}</span>
          </ButtonLink>
        )}
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("bundle_categories:list.title")}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}

export { BundleCategoryListScreen }
