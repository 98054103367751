import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { useGetInvoiceStatisticsQuery } from "@/features/invoices/redux/invoiceAPI"
import { Input } from "@/features/components/inputs/input"
import { InvoiceStatisticsInterface } from "@/features/invoices/redux/types"
import { SearchFilterInterface } from "../filters/types"
import { buildQuery } from "@/features/invoices/resources/views/list/_components/filters/helper"

type Props = {
  filters: SearchFilterInterface | undefined
}

export const InvoiceStatistics: React.FC<Props> = ({
  filters,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "invoices"])
  const query = useMemo(() => buildQuery(filters), [filters])
  const { data } = useGetInvoiceStatisticsQuery(query.url())
  const [statistics, setStatistics] = useState<InvoiceStatisticsInterface>({
    count: 0,
    gross_value: 0,
    net_value: 0,
    tax_value: 0,
  })

  useEffect(() => {
    if (data) {
      setStatistics(data)
    }
  }, [data])

  return (
    <div className={"grid"}>
      <Card className={"flex flex-col gap-y-4"}>
        <span className={"font-medium"}>{t("invoices:list.statistics")}</span>
        <div className={"grid grid-cols-4 gap-4"}>
          <Input
            label={t("form:labels.gross_value")}
            value={statistics.gross_value}
            readOnly
            endAdornment={"PLN"}
          />
          <Input
            label={t("form:labels.net_value")}
            value={statistics.net_value}
            readOnly
            endAdornment={"PLN"}
          />
          <Input
            label={t("form:labels.invoice_count")}
            value={statistics.count}
            readOnly
          />
          <Input
            label={t("form:labels.tax_value")}
            value={statistics.tax_value}
            endAdornment={"PLN"}
            readOnly
          />
        </div>
      </Card>
    </div>
  )
}
