import axios from "axios"
import { url } from "@/utils/api/igrit"
import { store } from "@/app/store"
import { GalleryEnum } from "@/app/enums/galleryEnum"

export class UploadAdapter {
  constructor(private loader: any, private type: GalleryEnum) {}

  upload() {
    return this.loader.file.then((file: File) => {
      const { token } = store.getState().auth

      return axios.post(`${url}/admin/gallery/${this.type}`, file, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": file.type,
        },
      })
    })
  }
}
