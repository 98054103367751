export enum UnitEnum {
  SECONDS = "seconds",
  MINUTES = "minutes",
  HOURS = "hours",
  PERCENT = "percent",
  KILOGRAMS_PER_HECTOLITER = "kilograms_per_hectoliter",
  MILLIGRAMS_PER_KILOGRAM = "milligrams_per_kilogram",
  KILOGRAM = "kilogram",
  TON = "ton",
  LITER = "liter",
  PIECES = "pieces",
  HECTARE = "hectare",
  METER = "meter",
}
