import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Table } from "./_components/table"
import Card from "@/features/components/cards"
import { SearchFilterInterface } from "./_components/filters/types"
import { InvoiceFilter } from "./_components/filters"
import { InvoiceStatistics } from "@/features/invoices/resources/views/list/_components/statistics"

export const InvoiceListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["invoices"])
  const [filters, setFilters] = useState<SearchFilterInterface>()

  const handleSearch = (data: SearchFilterInterface) => {
    setFilters(data)
  }

  return (
    <div className={"flex flex-col p-8 gap-y-4"}>
      <InvoiceFilter onSubmit={handleSearch} />
      <InvoiceStatistics filters={filters} />
      <div className={"flex gap-x-2"}></div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("invoices:list.title")}</span>
          </div>
          <Table filters={filters} />
        </div>
      </Card>
    </div>
  )
}
