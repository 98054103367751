import igritAPI from "@/utils/api/igrit"
import { SearchUsersResponseInterface } from "@/features/users/redux/types"
import { setArrayCache } from "@/utils/api/rtkHelper"

export const { useLazySearchUsersQuery } = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    searchUsers: build.query<SearchUsersResponseInterface["data"], string>({
      query: (query) => `/admin/users/search?${query}`,
      transformResponse: (response: SearchUsersResponseInterface) =>
        response.data,
      providesTags: (result) => setArrayCache("User", result),
    }),
  }),
})
