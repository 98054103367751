import React from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import {
  useDeleteArticleCategoryMutation,
  useGetArticleCategoriesQuery,
  useUpdateArticleCategoryMutation,
} from "@/features/articleCategories/redux/articleCategoryAPI"
import { ArticleCategoryInterface } from "@/features/articleCategories/redux/types"
import { articleCategoryFormModal } from "@/features/articleCategories/resources/components/form"

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(["form", "article_categories", "utils"])
  const { data = [] } = useGetArticleCategoriesQuery()
  const [deleteArticleGallery] = useDeleteArticleCategoryMutation()
  const [updateArticleGallery] = useUpdateArticleCategoryMutation()

  const onDelete = (id: number) => {
    deleteArticleGallery(id)
      .unwrap()
      .then(() => toast.success(t("article_categories:list.deleted")))
  }

  const onEdit = (id: number) => {
    const row = data.find((item) => item.id === id)

    articleCategoryFormModal({
      title: t("article_categories:edit.title"),
      data: row,
    }).then((data) => {
      data.subcategories = data.subcategories.map((subcategory: string) => ({
        name: subcategory,
      }))
      return updateArticleGallery({ data, id })
        .unwrap()
        .then(() => toast.success(t("article_categories:edit.success")))
    })
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
  })

  return <BaseTable table={table} />
}

const columnBuilder = createColumnHelper<ArticleCategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("name", {
    id: "name",
    header: t("form:labels.name"),
  }),
  columnBuilder.accessor("active", {
    id: "active",
    header: t("form:labels.active"),
    cell: ({ row }) =>
      row.original.active ? t("form:labels.yes") : t("form:labels.no"),
  }),
  columnBuilder.accessor("subcategories", {
    id: "subcategories",
    header: t("form:labels.subcategories"),
    cell: ({ row }) =>
      row.original.subcategories?.map((subcategory, index) => (
        <span key={index} className={"p-0.5"}>
          {subcategory.name}
        </span>
      )),
  }),

  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.ARTICLE_CATEGORY_DESTROY}
        editPermission={PermissionEnum.ARTICLE_CATEGORY_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
