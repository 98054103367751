import {
  SaveAttributeInterface,
  SaveAttributeValueInterface,
} from "@/features/posts/attributeCategories/redux/types"
import { MultiValue } from "react-select"
import { Select2OptionInterface } from "@/app/types"

export const delimitValue = (value: string | null) => {
  if (!value) return null

  return value.split(",").map((item) => item.trim())
}

export const instanceOfAttribute = (
  object: any,
): object is SaveAttributeInterface => {
  return "values" in object
}

export const instanceOfAttributeValue = (
  object: any,
): object is SaveAttributeValueInterface => {
  return "value" in object
}

export const explodeValue = (
  values: MultiValue<Select2OptionInterface>,
): string[] => {
  const transformedValues: string[] = []

  values.forEach((value) => {
    delimitValue(value.value)?.forEach((delimitedValue) => {
      transformedValues.push(delimitedValue)
    })
  })

  return transformedValues
}
