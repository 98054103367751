import React from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import { useDeleteSurveyAnswerMutation } from "@/features/surveys/redux/surveyAPI"
import { SurveyAnswerInterface } from "@/features/surveys/redux/types"

type Props = {
  onEdit: (id: number) => void
  surveyId: number | string
  data: SurveyAnswerInterface[]
  totalVotes: number
}

export const Table: React.FC<Props> = ({
  onEdit,
  surveyId,
  data,
  totalVotes,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "surveys", "utils"])
  const [deleteSurveyAnswer] = useDeleteSurveyAnswerMutation()

  const onDelete = (id: number) => {
    deleteSurveyAnswer({ surveyId, id })
      .unwrap()
      .then(() => toast.success(t("surveys:answers.list.deleted")))
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit, totalVotes),
    getCoreRowModel: getCoreRowModel(),
    data,
  })

  return <BaseTable table={table} />
}

const columnBuilder = createColumnHelper<SurveyAnswerInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  totalVotes: number,
) => [
  columnBuilder.accessor("answer", {
    id: "answer",
    header: t("form:labels.answer"),
  }),
  columnBuilder.accessor("votes_count", {
    id: "votes_count",
    header: t("form:labels.votes"),
    cell: ({ row }) =>
      ((row.original.votes_count * 100) / totalVotes).toFixed(0) + "%",
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.SURVEY_DESTROY}
        editPermission={PermissionEnum.SURVEY_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
