import React, { useEffect } from "react"
import {
  ArticleCategoryInterface,
  SaveArticleCategoryValidationInterface,
} from "@/features/articleCategories/redux/types"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveArticleCategoryValidation from "./validations/SaveArticleCategoryValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Modal from "@/features/components/modals/modal"
import { create, InstanceProps } from "react-modal-promise"
import ModalTitle from "@/features/components/modals/ModalTitle"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Label } from "@/features/components/inputs/label"
import CreateableSelect from "@/features/components/inputs/asyncSelect/createableSelect"
import { Button } from "@/features/components/buttons/button"
import { Checkbox } from "@/features/components/inputs/checkbox"

type Props = {
  data?: ArticleCategoryInterface
  title: string
} & InstanceProps<SaveArticleCategoryValidationInterface>

const Form: React.FC<Props> = ({
  data,
  title,
  onResolve,
  onReject,
  isOpen,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "validation", "utils"])
  const { schema, defaultValues } = useValidation(
    new SaveArticleCategoryValidation(),
    t,
  )
  const { control, setValue, handleSubmit } =
    useForm<SaveArticleCategoryValidationInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue("name", data.name)
      setValue("active", data.active)

      if (data.subcategories) {
        setValue(
          "subcategories",
          data.subcategories.map((subcategory) => subcategory.name),
        )
      }
    }
  }, [data])

  return (
    <Modal open={isOpen} onClose={() => onReject()}>
      <Modal.Content size={"lg:w-[800px]"}>
        <ModalTitle title={title} onClose={onReject} />
        <form
          onSubmit={handleSubmit(onResolve)}
          className={"flex flex-col gap-y-4 pt-8"}
        >
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <Input label={t("form:labels.name")} />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={"name"}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl className={"flex flex-col"} error={!!error}>
                <Label label={t("form:labels.subcategories")} />
                <CreateableSelect
                  value={field.value.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  isMulti
                  onChange={(value) =>
                    field.onChange(value.map((item) => item.value))
                  }
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={"subcategories"}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <Checkbox
                  label={t("form:labels.active")}
                  checked={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
            )}
            name={"active"}
            control={control}
          />
          <div className={"flex gap-x-4 pt-4"}>
            <Button variant={"contained"} type={"submit"} className={"w-full"}>
              {t("form:buttons.save")}
            </Button>
            <Button
              variant={"outlined"}
              onClick={() => onReject()}
              className={"w-full"}
            >
              {t("form:buttons.cancel")}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const articleCategoryFormModal = create(Form)
