import React, { FormEvent, Fragment, useState } from "react"
import { create, InstanceProps } from "react-modal-promise"
import { useTranslation } from "react-i18next"
import Modal from "@/features/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Label } from "@/features/components/inputs/label"
import { Input } from "@/features/components/inputs/input"
import { Button } from "@/features/components/buttons/button"
import { toast } from "react-toastify"
import { useBlockPostMutation } from "@/features/posts/posts/redux/postAPI"

type Props = {
  isBlocked: boolean
  postId: number | string
} & InstanceProps<void>

const BlockModal: React.FC<Props> = ({
  isOpen,
  onResolve,
  onReject,
  isBlocked,
  postId,
}): React.ReactNode => {
  const { t } = useTranslation(["form"])
  const [reason, setReason] = useState<string>("")
  const [toogleBlock] = useBlockPostMutation()

  const handleToggle = (e: FormEvent) => {
    e.preventDefault()

    toogleBlock({ id: postId, reason })
      .unwrap()
      .then(() => {
        toast.success(
          t(
            `posts/posts:edit.block.success_${isBlocked ? "unblock" : "block"}`,
          ),
        )
        onResolve()
      })
  }

  return (
    <Modal open={isOpen} onClose={() => onReject()}>
      <Modal.Content className={"gap-y-4 flex flex-col"}>
        <Fragment>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold"}>
              {t("posts/posts:edit.block.title")}
            </span>
            <FontAwesomeIcon
              onClick={() => onReject()}
              icon={faTimes}
              className={"cursor-pointer"}
            />
          </div>
          <form onSubmit={handleToggle} className={"flex flex-col gap-y-2"}>
            <div className={"flex flex-col"}>
              <Label label={t("form:labels.reason")} />
              <Input
                multiline
                rows={5}
                onChange={(e) => setReason(e.currentTarget.value)}
              />
            </div>
            <div className={"flex gap-x-2 pt-4"}>
              <Button
                variant={"contained"}
                className={"w-full"}
                type={"submit"}
              >
                {t("form:buttons.save")}
              </Button>
              <Button
                className={"w-full"}
                variant={"outlined"}
                onClick={() => onReject()}
              >
                {t("form:buttons.cancel")}
              </Button>
            </div>
          </form>
        </Fragment>
      </Modal.Content>
    </Modal>
  )
}

export const blockModal = create(BlockModal)
