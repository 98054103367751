import {
  SaveServiceRequestInterface,
  ServiceInterface,
} from "@/features/services/redux/types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveServiceValidation from "@/features/services/resources/_components/form/validations/saveServiceValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormInterface } from "@/app/types"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/features/components/inputs/select"
import _ from "lodash"
import { ServiceNameEnum } from "@/features/posts/posts/redux/enums/service"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { ServiceUnitEnum } from "@/features/services/redux/enums/serviceUnit"
import { Checkbox } from "@/features/components/inputs/checkbox"
import { Button } from "@/features/components/buttons/button"

export const Form: React.FC<
  FormInterface<SaveServiceRequestInterface, ServiceInterface>
> = ({ data, onSubmit, errors }): React.ReactNode => {
  const { t } = useTranslation(["form", "validation", "utils"])
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    new SaveServiceValidation(),
    t,
  )
  const { control, handleSubmit, setValue, setError } =
    useForm<SaveServiceRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue("system_name", data.system_name)
      setValue("price", data.price)
      setValue("points", data.points)
      setValue("discounted_price", data.discounted_price)
      setValue("quantity", data.quantity)
      setValue("description", data.description)
      setValue("unit", data.unit)
      setValue("standalone", data.standalone)
    }
  }, [data])

  useEffect(() => {
    if (errors) {
      const err = resolveValidationErrors(errors)

      _.forEach(err, (value, key) => {
        setError(key as keyof SaveServiceRequestInterface, {
          type: "manual",
          message: value,
        })
      })
    }
  }, [errors])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col gap-4"}>
      <div className={"grid grid-cols-4 gap-4"}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <Select
                value={field.value}
                label={t("form:labels.system_name")}
                onChange={(_, value) => field.onChange(value)}
              >
                {_.map(ServiceNameEnum, (value, key) => (
                  <Option value={value} key={key}>
                    {t(`utils:services.${value}`)}
                  </Option>
                ))}
              </Select>
            </FormControl>
          )}
          name={"system_name"}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <Input type={"number"} label={t("form:labels.price")} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={"price"}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <Input type={"number"} label={t("form:labels.points")} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={"points"}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <Input
                type={"number"}
                label={t("form:labels.discounted_price")}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={"discounted_price"}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <Input
                type={"number"}
                label={t("form:labels.discounted_points")}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={"discounted_points"}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <Input type={"number"} label={t("form:labels.quantity")} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={"quantity"}
          control={control}
        />
        <div className={"col-span-2"}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <Input label={t("form:labels.description")} />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={"description"}
            control={control}
          />
        </div>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <Select
                value={field.value}
                label={t("form:labels.unit")}
                onChange={(_, value) => field.onChange(value)}
              >
                {_.map(ServiceUnitEnum, (value, key) => (
                  <Option value={value} key={key}>
                    {t(`utils:service_units.${value}`)}
                  </Option>
                ))}
              </Select>
            </FormControl>
          )}
          name={"unit"}
          control={control}
        />
      </div>
      <div className={"flex flex-col gap-y-1"}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                label={t("form:labels.standalone")}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={"standalone"}
          control={control}
        />
      </div>
      <div className={"flex"}>
        <Button variant={"contained"} type={"submit"}>
          {t("form:buttons.save")}
        </Button>
      </div>
    </form>
  )
}
