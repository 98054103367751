import React, { useCallback, useEffect, useState } from "react"
import { Table } from "./_components/table"
import { useGetSurveyQuery } from "@/features/surveys/redux/surveyAPI"
import { SurveyAnswerInterface } from "@/features/surveys/redux/types"

type Props = {
  surveyId: number | string
  totalVotes: number
  onEdit: (answer: SurveyAnswerInterface) => void
}

export const SurveyAnswersList: React.FC<Props> = ({
  surveyId,
  onEdit,
  totalVotes,
}): React.ReactNode => {
  const { data } = useGetSurveyQuery(surveyId)
  const [answers, setAnswers] = useState<SurveyAnswerInterface[]>([])

  useEffect(() => {
    if (data && data.answers) {
      setAnswers(data.answers)
    }
  }, [data])

  const handleEditAnswer = useCallback(
    (id: number) => {
      const answer = answers.find((answer) => answer.id === id)

      if (!answer) return

      onEdit(answer)
    },
    [answers, onEdit],
  )

  return (
    <div className={"flex flex-col w-full gap-y-4"}>
      <Table
        totalVotes={totalVotes}
        onEdit={handleEditAnswer}
        surveyId={surveyId}
        data={answers}
      />
    </div>
  )
}
