import { BackendValidationErrorInterface } from "@/utils/hooks/useValidation/types"

export interface SuccessResponseInterface {
  data: any
  code: number
}

export interface ErrorResponseInterface {
  status: number
  data: {
    data: any
    code: ResponseCodeEnum
  }
}

export interface ErrorValidationResponseInterface<T> {
  status: number
  data: BackendValidationErrorInterface<T>
}

export interface ValidationErrorInterface {
  code: ResponseCodeEnum
  errors: Record<string, string[]>
}

export interface FormInterface<T extends object, D extends object = {}> {
  onSubmit: (data: T) => void
  errors?: BackendValidationErrorInterface<T>
  data?: D
  params?: { [key: string]: any }
}

export interface PaginationInterface {
  count: number
  current_page: number
  per_page: number
  total: number
  total_pages: number
}

export interface Select2OptionInterface {
  label: string
  value: string
}

export interface PaginatedResponseInterface<T> {
  data: T[]
  pagination: PaginationInterface
}

export enum ResponseCodeEnum {
  CATEGORY_CAN_NOT_BE_DELETED = 1008,
  ATTRIBUTE_CAN_NOT_BE_DELETED = 1009,
  ATTRIBUTE_CATEGORY_BINDING_EXIST = 1011,
}

export interface ElasticSearchResponseInterface<T extends object> {
  took: number
  timed_out: boolean
  _shards: {
    total: number
    successful: number
    skipped: number
    failed: number
  }
  hits: {
    total: {
      value: number
      relation: "eq" | "gt"
    }
    max_score: number
    hits: ElasticHitInterface<T>[]
  }
}

export interface ElasticHitInterface<T extends object> {
  _index: string
  _type: string
  _id: string
  _score: number
  _source: T
}

export interface NominatimSearchResponseInterface {
  place_id: number
  licence: string
  osm_type: string
  osm_id: number
  boundingbox: string[]
  lat: number
  lon: number
  display_name: string
  place_rank: number
  category: string
  type: string
  importance: number
  address: {
    city: string
    state_district: string
    state: string
    country: string
    country_code: string
  }
}

export enum ModelEnum {
  POST = "post",
}

export interface AuditInterface {
  id: number
  created_at: string
  event: "created" | "updated" | "deleted"
  auditable_type: ModelEnum
  old_values: object
  new_values: object
  user: {
    id: number
    name: string
    email: string
  } | null
}

export interface GetAuditsResponseInterface extends SuccessResponseInterface {
  data: PaginatedResponseInterface<AuditInterface>
}

export const AvailableLocales: string[] = ["pl"]
