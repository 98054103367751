import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { create, InstanceProps } from "react-modal-promise"
import Modal from "@/features/components/modals/modal"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Button } from "@/features/components/buttons/button"
import {
  BlackListRowInterface,
  BlackListRowTypes,
  SaveBlackListRowRequestInterface,
} from "@/features/blacklist/redux/types"
import SaveBlackListRowValidation from "./validations/SaveBlackListRowValidation"
import Carbon from "@/utils/carbon"
import ModalTitle from "@/features/components/modals/ModalTitle"
import Select, { Option } from "@/features/components/inputs/select"

type Props = {
  data?: BlackListRowInterface
  title: string
} & InstanceProps<SaveBlackListRowRequestInterface, unknown>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  onReject,
  onResolve,
  title,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "validation"])
  const { schema, defaultValues } = useValidation(
    new SaveBlackListRowValidation(),
    t,
  )

  const { control, setValue, handleSubmit } =
    useForm<SaveBlackListRowRequestInterface>({
      resolver: yupResolver(schema),
      defaultValues,
    })

  useEffect(() => {
    if (data) {
      setValue("value", data.value)
      setValue("type", data.type)

      if (data.reason) {
        setValue("reason", data.reason)
      }

      if (data.expires_at) {
        setValue(
          "expires_at",
          new Carbon(data.expires_at).toISOString().slice(0, 16),
        )
      }
    }
  }, [data])

  return (
    <Modal open={isOpen} onClose={() => onReject}>
      <Modal.Content size={"w-[500px]"}>
        <div className={"flex flex-col gap-y-6"}>
          <ModalTitle title={title} onClose={onReject} />
          <form
            className={"flex flex-col gap-y-2"}
            onSubmit={handleSubmit(onResolve)}
          >
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input label={t("form:labels.value")} />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={"value"}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <Select
                    value={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    label={t("form:labels.type")}
                  >
                    {BlackListRowTypes.map((type, index) => (
                      <Option value={type} key={index}>
                        {t(`utils:blacklist_types.${type}`)}
                      </Option>
                    ))}
                  </Select>
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={"type"}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input multiline rows={5} label={t("form:labels.reason")} />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={"reason"}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input
                    type={"datetime-local"}
                    label={t("form:labels.expires_at")}
                  />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={"expires_at"}
              control={control}
            />
            <div className={"flex gap-x-4 pt-4"}>
              <Button
                variant={"contained"}
                type={"submit"}
                className={"w-full"}
              >
                {t("form:buttons.save")}
              </Button>
              <Button
                variant={"outlined"}
                onClick={() => onReject()}
                className={"w-full"}
              >
                {t("form:buttons.cancel")}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const blackListFormModal = create(Form)
