import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Form } from "@/features/posts/categories/resources/_components/forms"
import { SaveCategoryPropsInterface } from "@/features/posts/categories/redux/types"
import {
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from "@/features/posts/categories/redux/categoryAPI"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { buildFormData } from "@/features/posts/categories/resources/_components/forms/helper"

export const PostCategoryEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(["posts/categories"])
  const [updateCategory] = useUpdateCategoryMutation()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data } = useGetCategoryQuery(id)

  const handleSubmit = (data: SaveCategoryPropsInterface) => {
    const formData = buildFormData(data)
    formData.append("_method", "PUT")

    updateCategory({ id, body: formData })
      .unwrap()
      .then(() => {
        toast.success(t("posts/categories:edit.success"))
        navigate("/posts/categories")
      })
  }

  return (
    <div className={"flex flex-col p-4 gap-y-8"}>
      <span className={"text-2xl font-medium"}>
        {t("posts/categories:edit.title")}
      </span>
      <Card>
        <Form data={data} onSubmit={handleSubmit} />
      </Card>
    </div>
  )
}
