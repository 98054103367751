import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveServiceRequestInterface } from "@/features/services/redux/types"
import { ServiceNameEnum } from "@/features/posts/posts/redux/enums/service"
import { ServiceUnitEnum } from "@/features/services/redux/enums/serviceUnit"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"

export default class SaveServiceValidation
  implements ValidationInterface<SaveServiceRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveServiceRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      system_name: yup
        .mixed<ServiceNameEnum>()
        .required(t("validation:required")),
      description: yup.string().nullable(),
      price: yup
        .number()
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
      points: yup
        .number()
        .nullable()
        .min(0, t("validation:min", { min: 0 })),
      discounted_price: yup
        .number()
        .nullable()
        .min(0, t("validation:min", { min: 0 })),
      discounted_points: yup
        .number()
        .nullable()
        .min(0, t("validation:min", { min: 0 })),
      quantity: yup
        .number()
        .required(t("validation:required"))
        .min(0, t("validation:min", { min: 0 })),
      standalone: yup.boolean().required(t("validation:required")),
      unit: yup
        .mixed<ServiceUnitEnum>()
        .oneOf(Object.values(ServiceUnitEnum))
        .required(t("validation:required")),
    })
  }

  defaultValues(): SaveServiceRequestInterface {
    return {
      system_name: ServiceNameEnum.POST_REFRESH,
      description: null,
      price: 0,
      points: null,
      discounted_price: null,
      discounted_points: null,
      quantity: 0,
      standalone: false,
      unit: ServiceUnitEnum.DAY,
    }
  }
}
