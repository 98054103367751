import { useParams } from "react-router-dom"
import {
  useDeleteCategoryMutation,
  useGetCategoryQuery,
} from "@/features/posts/categories/redux/categoryAPI"
import React from "react"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { useTranslation } from "react-i18next"
import { Button, ButtonLink } from "@/features/components/buttons/button"
import { toast } from "react-toastify"
import { isCustomError } from "@/utils/api/rtkHelper"
import { ResponseCodeEnum } from "@/app/types"
import { Details } from "./_components/details"
import { Tree } from "./_components/tree"

export const CategoryDetailsScreen = (): React.ReactNode => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data } = useGetCategoryQuery(id)
  const { t } = useTranslation(["posts/categories"])
  const { hasPermission } = usePermissions()
  const [deleteCategory] = useDeleteCategoryMutation()

  if (!data) {
    return <div>Loading...</div>
  }

  const handleDelete = (): void => {
    deleteCategory(id)
      .unwrap()
      .then(() => toast.success(t("posts/categories:list.deleted")))
      .catch((error) => {
        if (
          isCustomError(error, ResponseCodeEnum.CATEGORY_CAN_NOT_BE_DELETED)
        ) {
          toast.error(t("posts/categories:list.can_not_be_deleted"))
        }
      })
  }

  return (
    <div className={"flex flex-col p-4 gap-y-6"}>
      <div className={"flex justify-between items-center"}>
        <h3 className={"text-2xl font-medium"}>
          {t("posts/categories:details.title")}
        </h3>
        <div className={"flex gap-x-2"}>
          {hasPermission(PermissionEnum.CATEGORY_SAVE) && (
            <ButtonLink to={`/categories/${id}/edit`} variant={"contained"}>
              {t("form:buttons.edit")}
            </ButtonLink>
          )}
          {hasPermission(PermissionEnum.CATEGORY_DESTROY) && (
            <Button
              variant={"contained"}
              onClick={handleDelete}
              className={"bg-red-600"}
            >
              {t("form:buttons.delete")}
            </Button>
          )}
        </div>
      </div>
      <div className={"grid lg:grid-cols-2 grid-cols-1 gap-4"}>
        <Details type={"details"} data={data} />
        {data.parent_category && (
          <Details type={"parent_details"} data={data.parent_category} />
        )}
        {data.additional_categories &&
          data.additional_categories.map((item) => (
            <Details key={item.id} type={"additional_category"} data={item} />
          ))}
        {data.tree && <Tree tree={data.tree} />}
      </div>
    </div>
  )
}
