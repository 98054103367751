import { InvoiceInterface } from "@/features/invoices/redux/types"
import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"

type Props = {
  data: InvoiceInterface
}

export const InvoiceUserDetails: React.FC<Props> = ({
  data,
}): React.ReactNode => {
  const { t } = useTranslation(["invoices", "form", "utils"])

  return (
    <Card>
      <div className={"flex flex-col gap-y-6"}>
        <span className={"text-lg font-medium"}>
          {t("invoices:details.user_information")}
        </span>
        <div className={"flex flex-col gap-y-2"}>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>{t("form:labels.id")}</span>
            <span>{data.user.id}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>{t("form:labels.email")}</span>
            <span>{data.user.email}</span>
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <span className={"font-medium"}>{t("form:labels.name")}</span>
            <span>{data.user.name}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}
