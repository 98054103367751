import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetPageQuery,
  useUpdatePageMutation,
} from "@/features/pages/redux/pageAPI"
import { SavePageRequestInterface } from "@/features/pages/redux/types"
import { Form } from "@/features/pages/resources/_components/form"
import { serialize } from "object-to-formdata"
import { toast } from "react-toastify"

export const PageEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(["pages"])
  const { id } = useParams() as { id: string }
  const [updatePage] = useUpdatePageMutation()
  const { data } = useGetPageQuery(id)
  const navigate = useNavigate()

  const handleSubmit = (data: SavePageRequestInterface) => {
    const form = serialize(data, {
      booleansAsIntegers: true,
    })

    form.append("_method", "PUT")

    updatePage({ id, body: form })
      .unwrap()
      .then(() => {
        toast.success(t("pages:edit.success"))
        navigate("/pages")
      })
  }

  return (
    <div className={"flex flex-col p-4 gap-y-8"}>
      <span className={"text-2xl font-medium"}>{t("pages:edit.title")}</span>
      <Card>
        <Form data={data} onSubmit={handleSubmit} />
      </Card>
    </div>
  )
}
