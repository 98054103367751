import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import * as yup from "yup"
import { TFunction } from "i18next"
import { StoreArticleMediaGalleryInterface } from "@/features/articleMediaGalleries/redux/types"

export default class StoreArticleMediaGalleryValidation
  implements ValidationInterface<StoreArticleMediaGalleryInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<StoreArticleMediaGalleryInterface, yup.AnyObject> {
    return yup.object().shape({
      files: yup
        .array()
        .of(
          yup.object().shape({
            file: yup.mixed<File>().required(t("validation:required")),
            watermark: yup.boolean().required(),
            order: yup
              .number()
              .required(t("validation:required"))
              .min(0, t("validation:field_must_be_greater_than", { min: 0 })),
            title: yup
              .string()
              .required(t("validation:required"))
              .max(255, t("validation:field_must_be_less_than", { max: 255 })),
            description: yup
              .string()
              .max(255, t("validation:field_must_be_less_than", { max: 255 }))
              .nullable(),
          }),
        )
        .required(),
    })
  }

  defaultValues(): StoreArticleMediaGalleryInterface {
    return {
      files: [],
    }
  }
}
