import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import usePagination from "@/utils/hooks/usePagination"
import { Query } from "@/utils/query"
import {
  useDeleteAdsSlotMutation,
  useGetAdsSlotsQuery,
  useUpdateAdsSlotMutation,
} from "@/features/ads/slots/redux/slotAPI"
import { AdsSlotInterface } from "@/features/ads/slots/redux/types"
import { adsSlotFormModal } from "@/features/ads/slots/resources/components"
import { ValidationErrorInterface } from "@/app/types"
import _ from "lodash"
import { isFetchBaseQueryError } from "@/app/hooks"

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(["form", "ads_slots", "utils"])
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex).includes("views"),
    [pagination],
  )
  const { data: apiData } = useGetAdsSlotsQuery(query.url())
  const [data, setData] = useState<AdsSlotInterface[]>([])
  const [deleteAdsSlot] = useDeleteAdsSlotMutation()
  const [updateAdsSlot] = useUpdateAdsSlotMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = (id: number) => {
    deleteAdsSlot(id)
      .unwrap()
      .then(() => toast.success(t("ads_slots:list.deleted")))
  }

  const onEdit = (id: number) => {
    const row = data.find((item) => item.id === id)

    adsSlotFormModal({
      title: t("ads_slots:edit.title"),
      data: row,
    }).then((data) =>
      updateAdsSlot({ data, id })
        .unwrap()
        .then(() => toast.success(t("ads_slots:edit.success")))
        .catch((error) => {
          if (isFetchBaseQueryError(error) && error.status === 422) {
            const errors = error.data as ValidationErrorInterface

            toast.error(() => (
              <ul className={"flex flex-col gap-y-1"}>
                {_.map(errors.errors, (error, key) => (
                  <li key={key}>{error.join(", ")}</li>
                ))}
              </ul>
            ))
          }
        }),
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    manualPagination: true,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<AdsSlotInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("name", {
    id: "name",
    header: t("form:labels.name"),
  }),
  columnBuilder.accessor("type", {
    id: "type",
    header: t("form:labels.type"),
    cell: ({ row }) => row.original.type.toUpperCase().replaceAll("_", " "),
  }),
  columnBuilder.accessor("views", {
    id: "views",
    header: t("form:labels.views"),
    cell: ({ row }) => row.original.views?.map((view) => view.name).join(", "),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.SLOT_DESTROY}
        editPermission={PermissionEnum.SLOT_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
