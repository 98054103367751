import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Table } from "@/features/posts/posts/resources/views/list/_components/table"
import Card from "@/features/components/cards"
import { SearchFilterInterface } from "@/features/posts/_components/posts/filters/types"
import { MassAction } from "./_components/actions/massAction"
import { buildElasticSearchQuery } from "@/features/posts/_components/posts/filters/helper"
import { PostFilter } from "@/features/posts/_components/posts/filters"
import { AllowedMassActionType } from "@/features/posts/posts/redux/types"
import { useManagePostsMutation } from "@/features/posts/posts/redux/postAPI"
import { toast } from "react-toastify"

export const PostsListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["posts/posts"])
  const [rowSelection, setRowSelection] = useState<number[]>([])
  const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>({})
  const [managePosts] = useManagePostsMutation()

  const handleAction = (action: AllowedMassActionType) => {
    managePosts({ action, ids: rowSelection })
      .unwrap()
      .then(() => toast.success(t("posts/posts:mass_actions.success")))
  }

  const handleSearch = (data: SearchFilterInterface) => {
    setSearchFilters(buildElasticSearchQuery(data))
  }

  return (
    <div className={"flex flex-col p-8 gap-y-4"}>
      <PostFilter onSubmit={handleSearch} />
      <div className={"flex max-w-1/3 lg:max-w-1/4"}>
        <MassAction onConfirm={handleAction} />
      </div>
      <div className={"flex gap-x-2"}></div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("posts/posts:list.title")}</span>
          </div>
          <Table onRowSelectionChange={setRowSelection} query={searchFilters} />
        </div>
      </Card>
    </div>
  )
}
