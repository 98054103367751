import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { NominatimSearchResponseInterface } from "@/app/types"

const url = import.meta.env.VITE_NOMINATIM_API_URL

const nominatimAPI = createApi({
  tagTypes: [],
  reducerPath: "nomiatimAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: url,
  }),
  endpoints: (builder) => ({
    searchCity: builder.query<NominatimSearchResponseInterface[], string>({
      query: (query) => `/search.php?format=json&addressdetails=1&q=${query}`,
      transformResponse: (response: NominatimSearchResponseInterface[]) => {
        return response.filter(
          (item) =>
            item.address.city && item.address.state && item.address.country,
        )
      },
    }),
  }),
})

export const { useLazySearchCityQuery } = nominatimAPI

export default nominatimAPI
