import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveArticleRequestInterface } from "@/features/articles/redux/types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { ArticleStatusEnum } from "@/features/articles/redux/enums/ArticleStatusEnum"
import _ from "lodash"

export default class SaveArticleValidation
  implements ValidationInterface<SaveArticleRequestInterface>
{
  rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveArticleRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      article_category_id: yup.number().nullable(t("validation.required")),
      category_id: yup.number().nullable(t("validation.required")),
      status: yup
        .mixed<ArticleStatusEnum>()
        .required(t("validation.required"))
        .oneOf(Object.values(ArticleStatusEnum)),
      promoted: yup.boolean().required(t("validation.required")),
      important: yup.boolean().required(t("validation.required")),
      article_of_day: yup.boolean().required(t("validation.required")),
      title: yup.string().required(t("validation.required")),
      lead: yup.string().required(t("validation.required")),
      content: yup.string().required(t("validation.required")),
      seo_description: yup.string().nullable(),
      seo_keywords: yup.string().nullable(),
      seo_canonical_url: yup.string().nullable(),
      og_title: yup.string().nullable(),
      og_description: yup.string().nullable(),
      og_image: yup
        .mixed<File>()
        .nullable()
        .test(
          "file-extension",
          t("validation:file_extension", {
            allowedExtensions: "jpg, png, jpeg, svg",
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = ["jpg", "png", "jpeg", "svg"]
            const fileExtension = value.name.split(".").pop()

            return allowedExtensions.includes(fileExtension || "")
          },
        ),
      remove_og_image: yup.boolean().required().default(false),
      published_at: yup
        .string()
        .nullable()
        .when("status", {
          is: (status: ArticleStatusEnum) =>
            status === ArticleStatusEnum.PUBLISHED,
          then: (yup) => yup.required(t("validation.required")),
        }),
      subcategory: yup.string().nullable(),
    })
  }

  defaultValues(): SaveArticleRequestInterface {
    return {
      title: "",
      lead: "",
      content: "",
      status: ArticleStatusEnum.CREATED,
      promoted: false,
      important: false,
      article_of_day: false,
      seo_canonical_url: "",
      seo_description: "",
      seo_keywords: "",
      og_title: "",
      og_description: "",
      published_at: "",
      subcategory: "",
      remove_og_image: false,
    }
  }
}
