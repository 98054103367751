import { TFunction } from "i18next"
import * as yup from "yup"
import { SearchFilterInterface } from "../types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"
import { PostTypeEnum } from "@/features/posts/posts/redux/enums/type"

export default class FilterValidation
  implements ValidationInterface<SearchFilterInterface>
{
  rules(t: TFunction): yup.ObjectSchema<SearchFilterInterface, yup.AnyObject> {
    return yup.object().shape({
      category_id: yup.number().nullable(),
      type: yup.mixed<PostTypeEnum>().nullable(),
      price_from: yup.number().nullable(),
      price_to: yup.number().nullable(),
      published_at_from: yup.string().nullable(),
      published_at_to: yup.string().nullable(),
      id: yup.string().nullable(),
      registered_user: yup.boolean().nullable(),
      email_visible: yup.boolean().nullable(),
      phone_visible: yup.boolean().nullable(),
      is_price_negotiable: yup.boolean().nullable(),
    })
  }

  defaultValues(): SearchFilterInterface {
    return {
      category_id: null,
      type: null,
      price_from: null,
      price_to: null,
      published_at_from: null,
      published_at_to: null,
      id: null,
      registered_user: null,
      email_visible: null,
      phone_visible: null,
      is_price_negotiable: null,
    }
  }
}
