import igritAPI from "@/utils/api/igrit"
import {
  GetSurveyResponseInterface,
  GetSurveysResponseInterface,
  SaveSurveyAnswerRequestInterface,
  SaveSurveyRequestInterface,
  SurveyInterface,
} from "@/features/surveys/redux/types"
import { setPaginatedCache, setSingleObjectCache } from "@/utils/api/rtkHelper"

export const {
  useGetSurveysQuery,
  useGetSurveyQuery,
  useStoreSurveyMutation,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
  useStoreSurveyAnswerMutation,
  useUpdateSurveyAnswerMutation,
  useDeleteSurveyAnswerMutation,
  useLazyGetSurveyQuery,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query<GetSurveysResponseInterface["data"], string>({
      query: (query) => `/admin/surveys?${query}`,
      transformResponse: (response: GetSurveysResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache("Survey", result),
    }),
    getSurvey: builder.query<SurveyInterface, number | string>({
      query: (id) => `/admin/surveys/${id}`,
      transformResponse: (response: GetSurveyResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache("Survey", result),
    }),
    storeSurvey: builder.mutation<void, SaveSurveyRequestInterface>({
      query: (body) => ({
        url: `/admin/surveys`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Survey"],
    }),
    updateSurvey: builder.mutation<
      void,
      { id: number | string; body: SaveSurveyRequestInterface }
    >({
      query: ({ body, id }) => ({
        url: `/admin/surveys/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Survey"],
    }),
    deleteSurvey: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/admin/surveys/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Survey", id: "LIST" }],
    }),
    storeSurveyAnswer: builder.mutation<
      void,
      { surveyId: number; body: SaveSurveyAnswerRequestInterface }
    >({
      query: ({ body, surveyId }) => ({
        url: `/admin/surveys/${surveyId}/answers`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error, { surveyId }) => [
        { type: "Survey", id: surveyId },
      ],
    }),
    updateSurveyAnswer: builder.mutation<
      void,
      {
        id: number | string
        body: SaveSurveyAnswerRequestInterface
        surveyId: number | string
      }
    >({
      query: ({ body, id, surveyId }) => ({
        url: `/admin/surveys/${surveyId}/answers/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { surveyId }) => [
        { type: "Survey", id: surveyId },
      ],
    }),
    deleteSurveyAnswer: builder.mutation<
      void,
      { id: number | string; surveyId: number | string }
    >({
      query: ({ id, surveyId }) => ({
        url: `/admin/surveys/${surveyId}/answers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, { surveyId }) => [
        { type: "Survey", id: surveyId },
      ],
    }),
  }),
})
