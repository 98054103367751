import React from "react"
import { useTranslation } from "react-i18next"
import { Button, ButtonLink } from "@/features/components/buttons/button"
import { blockModal } from "@/features/posts/posts/resources/views/edit/_components/modals/blockModal"
import clsx from "clsx"
import { useSendPostPublishedMailMutation } from "@/features/posts/posts/redux/postAPI"
import { toast } from "react-toastify"

export const ButtonStack: React.FC<{
  blocked: boolean
  id: string | number
  policeReportUrl: string
}> = ({ blocked, id, policeReportUrl }): React.ReactNode => {
  const { t } = useTranslation(["posts/posts"])
  const [sendMail] = useSendPostPublishedMailMutation()

  const handleSendMessage = () => {
    sendMail(id)
      .unwrap()
      .then(() => toast.success(t("posts/posts:edit.send_message.success")))
  }

  const handleBlock = () => {
    void blockModal({
      isBlocked: blocked,
      postId: id,
    })
  }

  return (
    <div className={"flex gap-4 items-center"}>
      <Button
        variant={"contained"}
        className={clsx({
          "bg-red-600": !blocked,
          "bg-green-600": blocked,
        })}
        onClick={handleBlock}
      >
        {t(`posts/posts:edit.button_stack.${blocked ? "unblock" : "block"}`)}
      </Button>
      <ButtonLink
        variant={"contained"}
        className={"bg-red-600"}
        target={"_blank"}
        to={policeReportUrl}
      >
        {t("posts/posts:edit.button_stack.download_report")}
      </ButtonLink>
      <Button variant={"contained"} onClick={handleSendMessage}>
        {t("posts/posts:edit.button_stack.send_message")}
      </Button>
    </div>
  )
}
