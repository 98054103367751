import { PrivateRouterInterface } from "@/utils/routes/privateRoutes/types"
import { DashboardScreen } from "@/features/dashboard/resources/views"
import { PostCategoryListScreen } from "@/features/posts/categories/resources/list"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { PostCategoryCreateScreen } from "@/features/posts/categories/resources/create"
import { PostCategoryEditScreen } from "@/features/posts/categories/resources/edit"
import { CategoryDetailsScreen } from "@/features/posts/categories/resources/details"
import { PostAttributesCreateScreen } from "@/features/posts/attributes/resources/views/create"
import { PostAttributesEditScreen } from "@/features/posts/attributes/resources/views/edit"
import { PostAttributesListScreen } from "@/features/posts/attributes/resources/views/list"
import { PostAttributeCategoryCreateScreen } from "@/features/posts/attributeCategories/resources/views/create"
import { FAQCategoriesCreateScreen } from "@/features/faq/categories/resources/views/create"
import { FAQCategoriesEditScreen } from "@/features/faq/categories/resources/views/edit"
import { FAQCategoriesListScreen } from "@/features/faq/categories/resources/views/list"
import { FAQCategoryDetailsScreen } from "@/features/faq/categories/resources/views/details"
import { FAQCategoryQuestionsCreateScreen } from "@/features/faq/questions/resources/views/create"
import { FAQCategoryQuestionsEditScreen } from "@/features/faq/questions/resources/views/edit"
import { RolesCreateScreen } from "@/features/roles/resources/views/create"
import { RolesEditScreen } from "@/features/roles/resources/views/edit"
import { RolesListScreen } from "@/features/roles/resources/views/list"
import { PostsListScreen } from "@/features/posts/posts/resources/views/list"
import { PostsEditScreen } from "@/features/posts/posts/resources/views/edit"
import { CensorshipListScreen } from "@/features/censorship/resources/views/list"
import { SurveyListScreen } from "@/features/surveys/resources/surveys/views/list"
import { PostAttributeCategoryEditScreen } from "@/features/posts/attributeCategories/resources/views/edit"
import { PostAttributeCategoryListScreen } from "@/features/posts/attributeCategories/resources/views/list"
import { InvoiceListScreen } from "@/features/invoices/resources/views/list"
import { InvoiceDetailsScreen } from "@/features/invoices/resources/views/details"
import { NotificationTemplateListScreen } from "@/features/notificationTemplates/resources/views/list"
import { NotificationTemplateEditScreen } from "@/features/notificationTemplates/resources/views/edit"
import { IssueListScreen } from "@/features/issues/resources/views/list"
import { PageCreateScreen } from "@/features/pages/resources/views/create"
import { PageListScreen } from "@/features/pages/resources/views/list"
import { PageEditScreen } from "@/features/pages/resources/views/edit"
import { AdvertiserListScreen } from "@/features/ads/advertisers/resources/views/list"
import { ServiceListScreen } from "@/features/services/resources/views/list"
import { ServiceEditScreen } from "@/features/services/resources/views/edit"
import { ServiceCreateScreen } from "@/features/services/resources/views/create"
import { ArticleCreateScreen } from "@/features/articles/resources/views/create"
import { PackageListScreen } from "@/features/packages/resources/views/list"
import { PackageEditScreen } from "@/features/packages/resources/views/edit"
import { ArticleCategoryListScreen } from "@/features/articleCategories/resources/views/list"
import { BlackListScreen } from "@/features/blacklist/resources/views/list"
import { ArticleGalleryScreen } from "@/features/articleGalleries/resources/views/list"
import { ArticleMediaGalleryScreen } from "@/features/articleMediaGalleries/resources/views/list"
import { CreateArticleMediaGalleryScreen } from "@/features/articleMediaGalleries/resources/views/create/page"
import { AdsViewsScreen } from "@/features/ads/views/resources/views/list"
import { AdsSlotsScreen } from "@/features/ads/slots/resources/views/list"
import { BundleCategoryListScreen } from "@/features/categoryBundles/resources/views/list"
import { CreateCategoryBundleScreen } from "@/features/categoryBundles/resources/views/create"
import { EditCategoryBundleScreen } from "@/features/categoryBundles/resources/views/edit"

export const routes: PrivateRouterInterface[] = [
  {
    path: "/",
    component: DashboardScreen,
    permissions: [],
  },
  {
    path: "/posts/categories",
    component: PostCategoryListScreen,
    permissions: [PermissionEnum.CATEGORY_SHOW],
  },
  {
    path: "/posts/categories/create",
    component: PostCategoryCreateScreen,
    permissions: [PermissionEnum.CATEGORY_SAVE, PermissionEnum.CATEGORY_SHOW],
  },
  {
    path: "/posts/categories/:id/edit",
    component: PostCategoryEditScreen,
    permissions: [PermissionEnum.CATEGORY_SAVE, PermissionEnum.CATEGORY_SHOW],
  },
  {
    path: "/posts/categories/:id",
    component: CategoryDetailsScreen,
    permissions: [PermissionEnum.CATEGORY_SHOW],
  },
  {
    path: "/posts/attributes/create",
    component: PostAttributesCreateScreen,
    permissions: [PermissionEnum.ATTRIBUTE_SAVE, PermissionEnum.ATTRIBUTE_SHOW],
  },
  {
    path: "/posts/attributes/:id/edit",
    component: PostAttributesEditScreen,
    permissions: [PermissionEnum.ATTRIBUTE_SAVE, PermissionEnum.ATTRIBUTE_SHOW],
  },
  {
    path: "/posts/attributes",
    component: PostAttributesListScreen,
    permissions: [PermissionEnum.ATTRIBUTE_SHOW],
  },
  {
    path: "/posts/attribute-categories/create",
    component: PostAttributeCategoryCreateScreen,
    permissions: [],
  },
  {
    path: "/posts/attribute-categories/:id/edit",
    component: PostAttributeCategoryEditScreen,
    permissions: [],
  },
  {
    path: "/posts/attribute-categories",
    component: PostAttributeCategoryListScreen,
    permissions: [],
  },
  {
    path: "/faq/categories/create",
    component: FAQCategoriesCreateScreen,
    permissions: [PermissionEnum.FAQ_SHOW, PermissionEnum.FAQ_SAVE],
  },
  {
    path: "/faq/categories/:id/edit",
    component: FAQCategoriesEditScreen,
    permissions: [PermissionEnum.FAQ_SHOW, PermissionEnum.FAQ_SAVE],
  },
  {
    path: "/faq/categories",
    component: FAQCategoriesListScreen,
    permissions: [PermissionEnum.FAQ_SHOW],
  },
  {
    path: "/faq/categories/:id",
    component: FAQCategoryDetailsScreen,
    permissions: [PermissionEnum.FAQ_SHOW],
  },
  {
    path: "/faq/categories/:category_id/questions/create",
    component: FAQCategoryQuestionsCreateScreen,
    permissions: [],
  },
  {
    path: "/faq/categories/:category_id/questions/:id/edit",
    component: FAQCategoryQuestionsEditScreen,
    permissions: [PermissionEnum.FAQ_SHOW, PermissionEnum.FAQ_SAVE],
  },
  {
    path: "/roles/create",
    component: RolesCreateScreen,
    permissions: [PermissionEnum.ROLE_SHOW, PermissionEnum.ROLE_SAVE],
  },
  {
    path: "/roles/:id/edit",
    component: RolesEditScreen,
    permissions: [PermissionEnum.ROLE_SHOW, PermissionEnum.ROLE_SAVE],
  },
  {
    path: "/roles",
    component: RolesListScreen,
    permissions: [PermissionEnum.ROLE_SHOW],
  },
  {
    path: "/posts",
    component: PostsListScreen,
    permissions: [],
  },
  {
    path: "/posts/:id/edit",
    component: PostsEditScreen,
    permissions: [],
  },
  {
    path: "/censorship",
    component: CensorshipListScreen,
    permissions: [PermissionEnum.CENSORSHIP_SHOW],
  },
  {
    path: "/surveys",
    component: SurveyListScreen,
    permissions: [PermissionEnum.SURVEY_SHOW],
  },
  {
    path: "/invoices",
    component: InvoiceListScreen,
    permissions: [PermissionEnum.INVOICE_SHOW],
  },
  {
    path: "/invoices/:id",
    component: InvoiceDetailsScreen,
    permissions: [PermissionEnum.INVOICE_SHOW],
  },
  {
    path: "/notification-templates",
    component: NotificationTemplateListScreen,
    permissions: [],
  },
  {
    path: "/notification-templates/:id/edit",
    component: NotificationTemplateEditScreen,
    permissions: [],
  },
  {
    path: "/issues",
    component: IssueListScreen,
    permissions: [PermissionEnum.ISSUE_SHOW],
  },
  {
    path: "/pages/create",
    component: PageCreateScreen,
    permissions: [PermissionEnum.PAGE_SAVE, PermissionEnum.PAGE_SHOW],
  },
  {
    path: "/pages",
    component: PageListScreen,
    permissions: [PermissionEnum.PAGE_SHOW],
  },
  {
    path: "/pages/:id/edit",
    component: PageEditScreen,
    permissions: [PermissionEnum.PAGE_SAVE, PermissionEnum.PAGE_SHOW],
  },
  {
    path: "/advertisers",
    component: AdvertiserListScreen,
    permissions: [PermissionEnum.ADVERTISER_SHOW],
  },
  {
    path: "/services",
    component: ServiceListScreen,
    permissions: [PermissionEnum.SERVICE_SHOW],
  },
  {
    path: "/services/:id/edit",
    component: ServiceEditScreen,
    permissions: [PermissionEnum.SERVICE_SAVE, PermissionEnum.SERVICE_SHOW],
  },
  {
    path: "/services/create",
    component: ServiceCreateScreen,
    permissions: [PermissionEnum.SERVICE_SAVE, PermissionEnum.SERVICE_SHOW],
  },
  {
    path: "/articles/create",
    component: ArticleCreateScreen,
    permissions: [],
  },
  {
    path: "/packages",
    component: PackageListScreen,
    permissions: [PermissionEnum.PACKAGE_SHOW],
  },
  {
    path: "/packages/:id/edit",
    component: PackageEditScreen,
    permissions: [PermissionEnum.PACKAGE_SAVE, PermissionEnum.PACKAGE_SHOW],
  },
  {
    path: "/article-categories",
    component: ArticleCategoryListScreen,
    permissions: [
      PermissionEnum.ARTICLE_CATEGORY_SHOW,
      PermissionEnum.ARTICLE_CATEGORY_SAVE,
      PermissionEnum.ARTICLE_CATEGORY_DESTROY,
    ],
  },
  {
    path: "/blacklist",
    component: BlackListScreen,
    permissions: [PermissionEnum.BLACKLIST_SHOW],
  },
  {
    path: "/article-gallery",
    component: ArticleGalleryScreen,
    permissions: [PermissionEnum.ARTICLE_GALLERY_SHOW],
  },
  {
    path: "/article-gallery/:id/article-media",
    component: ArticleMediaGalleryScreen,
    permissions: [PermissionEnum.ARTICLE_GALLERY_SHOW],
  },
  {
    path: "/article-gallery/:id/article-media/create",
    component: CreateArticleMediaGalleryScreen,
    permissions: [PermissionEnum.ARTICLE_GALLERY_SHOW],
  },
  {
    path: "/ads/views",
    component: AdsViewsScreen,
    permissions: [PermissionEnum.VIEW_SHOW],
  },
  {
    path: "/ads/slots",
    component: AdsSlotsScreen,
    permissions: [PermissionEnum.SLOT_SHOW],
  },
  {
    path: "/bundle-categories",
    component: BundleCategoryListScreen,
    permissions: [PermissionEnum.CATEGORY_BUNDLE_SHOW],
  },
  {
    path: "/bundle-categories/create",
    component: CreateCategoryBundleScreen,
    permissions: [
      PermissionEnum.CATEGORY_BUNDLE_SAVE,
      PermissionEnum.CATEGORY_BUNDLE_SHOW,
    ],
  },
  {
    path: "/bundle-categories/:id/edit",
    component: EditCategoryBundleScreen,
    permissions: [
      PermissionEnum.CATEGORY_BUNDLE_SAVE,
      PermissionEnum.CATEGORY_BUNDLE_SHOW,
    ],
  },
]
