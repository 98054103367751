import { flexRender, Table as TableType } from "@tanstack/react-table"
import { Pagination } from "./pagination"
import { PaginationInterface } from "@/app/types"
import React from "react"
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowDownWideShort,
  faArrowUpWideShort,
} from "@fortawesome/free-solid-svg-icons"

type Props<T> = {
  table: TableType<T>
  pagination?: PaginationInterface
}

const BaseTable = <T extends {}>({ table, pagination }: Props<T>) => {
  return (
    <div className={"overflow-auto w-full"}>
      <table className={"table-auto w-full text-left"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={"border-b border-b-neutral-600"}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={clsx(
                      "bg-neutral-100 px-[12px] py-[6px] text-xs font-[500] text-gray-50",
                    )}
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                    }}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={clsx({
                          "cursor-pointer flex gap-x-2 items-center select-none":
                            header.column.getCanSort(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <FontAwesomeIcon icon={faArrowUpWideShort} />,
                          desc: <FontAwesomeIcon icon={faArrowDownWideShort} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className={"text-xs font-medium text-gray-700"}>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className={"border-b border-b-neutral-600 h-full"}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={clsx(
                        "px-[12px] py-[6px] text-[12px] text-gray-700",
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {pagination && (
        <div className={"px-8 py-6"}>
          <Pagination
            onPageChange={table.setPageIndex}
            totalPages={pagination.total_pages}
          />
        </div>
      )}
    </div>
  )
}

export default BaseTable
