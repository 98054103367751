import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { useNavigate } from "react-router-dom"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import {
  useDeleteRoleMutation,
  useGetRolesQuery,
} from "@/features/roles/redux/roleAPI"
import { RoleInterface } from "@/features/roles/redux/types"

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(["form", "roles"])
  const navigate = useNavigate()
  const { data: apiData = [] } = useGetRolesQuery()
  const [data, setData] = useState<RoleInterface[]>([])
  const [deleteRole] = useDeleteRoleMutation()

  useEffect(() => {
    if (apiData) setData(apiData)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteRole(id)
      .unwrap()
      .then(() => toast.success(t("roles:list.deleted")))
  }

  const table = useReactTable({
    columns: columns(t, onDelete, (id) => navigate(`/roles/${id}/edit`)),
    getCoreRowModel: getCoreRowModel(),
    data,
  })

  return <BaseTable table={table} />
}

const columnBuilder = createColumnHelper<RoleInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("name", {
    id: "type",
    header: t("form:labels.name"),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.ROLE_DESTROY}
        editPermission={PermissionEnum.ROLE_SAVE}
        onDeleteClick={
          row.original.is_deleteable
            ? () => onDelete(row.original.id)
            : undefined
        }
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
