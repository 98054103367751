import React, { useState } from "react"
import Select, { Option } from "@/features/components/inputs/select"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { useTranslation } from "react-i18next"
import { Button } from "@/features/components/buttons/button"
import { AllowedMassActionType } from "@/features/posts/posts/redux/types"
import { confirmModal } from "@/features/components/modals/confirm"

type Props = {
  onConfirm: (type: AllowedMassActionType) => void
}

export const MassAction: React.FC<Props> = ({ onConfirm }): React.ReactNode => {
  const [action, setSelectAction] = useState<AllowedMassActionType>()
  const { hasPermission } = usePermissions()
  const { t } = useTranslation(["posts/posts"])

  const handleClick = () => {
    switch (action) {
      case "delete":
        confirmModal({
          message: t("posts/posts:mass_actions.delete_confirm"),
          type: "warning",
        }).then(() => onConfirm(action))
        break
      case "enable_price_negotiations":
        confirmModal({
          message: t("posts/posts:mass_actions.enable_price_negotiations"),
          type: "warning",
        }).then(() => onConfirm(action))
        break
      case "block":
        confirmModal({
          message: t("posts/posts:mass_actions.block_confirm"),
          type: "warning",
        }).then(() => onConfirm(action))
    }
  }

  return (
    <div className={"flex gap-2 flex-col lg:flex-row items-center"}>
      <div className={"w-full"}>
        <Select
          buttonClassNames={"bg-white"}
          placeholder={t("posts/posts:mass_actions.select_action")}
          value={action}
          onChange={(_, value) =>
            setSelectAction(value as AllowedMassActionType)
          }
        >
          {hasPermission(PermissionEnum.POST_DESTROY) && (
            <Option value={"delete"}>
              {t("posts/posts:mass_actions.delete")}
            </Option>
          )}
          {hasPermission(PermissionEnum.POST_SAVE) && (
            <Option value={"enable_price_negotiations"}>
              {t("posts/posts:mass_actions.set_negotianable")}
            </Option>
          )}
          {hasPermission(PermissionEnum.POST_SAVE) && (
            <Option value={"block"}>
              {t("posts/posts:mass_actions.block")}
            </Option>
          )}
        </Select>
      </div>
      <div className={"flex w-full"}>
        <Button size={"small"} variant={"contained"} onClick={handleClick}>
          {t("posts/posts:mass_actions.apply")}
        </Button>
      </div>
    </div>
  )
}
