import { Select2OptionInterface } from "@/app/types"
import { Query } from "@/utils/query"
import _, { isArray } from "lodash"
import React from "react"
import { MultiValue, SingleValue } from "react-select"
import AsyncSelect from "./asyncSelect"
import { useLazySearchAttributeQuery } from "@/features/posts/attributes/redux/attributeAPI"

type Props<TMultiple = boolean> = TMultiple extends true
  ? {
      defaultValue?: Select2OptionInterface[] | null
      onChange: (value: MultiValue<Select2OptionInterface>) => void
      isMulti: TMultiple
    }
  : {
      defaultValue?: Select2OptionInterface | null
      onChange: (value: SingleValue<Select2OptionInterface>) => void
      isMulti: TMultiple
    }

type SelectProps = Props & {
  query?: Query
}

export const SearchAttribute: React.FC<SelectProps> = ({
  defaultValue,
  onChange,
  isMulti,
  query: _query,
}): React.ReactNode => {
  const [searchAttribute] = useLazySearchAttributeQuery()

  const _handleSearchAttribute = (
    value: string,
    callback: (options: Select2OptionInterface[]) => void,
  ) => {
    let query = new Query()

    if (!_query) {
      query.where("name", value)

      if (defaultValue && !isArray(defaultValue))
        query.whereNotIn([defaultValue.value])
    } else {
      query = _query
    }

    searchAttribute(query.url())
      .unwrap()
      .then((response) =>
        callback(
          response.map((attribute) => ({
            label: attribute.name,
            value: attribute.id.toString(),
          })),
        ),
      )
  }

  const handleSearchAttribute = _.debounce(_handleSearchAttribute, 500)

  return (
    <AsyncSelect
      isMulti={isMulti}
      cacheOptions
      // @ts-ignore
      onChange={onChange}
      value={defaultValue}
      loadOptions={handleSearchAttribute}
    />
  )
}
