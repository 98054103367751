import React, { useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { ElasticSearchResponseInterface } from "@/app/types"
import clsx from "clsx"

type Props = {
  total: ElasticSearchResponseInterface<any>["hits"]["total"]
  onPrevClick: () => void
  onNextClick: () => void
  from: number
  size: number
}

export const SimplePagination: React.FC<Props> = ({
  total,
  onNextClick,
  onPrevClick,
  from,
  size,
}): React.ReactNode => {
  const showPrevButton = useMemo(
    () => from > 0 && total.value > 0,
    [from, size, total],
  )
  const showNextButton = useMemo(
    () => from + size < total.value,
    [from, size, total],
  )

  return (
    <>
      <div className={"flex flex-col"}>
        <button
          onClick={onPrevClick}
          disabled={!showPrevButton}
          className={clsx(
            "p-[10px] cursor-pointer border border-neutral-600 rounded-3xl flex items-center text-neutral-950 justify-center",
            {
              "cursor-not-allowed": !showPrevButton,
              "cursor-pointer ": showPrevButton,
            },
          )}
          style={{
            backgroundColor: !showPrevButton ? "#f3f3f3" : "white",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>

      <span
        className={
          "p-[10px] border border-neutral-600 rounded-3xl flex items-center text-neutral-950 justify-center"
        }
      >
        {from} / {total.value}
      </span>
      <div className={"flex flex-col"}>
        <button
          onClick={onNextClick}
          disabled={!showNextButton}
          className={clsx(
            "p-[10px] border border-neutral-600 rounded-3xl flex items-center text-neutral-950 justify-center",
            {
              "cursor-not-allowed": !showNextButton,
              "cursor-pointer ": showNextButton,
            },
          )}
          style={{
            backgroundColor: !showNextButton ? "#f3f3f3" : "white",
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </>
  )
}
