import React from "react"
import { useTranslation } from "react-i18next"
import { TagInterface } from "@/features/notificationTemplates/redux/types"

type Props = {
  onSelectTag: (tag: TagInterface) => void
  tags: TagInterface[]
}

export const SelectTag: React.FC<Props> = ({
  onSelectTag,
  tags,
}): React.ReactNode => {
  const { t } = useTranslation(["utils"])

  return (
    <div
      className={
        "flex flex-col bg-[#fafafb] p-[19px] border border-[#d1d5dc] rounded max-h-[684px] min-w-[150px] overflow-y-auto"
      }
    >
      <span className={"text-xs font-medium"}>
        {t("utils:select_tag:title")}
      </span>
      <div className={"pt-4 flex flex-col gap-y-1 text-xs"}>
        {tags.map((tag, index) => (
          <button
            className={"flex font-semibold"}
            onClick={() => onSelectTag(tag)}
            key={index}
          >
            {tag.name}
          </button>
        ))}
      </div>
    </div>
  )
}
