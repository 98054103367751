import React, { useEffect } from "react"
import { FormInterface } from "@/app/types"
import {
  PostUserInterface,
  UpdatePostUserRequestInterface,
} from "@/features/posts/posts/redux/types"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import UpdatePostUserValidation from "./validations/updatePostUserValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Card from "@/features/components/cards"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Button } from "@/features/components/buttons/button"

export const Form: React.FC<
  FormInterface<UpdatePostUserRequestInterface, PostUserInterface>
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "validation"])

  const { schema, defaultValues } = useValidation(
    new UpdatePostUserValidation(),
    t,
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdatePostUserRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      setValue("name", data.name)
      setValue("email", data.email)
      setValue("contact_email", data.contact_email)
      setValue("description", data.description)

      if (data.phone_number) {
        setValue("phone.number", data.phone_number.phone_number)
        setValue("phone.prefix", data.phone_number.prefix)
      }
    }
  }, [data])

  return (
    <Card>
      <div className={"flex flex-col gap-y-4"}>
        <span className={"font-medium"}>
          {t("posts/posts:edit.users.edit.title")}
        </span>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"grid grid-cols-3 gap-4"}
        >
          <Controller
            render={({ field }) => (
              <FormControl {...field} error={!!errors[field.name]}>
                <Input label={t("form:labels.name")} />
                <FormHelperText message={errors[field.name]?.message} />
              </FormControl>
            )}
            name={"name"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl {...field} error={!!errors[field.name]}>
                <Input label={t("form:labels.email")} />
                <FormHelperText message={errors[field.name]?.message} />
              </FormControl>
            )}
            name={"email"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl {...field} error={!!errors[field.name]}>
                <Input label={t("form:labels.contact_email")} />
                <FormHelperText message={errors[field.name]?.message} />
              </FormControl>
            )}
            name={"contact_email"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl {...field} error={!!errors.phone?.prefix}>
                <Input label={t("form:labels.phone_prefix")} />
                <FormHelperText message={errors.phone?.prefix?.message} />
              </FormControl>
            )}
            name={"phone.prefix"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl {...field} error={!!errors.phone?.number}>
                <Input label={t("form:labels.phone_number")} />
                <FormHelperText message={errors.phone?.number?.message} />
              </FormControl>
            )}
            name={"phone.number"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl
                {...field}
                className={"col-span-3"}
                error={!!errors.description}
              >
                <Input
                  multiline
                  rows={5}
                  label={t("form:labels.description")}
                />
                <FormHelperText message={errors.description?.message} />
              </FormControl>
            )}
            name={"description"}
            control={control}
          />
          <div className={"flex pt-4"}>
            <Button variant={"contained"} type={"submit"}>
              {t("form:buttons.save")}
            </Button>
          </div>
        </form>
      </div>
    </Card>
  )
}
