import { SearchFilterInterface } from "./types"
import { Query } from "@/utils/query"

export const buildQuery = (
  searchFilter: SearchFilterInterface | undefined,
): Query => {
  const query = new Query()

  if (searchFilter?.amount_from) {
    query.where("amount_from", searchFilter.amount_from)
  }

  if (searchFilter?.amount_to) {
    query.where("amount_to", searchFilter.amount_to)
  }

  if (searchFilter?.date_from) {
    query.where("date_from", searchFilter.date_from)
  }

  if (searchFilter?.date_to) {
    query.where("date_to", searchFilter.date_to)
  }

  if (searchFilter?.type) {
    query.where("type", searchFilter.type)
  }

  return query
}
