import igritAPI from "@/utils/api/igrit"
import {
  ArticleMediaInterface,
  GetArticleMediaGalleryInterface,
  UpdateArticleMediaGalleryInterface,
} from "@/features/articleMediaGalleries/redux/types"
import { setArrayCache } from "@/utils/api/rtkHelper"

export const {
  useDeleteArticleMediaGalleryMutation,
  useGetArticleMediaGalleryQuery,
  useStoreArticleMediaGalleryMutation,
  useUpdateArticleMediaGalleryMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getArticleMediaGallery: build.query<
      ArticleMediaInterface[],
      string | number
    >({
      query: (id) => `/admin/article-gallery/${id}/article-media`,
      transformResponse: (response: GetArticleMediaGalleryInterface) =>
        response.data,
      providesTags: (result) => setArrayCache("ArticleMediaGallery", result),
    }),
    storeArticleMediaGallery: build.mutation<
      void,
      { galleryId: number | string; data: FormData }
    >({
      query: ({ data, galleryId }) => ({
        url: `/admin/article-gallery/${galleryId}/article-media`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["ArticleMediaGallery"],
    }),
    updateArticleMediaGallery: build.mutation<
      void,
      {
        articleGalleryId: number | string
        articleMediaId: number | string
        data: UpdateArticleMediaGalleryInterface
      }
    >({
      query: ({ articleGalleryId, articleMediaId, data }) => ({
        url: `/admin/article-gallery/${articleGalleryId}/article-media/${articleMediaId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ArticleMediaGallery"],
    }),
    deleteArticleMediaGallery: build.mutation<
      void,
      {
        articleGalleryId: number | string
        articleMediaId: number | string
      }
    >({
      query: ({ articleGalleryId, articleMediaId }) => ({
        url: `admin/article-gallery/${articleGalleryId}/article-media/${articleMediaId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ArticleMediaGallery"],
    }),
  }),
})
