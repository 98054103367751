import { create, InstanceProps } from "react-modal-promise"
import React, { memo } from "react"
import { useTranslation } from "react-i18next"
import Modal from "./modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { Button } from "@/features/components/buttons/button"

type Type = "success" | "error" | "warning" | "info"

type Props = {
  message: string
  type: Type
} & InstanceProps<unknown>

const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onReject,
  onResolve,
  type,
  message,
}): React.ReactNode => {
  const { t } = useTranslation(["utils", "form"])

  return (
    <Modal open={isOpen} onClose={() => onReject()}>
      <Modal.Content>
        <div className={"flex flex-col gap-y-8"}>
          <div className={"flex w-full items-center justify-between"}>
            <span className={"text-lg font-semibold"}>
              {t("utils:confirm.title")}
            </span>
            <FontAwesomeIcon
              onClick={() => onReject()}
              icon={faTimes}
              className={"cursor-pointer"}
            />
          </div>
          <Icon type={type} />
          <p className={"text-center"}>{message}</p>
          <div className={"flex gap-x-4"}>
            <Button
              variant={"contained"}
              className={"w-full bg-green-600"}
              size={"small"}
              onClick={() => onResolve(true)}
            >
              {t("form:buttons.confirm")}
            </Button>
            <Button
              variant={"contained"}
              className={"w-full bg-red-600"}
              size={"small"}
              onClick={() => onReject()}
            >
              {t("form:buttons.cancel")}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const Icon = memo(function Icon({ type }: { type: Type }) {
  switch (type) {
    case "success":
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size={"3x"}
          className={"text-green-600"}
        />
      )
    case "error":
      return (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          size={"3x"}
          className={"text-red-600"}
        />
      )
    case "warning":
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={"3x"}
          className={"text-yellow-600"}
        />
      )
    case "info":
      return (
        <FontAwesomeIcon
          icon={faInfoCircle}
          size={"3x"}
          className={"text-blue-600"}
        />
      )
    default:
      return <></>
  }
})

export const confirmModal = create(ConfirmModal)
