import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { Button } from "@/features/components/buttons/button"
import Card from "@/features/components/cards"
import useValidation from "@/utils/hooks/useValidation"
import FilterValidation from "./validations/filterValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import _ from "lodash"
import { SearchFilterInterface } from "./types"
import { Label } from "@/features/components/inputs/label"
import AsyncSelect from "@/features/components/inputs/asyncSelect/asyncSelect"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/features/components/inputs/select"
import { Input } from "@/features/components/inputs/input"
import { Select2OptionInterface } from "@/app/types"

type Props = {
  onSubmit: (data: SearchFilterInterface) => void
}

export const InvoiceFilter: React.FC<Props> = ({
  onSubmit,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "validation"])
  const [show, setShow] = useState<boolean>(false)
  const { schema, defaultValues } = useValidation(new FilterValidation(), t)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SearchFilterInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const _handleSearchUsers = (
    value: string,
    response: (options: Select2OptionInterface[]) => void,
  ) => {}

  const handleSearchUsers = _.debounce(_handleSearchUsers, 500)

  return (
    <div>
      <Card
        className={clsx(
          "!mb-0 relative flex duration-300 ease-in-out transition-all",
          show ? "h-[350px] mb-2" : "h-[120px]",
        )}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"flex flex-col gap-y-4 w-full overflow-hidden flex-grow-0"}
        >
          <div className={"grid grid-cols-4 gap-4 p-1"}>
            <Controller
              control={control}
              name={"user_id"}
              render={({ field }) => (
                <div className={"flex flex-col"}>
                  <Label label={t("form:labels.user")} />
                  <AsyncSelect
                    cacheOptions
                    onChange={(value) => field.onChange(value?.value)}
                    loadOptions={handleSearchUsers}
                  />
                </div>
              )}
            />
            <Controller
              render={({ field }) => (
                <FormControl>
                  <Select
                    label={t("form:labels.type")}
                    placeholder={t("form:labels.select_type")}
                    onChange={(_, value) => field.onChange(value)}
                    value={field.value}
                  >
                    {_.map(["instant", "collective"], (value, key) => (
                      <Option value={value} key={key}>
                        {t(`utils:invoice_types.${value}`)}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={"type"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"number"} label={t("form:labels.amount_from")} />
                </FormControl>
              )}
              name={"amount_from"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"number"} label={t("form:labels.amount_to")} />
                </FormControl>
              )}
              name={"amount_to"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"date"} label={t("form:labels.date_from")} />
                </FormControl>
              )}
              name={"date_from"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field}>
                  <Input type={"date"} label={t("form:labels.date_to")} />
                </FormControl>
              )}
              name={"date_to"}
              control={control}
            />
          </div>
          <div className={"flex pt-4 gap-x-2 items-center"}>
            <Button size={"small"} type={"submit"} variant={"contained"}>
              {t("form:buttons.search")}
            </Button>
            <Button
              size={"small"}
              className={"bg-gray-500"}
              variant={"contained"}
              type={"button"}
              onClick={() => reset(defaultValues)}
            >
              {t("form:buttons.clear")}
            </Button>
          </div>
        </form>
        <button
          onClick={() => setShow(!show)}
          className={
            "absolute z-[999999999] -bottom-2 -right-3 flex items-center justify-center rounded-full w-8 h-8 bg-blue-500"
          }
        >
          <FontAwesomeIcon
            icon={show ? faChevronUp : faChevronDown}
            className={"text-white"}
          />
        </button>
      </Card>
    </div>
  )
}
