import { AuthSliceInterface } from "./types"
import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { RootState } from "@/app/store"
import {
  loginMutation,
  logoutRequestMutation,
} from "@/features/auth/redux/authAPI"
import { PermissionEnum } from "@/features/permissions/redux/types"

const initialState: AuthSliceInterface = {
  token: null,
  authenticated: false,
  permissions: [],
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null
      state.authenticated = false
    },
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload
      state.authenticated = true
    },
    setPermissions: (state, action: PayloadAction<PermissionEnum[]>) => {
      state.permissions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginMutation.fulfilled, (state, action) => {
      state.token = action.payload.data.access_token
      state.authenticated = true
    })
    builder.addCase(logoutRequestMutation.pending, () => initialState)
  },
})

export default authSlice.reducer
export const { logout, login, setPermissions } = authSlice.actions

export const getIsAuthenticated = createDraftSafeSelector(
  (state: RootState) => state.auth,
  (auth) => auth.authenticated,
)
