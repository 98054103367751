import igritAPI from "@/utils/api/igrit"
import {
  AttributeCategoryInterface,
  GetAttributeCategoryListResponseInterface,
  GetAttributeCategoryResponseInterface,
  SearchAttributeCategoriesResponseInterface,
} from "@/features/posts/attributeCategories/redux/types"
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from "@/utils/api/rtkHelper"

export const {
  useLazySearchAttributeCategoriesQuery,
  useStoreAttributeCategoryMutation,
  useLazyGetAttributeCategoryQuery,
  useGetAttributeCategoryQuery,
  useUpdateAttributeCategoryMutation,
  useDeleteAttributeCategoryMutation,
  useGetAttributeCategoryListQuery,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    searchAttributeCategories: builder.query<
      SearchAttributeCategoriesResponseInterface["data"],
      string
    >({
      query: (query) => `/admin/attribute-categories/search?${query}`,
      transformResponse: (
        response: SearchAttributeCategoriesResponseInterface,
      ) => response.data,
      providesTags: (result) => setArrayCache("AttributeCategory", result),
    }),
    storeAttributeCategory: builder.mutation<void, FormData>({
      query: (body) => ({
        url: "/admin/attribute-categories",
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [{ type: "AttributeCategory", id: "LIST" }],
    }),
    getAttributeCategory: builder.query<
      AttributeCategoryInterface,
      string | number
    >({
      query: (id) => `/admin/attribute-categories/${id}`,
      transformResponse: (response: GetAttributeCategoryResponseInterface) =>
        response.data,
      providesTags: (result) =>
        setSingleObjectCache("AttributeCategory", result),
    }),
    updateAttributeCategory: builder.mutation<
      void,
      { id: string | number; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/admin/attribute-categories/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: "AttributeCategory", id },
      ],
    }),
    deleteAttributeCategory: builder.mutation<
      void,
      { id: number | string; delete_value: boolean }
    >({
      query: ({ id, delete_value }) => ({
        url: `/admin/attribute-categories/${id}`,
        body: {
          delete_value,
        },
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "AttributeCategory", id: "LIST" }],
    }),
    getAttributeCategoryList: builder.query<
      GetAttributeCategoryListResponseInterface["data"],
      string
    >({
      query: (query) => `/admin/attribute-categories?${query}`,
      transformResponse: (
        response: GetAttributeCategoryListResponseInterface,
      ) => response.data,
      providesTags: (result) => setPaginatedCache("AttributeCategory", result),
    }),
  }),
})
