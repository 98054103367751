import { PaginatedResponseInterface, ResponseCodeEnum } from "@/app/types"
import { TagTypes } from "@/utils/api/igrit"

export const setPaginatedCache = <T extends { id: number | string } & object>(
  tag: TagTypes,
  data: PaginatedResponseInterface<T> | undefined,
) => {
  return data
    ? [
        ...data.data.map(({ id }) => ({ type: tag, id } as const)),
        { type: tag, id: "LIST" },
      ]
    : [{ type: tag, id: "LIST" as const }]
}

export const setArrayCache = <T extends { id: number | string } & object>(
  tag: TagTypes,
  data: T[] | undefined,
) => {
  return data
    ? [
        ...data.map(({ id }) => ({ type: tag, id } as const)),
        { type: tag, id: "LIST" },
      ]
    : [{ type: tag, id: "LIST" as const }]
}

export const setSingleObjectCache = <
  T extends { id: number | string } & object,
>(
  tag: TagTypes,
  data: T | undefined,
) => {
  return [{ type: tag, id: data?.id }]
}

export const isCustomError = (error: any, code: ResponseCodeEnum) =>
  error && error.status === 400 && error.data.code === code
