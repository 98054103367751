import React from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import { ArticleMediaInterface } from "@/features/articleMediaGalleries/redux/types"
import {
  useDeleteArticleMediaGalleryMutation,
  useGetArticleMediaGalleryQuery,
  useUpdateArticleMediaGalleryMutation,
} from "@/features/articleMediaGalleries/redux/articleMediaGalleryAPI"
import { editArticleMediaGalleryModal } from "@/features/articleMediaGalleries/resources/views/edit"

type Props = {
  articleGalleryId: string
}
export const Table: React.FC<Props> = ({
  articleGalleryId,
}): React.ReactNode => {
  const { t } = useTranslation(["form", "article_media_gallery", "utils"])
  const { data = [] } = useGetArticleMediaGalleryQuery(articleGalleryId)

  const [deleteArticleMediaGallery] = useDeleteArticleMediaGalleryMutation()
  const [updateArticleMediaGallery] = useUpdateArticleMediaGalleryMutation()

  const onDelete = (articleMediaId: number) => {
    deleteArticleMediaGallery({ articleGalleryId, articleMediaId })
      .unwrap()
      .then(() => toast.success(t("article_media_gallery:list.deleted")))
  }

  const onEdit = (articleMediaId: number) => {
    const row = data.find((item) => item.id === articleMediaId)

    editArticleMediaGalleryModal({
      title: t("article_media_gallery:edit.title"),
      data: row,
    }).then((data) =>
      updateArticleMediaGallery({ data, articleGalleryId, articleMediaId })
        .unwrap()
        .then(() => toast.success(t("article_media_gallery:edit.success"))),
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
  })

  return <BaseTable table={table} />
}

const columnBuilder = createColumnHelper<ArticleMediaInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("order", {
    id: "order",
    header: t("form:labels.order"),
  }),
  columnBuilder.display({
    id: "image",
    header: t("form:labels.image"),
    cell: ({ row }) => {
      const defaultPhoto = row.original.article_media_files.find(
        (article) => article.collection_name === "default",
      )?.url
      return <img src={defaultPhoto} alt={""} className={"max-w-[100px]"} />
    },
  }),
  columnBuilder.accessor("title", {
    id: "title",
    header: t("form:labels.title"),
    cell: ({ row }) => t(`${row.original.title}`),
  }),
  columnBuilder.accessor("description", {
    id: "description",
    header: t("form:labels.description"),
  }),
  columnBuilder.accessor("watermark", {
    id: "watermark",
    header: t("form:labels.watermark"),
    cell: ({ row }) =>
      row.original.watermark ? t("form:labels.yes") : t("form:labels.no"),
  }),

  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.ARTICLE_GALLERY_DESTROY}
        editPermission={PermissionEnum.ARTICLE_GALLERY_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
