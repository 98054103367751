import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit"
import igritAPI from "@/utils/api/igrit"
import nominatimAPI from "@/utils/api/nominatim"
import elasticSearchAPI from "@/utils/api/elasticSearch"

const UnauthorizedMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        api.dispatch({ type: "auth/logout" })
        api.dispatch(igritAPI.util?.resetApiState())
        api.dispatch(nominatimAPI.util?.resetApiState())
        api.dispatch(elasticSearchAPI.util?.resetApiState())
      }
    }

    return next(action)
  }

export { UnauthorizedMiddleware }
