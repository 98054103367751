import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import type { AppDispatch, RootState } from "./store"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const isFetchBaseQueryError = (
  error: any,
): error is FetchBaseQueryError => {
  return error.status !== undefined && error.data !== undefined
}
