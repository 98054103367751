import React, { useEffect, useState } from "react"
import { FormInterface, Select2OptionInterface } from "@/app/types"
import {
  FAQCategoryQuestionInterface,
  SaveFAQCategoryQuestionRequestInterface,
} from "@/features/faq/questions/redux/types"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveCategoryQuestionValidation from "@/features/faq/questions/resources/_components/form/validations/saveCategoryQuestionValidation"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { useLazySearchFAQCategoryQuestionsQuery } from "@/features/faq/questions/redux/faqCategoryQuestionAPI"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Label } from "@/features/components/inputs/label"
import AsyncSelect from "@/features/components/inputs/asyncSelect/asyncSelect"
import { Query } from "@/utils/query"
import _ from "lodash"
import { MultiValue } from "react-select"
import { Button } from "@/features/components/buttons/button"
import "ckeditor5-custom-build/build/ckeditor"
import { Editor } from "@/features/components/inputs/editor"
import { GalleryEnum } from "@/app/enums/galleryEnum"

export const Form: React.FC<
  FormInterface<
    SaveFAQCategoryQuestionRequestInterface,
    FAQCategoryQuestionInterface
  >
> = ({ data, onSubmit, params }): React.ReactNode => {
  const { t } = useTranslation(["form", "validation", "utils"])
  const { schema, defaultValues } = useValidation(
    new SaveCategoryQuestionValidation(),
    t,
  )
  const [searchCategoryQuestions] = useLazySearchFAQCategoryQuestionsQuery()
  const [selectedAdditionalQuestions, setSelectedAdditionalQuestions] =
    useState<MultiValue<Select2OptionInterface>>()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SaveFAQCategoryQuestionRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (params && Object.hasOwn(params, "category_id") && !data) {
      setValue("category_id", params.category_id)
    }
  }, [data, params, setValue])

  useEffect(() => {
    if (data) {
      setValue("question", data.question)
      setValue("answer", data.answer)
      setValue("category_id", data.category_id)
      setValue("related_faq_entry_ids", data.related_faq_entry_ids)

      if (data.related_faq_entries) {
        setSelectedAdditionalQuestions(
          data.related_faq_entries.map((question) => ({
            label: question.question,
            value: question.id.toString(),
          })),
        )
      }
    }
  }, [data])

  const _handleSearchQuestions = (
    value: string,
    callback: (options: Select2OptionInterface[]) => void,
  ) => {
    const query = new Query().where("question", value).url()

    searchCategoryQuestions(query)
      .unwrap()
      .then((response) => {
        const options = response.map((question) => ({
          label: question.question,
          value: question.id.toString(),
        }))

        callback(options)
      })
  }

  const handleSearchQuestions = _.debounce(_handleSearchQuestions, 500)

  return (
    <div className={"flex flex-col"}>
      <form
        className={"flex flex-col gap-y-6"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={"grid grid-cols-2 gap-4"}>
          <Controller
            render={({ field }) => (
              <FormControl {...field} error={!!errors.question}>
                <Input
                  name={field.name}
                  label={t("form:labels.question")}
                  error={!!errors.question}
                />
                <FormHelperText message={errors.question?.message} />
              </FormControl>
            )}
            name={"question"}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <div className={"flex flex-col"}>
                <Label label={t("form:labels.related_questions")} />
                <AsyncSelect
                  isMulti
                  cacheOptions
                  onChange={(value) =>
                    field.onChange(value.map((v) => v.value))
                  }
                  value={selectedAdditionalQuestions}
                  loadOptions={handleSearchQuestions}
                />
                <FormHelperText
                  message={errors.related_faq_entry_ids?.message}
                  error={!!errors.related_faq_entry_ids}
                />
              </div>
            )}
            name={"related_faq_entry_ids"}
            control={control}
          />
        </div>
        <Controller
          render={({ field }) => (
            <div className={"flex flex-col"}>
              <Label label={t("form:labels.answer")} />
              <Editor
                onChange={field.onChange}
                data={field.value}
                galleryType={GalleryEnum.FAQ}
              />
              <FormHelperText
                error={!!errors.answer}
                message={errors.answer?.message}
              />
            </div>
          )}
          name={"answer"}
          control={control}
        />
        <div className={"flex pt-6"}>
          <Button variant={"contained"} type={"submit"}>
            {t("form:buttons.save")}
          </Button>
        </div>
      </form>
    </div>
  )
}
