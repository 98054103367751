import {
  SaveSurveyAnswerRequestInterface,
  SurveyAnswerInterface,
} from "@/features/surveys/redux/types"
import React, { useEffect } from "react"
import { FormInterface } from "@/app/types"
import { useTranslation } from "react-i18next"
import useValidation from "@/utils/hooks/useValidation"
import SaveSurveyAnswerValidation from "@/features/surveys/resources/answers/_components/form/validations/saveSurveyAnswerValidation"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import { Button } from "@/features/components/buttons/button"

export const Form: React.FC<
  FormInterface<SaveSurveyAnswerRequestInterface, SurveyAnswerInterface>
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(["utils", "form", "validation"])
  const { schema, defaultValues } = useValidation(
    new SaveSurveyAnswerValidation(),
    t,
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SaveSurveyAnswerRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      setValue("answer", data.answer)
    }
  }, [data])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={"flex flex-col gap-y-4"}>
        <Controller
          name={"answer"}
          control={control}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.answer}>
              <Input label={t("form:labels.answer")} multiline rows={4} />
              <FormHelperText message={errors.answer?.message} />
            </FormControl>
          )}
        />
      </div>
      <div className={"pt-4"}>
        <Button variant={"contained"} type={"submit"}>
          {t("form:buttons.save")}
        </Button>
      </div>
    </form>
  )
}
