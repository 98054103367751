import React, { Fragment } from "react"
import {
  PostInterface,
  UpdateAttributeInterface,
  UpdatePostDataRequest,
} from "@/features/posts/posts/redux/types"
import { BasicDataForm } from "@/features/posts/posts/resources/views/edit/_components/form/base/basicDataForm"
import { LocationForm } from "@/features/posts/posts/resources/views/edit/_components/form/base/locationForm"
import { Button } from "@/features/components/buttons/button"
import { AttributesForm } from "@/features/posts/posts/resources/views/edit/_components/form/base/attributesForm"
import useValidation from "@/utils/hooks/useValidation"
import UpdatePostValidation from "@/features/posts/posts/resources/views/edit/_components/form/validations/updatePostValidation"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { useUpdatePostMutation } from "@/features/posts/posts/redux/postAPI"
import { toast } from "react-toastify"
import { AttributeTypeEnum } from "@/features/posts/attributes/redux/enums/attributeType"

type Props = {
  data?: PostInterface
}

export const BasicForm: React.FC<Props> = ({ data }): React.ReactNode => {
  const { t } = useTranslation(["form", "validation", "utils", "posts/posts"])
  const { schema, defaultValues } = useValidation(new UpdatePostValidation(), t)
  const methods = useForm<UpdatePostDataRequest>({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const [updatePost] = useUpdatePostMutation()

  const resolveValue = (attribute: UpdateAttributeInterface) => {
    const { value, value_id, value_ids } = attribute

    switch (attribute.type) {
      case AttributeTypeEnum.MULTI_SELECT:
        return { value_ids: value_ids }
      case AttributeTypeEnum.SELECT:
      case AttributeTypeEnum.CERTIFICATE:
        return { value_id: value_id }
      default:
        return { value: value }
    }
  }

  const onSubmit = (formData: UpdatePostDataRequest) => {
    if (!data) return

    const transformedData = {
      ...formData,
      attributes: formData.attributes?.map((attribute) => ({
        id: attribute.attribute_category_id,
        ...resolveValue(attribute),
      })),
    }

    updatePost({
      id: data.id,
      body: transformedData as UpdatePostDataRequest,
    })
      .unwrap()
      .then(() => toast.success(t("posts/posts:edit.success")))
  }

  return (
    <Fragment>
      <div className={"flex flex-col"}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={"grid lg:grid-cols-2 gap-4"}
          >
            <BasicDataForm data={data} form={methods} />
            <div className={"flex flex-col"}>
              <LocationForm form={methods} data={data} />
              <AttributesForm form={methods} data={data} />
            </div>
          </form>
        </FormProvider>
        <div className={"flex"}>
          <Button
            variant={"contained"}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t("form:buttons.save")}
          </Button>
        </div>
      </div>
    </Fragment>
  )
}
