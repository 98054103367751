import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Table } from "./_components/table"

export const NotificationTemplateListScreen = (): React.ReactNode => {
  const { t } = useTranslation(["notification_templates"])

  return (
    <div className={"flex flex-col gap-y-4 p-4"}>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("notification_templates:list.title")}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}
