import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { ButtonLink } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "./_components/table"
import { usePermissions } from "@/utils/hooks/usePermission"
import { PermissionEnum } from "@/features/permissions/redux/types"

type Props = {
  categoryId: string
  categoryName: string
}

export const FAQCategoryQuestionsList: React.FC<Props> = ({
  categoryId,
  categoryName,
}): React.ReactNode => {
  const { t } = useTranslation(["faq/questions"])
  const { hasPermission } = usePermissions()

  return (
    <div className={"flex flex-col gap-y-4"}>
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.FAQ_SAVE) && (
          <ButtonLink
            variant={"contained"}
            className={"flex items-center gap-x-2"}
            to={`/faq/categories/${categoryId}/questions/create`}
          >
            <FontAwesomeIcon icon={faPlus} className={"text-white"} />
            <span>{t("faq/questions:list.create")}</span>
          </ButtonLink>
        )}
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>
              {t("faq/questions:list.title", { category: categoryName })}
            </span>
          </div>
          <Table categoryId={categoryId} />
        </div>
      </Card>
    </div>
  )
}
