import { Button as MUIButton, ButtonOwnerState, ButtonProps } from "@mui/base"
import React from "react"
import { Link, LinkProps } from "react-router-dom"
import clsx from "clsx"

type Props = {
  variant: "contained" | "outlined" | "text" | "secondary"
  size?: "medium" | "large" | "small" | "xsmall"
  fullWidth?: boolean
  color?: string
  className?: string
}

const classes = ({ variant, className, fullWidth, size, color }: Props) =>
  clsx(
    "py-[10px] px-[16px] rounded-lg box-shadow cursor-pointer",
    "hover:bg-primary-600 hover:text-white hover:border-primary-600 transition-colors duration-200 ease-in-out",
    "disabled:opacity-50 disabled:cursor-not-allowed",
    {
      "w-full": fullWidth,
      "bg-primary-600 text-white": variant === "contained" && !color,
      "!p-0": variant === "text",
      "border border-neutral-700 shadow-sm text-m text-neutral-950":
        variant === "outlined",
      "py-[12px] px-[18px]": size === "large",
      "flex items-center text-neutral-950 gap-x-2 py-[10px] px-[12px] rounded-lg border border-neutral-600":
        variant === "secondary",
      "py-[8px] px-[14px]": size === "small",
      "py-[4px] px-[10px]": size === "xsmall",
    },
    color,
    className,
  )

export const Button = React.forwardRef(
  (props: ButtonProps & Props, ref: React.ForwardedRef<any>) => {
    const { variant, children, className, fullWidth, size, color, ...rest } =
      props

    const classNames = classes({
      variant,
      className,
      fullWidth,
      size,
      color,
    })

    return (
      <MUIButton
        ref={ref}
        slotProps={{
          root: (state: ButtonOwnerState) => ({
            className: classNames,
          }),
        }}
        {...rest}
      >
        {children}
      </MUIButton>
    )
  },
)

export const ButtonLink = React.forwardRef(
  (props: LinkProps & Props, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    const { variant, className, fullWidth, size, color, ...rest } = props

    const classNames = classes({
      variant,
      className,
      fullWidth,
      size,
      color,
    })

    return <Link className={classNames} ref={ref} {...rest} />
  },
)
