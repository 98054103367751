import React from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import {
  useDeleteArticleGalleryMutation,
  useGetArticleGalleriesQuery,
  useUpdateArticleGalleryMutation,
} from "@/features/articleGalleries/redux/articleGalleryAPI"
import { articleGalleriesModal } from "@/features/articleGalleries/resources/components/form"
import { ArticleGalleryInterface } from "@/features/articleGalleries/redux/types"
import { useNavigate } from "react-router-dom"

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(["form", "article_galleries", "utils"])
  const { data = [] } = useGetArticleGalleriesQuery()
  const [deleteArticleGallery] = useDeleteArticleGalleryMutation()
  const [updateArticleGallery] = useUpdateArticleGalleryMutation()
  const navigate = useNavigate()

  const onDelete = (id: number) => {
    deleteArticleGallery(id)
      .unwrap()
      .then(() => toast.success(t("article_galleries:list.deleted")))
  }

  const onEdit = (id: number) => {
    const row = data.find((item) => item.id === id)

    articleGalleriesModal({
      title: t("article_galleries:edit.title"),
      data: row,
    }).then((data) =>
      updateArticleGallery({ data, id })
        .unwrap()
        .then(() => toast.success(t("article_galleries:edit.success"))),
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit, (id) =>
      navigate(`${id}/article-media`),
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
  })

  return <BaseTable table={table} />
}

const columnBuilder = createColumnHelper<ArticleGalleryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onDetails: (id: number) => void,
) => [
  columnBuilder.accessor("title", {
    id: "title",
    header: t("form:labels.title"),
  }),
  columnBuilder.accessor("description", {
    id: "description",
    header: t("form:labels.description"),
  }),
  columnBuilder.accessor("status", {
    id: "status",
    header: t("form:labels.status"),
    cell: ({ row }) =>
      t(`utils:article_gallery_statuses.${row.original.status}`),
  }),

  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.ARTICLE_GALLERY_DESTROY}
        editPermission={PermissionEnum.ARTICLE_GALLERY_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
        onDetailsClick={() => onDetails(row.original.id)}
      />
    ),
  }),
]
