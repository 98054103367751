import igritAPI from "@/utils/api/igrit"
import {
  GetAdsViewResponseInterface,
  GetAdsViewsResponseInterface,
  SaveAdsViewRequestInterface,
} from "@/features/ads/views/redux/types"
import { setPaginatedCache, setSingleObjectCache } from "@/utils/api/rtkHelper"

export const {
  useGetAdsViewsQuery,
  useGetAdsViewQuery,
  useStoreAdsViewMutation,
  useUpdateAdsViewMutation,
  useDeleteAdsViewMutation,
  useLazyGetAdsViewsQuery,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAdsViews: builder.query<GetAdsViewsResponseInterface["data"], string>({
      query: (query) => `/admin/ads/views?${query}`,
      transformResponse: (response: GetAdsViewsResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache("AdsView", result),
    }),
    getAdsView: builder.query<
      GetAdsViewResponseInterface["data"],
      string | number
    >({
      query: (id) => `/admin/ads/views/${id}`,
      transformResponse: (response: GetAdsViewResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache("AdsView", result),
    }),
    storeAdsView: builder.mutation<void, SaveAdsViewRequestInterface>({
      query: (data) => ({
        url: "/admin/ads/views",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AdsView"],
    }),
    updateAdsView: builder.mutation<
      void,
      { id: number | string; data: SaveAdsViewRequestInterface }
    >({
      query: ({ id, data }) => ({
        url: `/admin/ads/views/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["AdsView"],
    }),
    deleteAdsView: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `/admin/ads/views/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AdsView"],
    }),
  }),
})
