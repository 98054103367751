import React, { useEffect } from "react"
import { FormInterface } from "@/app/types"
import {
  RoleInterface,
  SaveRoleRequestInterface,
} from "@/features/roles/redux/types"
import { useTranslation } from "react-i18next"
import SaveRoleValidation from "@/features/roles/resources/_components/form/validations/saveRoleValidation"
import useValidation from "@/utils/hooks/useValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { Input } from "@/features/components/inputs/input"
import { FormHelperText } from "@/features/components/inputs/formHelperText"
import _ from "lodash"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { Checkbox } from "@/features/components/inputs/checkbox"
import { Button } from "@/features/components/buttons/button"

export const Form: React.FC<
  FormInterface<SaveRoleRequestInterface, RoleInterface>
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(["form", "validation", "utils"])
  const { schema, defaultValues } = useValidation(new SaveRoleValidation(), t)

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm<SaveRoleRequestInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const watchPermissions = watch("permissions")

  useEffect(() => {
    if (data) {
      setValue("name", data.name)
      setValue("permissions", data.permissions)
    }
  }, [data])

  const handleChangePermission = (permission: PermissionEnum) => {
    const permissions = getValues("permissions")

    if (permissions.includes(permission)) {
      setValue(
        "permissions",
        permissions.filter((p: PermissionEnum) => p !== permission),
      )
    } else {
      setValue("permissions", [...permissions, permission])
    }
  }

  return (
    <form
      id={"roles-form"}
      onSubmit={handleSubmit(onSubmit)}
      className={"flex flex-col gap-y-6"}
    >
      <div className={"grid 2xl:grid-cols-3 grid-cols-1"}>
        <Controller
          render={({ field }) => (
            <FormControl {...field} error={!!errors.name}>
              <Input
                name={field.name}
                label={t("form:labels.name")}
                error={!!errors.name}
              />
              <FormHelperText message={errors.name?.message} />
            </FormControl>
          )}
          name={"name"}
          control={control}
        />
      </div>
      <div className={"flex flex-col gap-y-1"}>
        {_.map(PermissionEnum, (permission, index) => (
          <Checkbox
            onChange={() => handleChangePermission(permission)}
            key={index}
            data-value={permission}
            checked={watchPermissions.includes(permission)}
            label={t(`utils:permissions.${permission}`)}
          />
        ))}
      </div>
      <div className={"flex pt-6"}>
        <Button variant={"contained"} type={"submit"}>
          {t("form:buttons.save")}
        </Button>
      </div>
    </form>
  )
}
