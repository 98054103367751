import { TFunction } from "i18next"
import * as yup from "yup"
import { SearchFilterInterface } from "../types"
import { ValidationInterface } from "@/utils/hooks/useValidation/types"

export default class FilterValidation
  implements ValidationInterface<SearchFilterInterface>
{
  rules(t: TFunction): yup.ObjectSchema<SearchFilterInterface, yup.AnyObject> {
    return yup.object().shape({
      category_id: yup.number().nullable(),
      attribute_id: yup.number().nullable(),
    })
  }

  defaultValues(): SearchFilterInterface {
    return {
      category_id: null,
      attribute_id: null,
    }
  }
}
