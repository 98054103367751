import React from "react"
import { useTranslation } from "react-i18next"
import Card from "@/features/components/cards"
import { Button } from "@/features/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Table } from "@/features/packages/resources/views/edit/categories/list/components/table"
import { packageCategoryForm } from "@/features/packages/resources/views/edit/categories/components/form"
import { toast } from "react-toastify"
import { useStorePackageCategoryMutation } from "@/features/packages/redux/packageAPI"

type Props = {
  packageId: number | string
}

export const PackageCategoryList: React.FC<Props> = ({
  packageId,
}): React.ReactNode => {
  const { t } = useTranslation("packages")
  const [storePackageCategory] = useStorePackageCategoryMutation()

  const handleStoreServiceCategory = () => {
    packageCategoryForm({
      title: t("packages:categories.create.title"),
    }).then((data) => {
      storePackageCategory({ packageId, data })
        .unwrap()
        .then(() => toast.success(t("packages:categories.create.success")))
    })
  }

  return (
    <div className={"flex flex-col gap-y-4"}>
      <div className={"flex flex-row-reverse"}>
        <Button
          variant={"contained"}
          className={"flex items-center gap-x-2"}
          onClick={handleStoreServiceCategory}
        >
          <FontAwesomeIcon icon={faPlus} className={"text-white"} />
          <span>{t("packages:categories.list.create")}</span>
        </Button>
      </div>
      <Card noPadding>
        <div className={"flex flex-col w-full"}>
          <div
            className={
              "border-b border-neutral-600 px-6 py-4 text-l font-medium"
            }
          >
            <span>{t("packages:categories.list.title")}</span>
          </div>
          <Table packageId={packageId} />
        </div>
      </Card>
    </div>
  )
}
