import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import BaseTable from "@/features/components/table"
import { ActionStack } from "@/features/components/table/actionStack"
import usePagination from "@/utils/hooks/usePagination"
import { Query } from "@/utils/query"
import { PermissionEnum } from "@/features/permissions/redux/types"
import { toast } from "react-toastify"
import {
  useDeletePackageCategoryMutation,
  useGetPackageCategoriesQuery,
  useUpdatePackageCategoryMutation,
} from "@/features/packages/redux/packageAPI"
import { PackageCategoryInterface } from "@/features/packages/redux/types"
import { packageCategoryForm } from "@/features/packages/resources/views/edit/categories/components/form"

type Props = {
  packageId: number | string
}

export const Table: React.FC<Props> = ({ packageId }): React.ReactNode => {
  const { t } = useTranslation(["form", "utils", "packages"])
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex).includes("category"),
    [pagination],
  )
  const { data: apiData } = useGetPackageCategoriesQuery({
    packageId,
    query: query.url(),
  })
  const [data, setData] = useState<PackageCategoryInterface[]>([])
  const [deletePackageCategory] = useDeletePackageCategoryMutation()
  const [updatePackageCategory] = useUpdatePackageCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deletePackageCategory({ packageId, id })
      .unwrap()
      .then(() => toast.success(t("packages:categories.list.deleted")))
  }

  const onEdit = (id: number) => {
    const packageCategory = data.find(
      (packageCategory) => packageCategory.id === id,
    )

    if (!packageCategory || !packageCategory.category) return

    packageCategoryForm({
      data: packageCategory,
      title: t("packages:categories.edit.title"),
    }).then((data) => {
      updatePackageCategory({ packageId, id, data })
        .unwrap()
        .then(() => toast.success(t("packages:categories.edit.success")))
    })
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<PackageCategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnBuilder.accessor("category.name", {
    id: "category.name",
    header: t("form:labels.category_name"),
  }),
  columnBuilder.accessor("price", {
    id: "price",
    header: t("form:labels.price"),
    cell: ({ row }) =>
      Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN" }).format(
        row.original.price,
      ),
  }),
  columnBuilder.accessor("points", {
    id: "points",
    header: t("form:labels.points"),
  }),
  columnBuilder.accessor("discounted_price", {
    id: "discounted_price",
    header: t("form:labels.discounted_price"),
    cell: ({ row }) =>
      row.original.discounted_price
        ? Intl.NumberFormat("pl-PL", {
            style: "currency",
            currency: "PLN",
          }).format(row.original.discounted_price)
        : "-",
  }),
  columnBuilder.accessor("discounted_points", {
    id: "discounted_points",
    header: t("form:labels.discounted_points"),
  }),
  columnBuilder.display({
    id: "actions",
    header: t("form:labels.actions"),
    meta: {
      columnClassName: "text-right pr-8",
    },
    cell: ({ row }) => (
      <ActionStack
        deletePermission={PermissionEnum.PACKAGE_DESTROY}
        editPermission={PermissionEnum.PACKAGE_SAVE}
        onDeleteClick={() => onDelete(row.original.id)}
        onEditClick={() => onEdit(row.original.id)}
      />
    ),
  }),
]
