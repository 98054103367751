import { SearchFilterInterface } from "@/features/posts/_components/posts/filters/types"

export const buildElasticSearchQuery = (
  searchFilter: SearchFilterInterface,
): { [key: string]: any } => {
  const query: { [key: string]: any } = {
    bool: {
      must: [],
    },
  }

  if (searchFilter.category_id) {
    query.bool.must.push({
      match: {
        category_id: searchFilter.category_id,
      },
    })
  }

  if (searchFilter.type) {
    query.bool.must.push({
      match: {
        type: searchFilter.type,
      },
    })
  }

  const priceRange: { [key: string]: any } = {}

  if (searchFilter.price_from) {
    priceRange.gte = searchFilter.price_from
  }

  if (searchFilter.price_to) {
    priceRange.lte = searchFilter.price_to
  }

  if (Object.keys(priceRange).length) {
    query.bool.must.push({
      range: {
        price: priceRange,
      },
    })
  }

  const publishedAtRange: { [key: string]: any } = {}

  if (searchFilter.published_at_from) {
    publishedAtRange.gte = searchFilter.published_at_from
  }

  if (searchFilter.published_at_to) {
    publishedAtRange.lte = searchFilter.published_at_to
  }

  if (Object.keys(publishedAtRange).length) {
    query.bool.must.push({
      range: {
        published_at: publishedAtRange,
      },
    })
  }

  if (searchFilter.id) {
    query.bool.must.push({
      match: {
        id: searchFilter.id,
      },
    })
  }

  if (searchFilter.email_visible) {
    query.bool.must.push({
      match: {
        email_visible: searchFilter.email_visible,
      },
    })
  }

  if (searchFilter.phone_visible) {
    query.bool.must.push({
      match: {
        phone_visible: searchFilter.phone_visible,
      },
    })
  }

  if (searchFilter.registered_user) {
    query.bool.must.push({
      match: {
        user_registered: searchFilter.registered_user,
      },
    })
  }

  if (searchFilter.is_price_negotiable) {
    query.bool.must.push({
      match: {
        is_price_negotiable: searchFilter.is_price_negotiable,
      },
    })
  }

  return query
}
